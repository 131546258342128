import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import { doc, updateDoc } from 'firebase/firestore';
import { X } from 'lucide-react';
import Popup from 'reactjs-popup';
import * as FaIcons from 'react-icons/fa';
import "./styles/resume_display.css"
import Tooltip from '@mui/material/Tooltip';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

export const ShowCandidateResume = ({candidate}) => {

    const [fileUrl, setFileUrl] = useState('');
    const [isDocLink, setIsDocLink] = useState(false);
    const [docs, setDocs] = useState([]);
    const [popupIsOpen, setPopupOpen] = useState(false);
    const [notes, setNotes] = useState('');

    const fetchFile = async () => {


        // return;
        try {
            const response = await fetch('https://api.skillpool.tech/get_file', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    fileName: candidate?.fileName,
                }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const fileExtension = candidate?.fileName.split('.').pop();

            if (fileExtension === 'pdf') {
                const blob = await response.blob();
                const pdfBlobUrl = URL.createObjectURL(blob);
                setIsDocLink(false);
                setFileUrl(pdfBlobUrl);
            } else if (fileExtension === 'doc' || fileExtension === 'docx') {
                const data = await response.json();
                setIsDocLink(true);
                setDocs([{uri: data.url, fileType: fileExtension}]);
                setFileUrl(data.url);
            }
        } catch (error) {
            console.error('Error fetching the file', error);
        }
    };

    useEffect(() => {
        if (candidate) {
            if (candidate?.notes) {
                setNotes(candidate.notes);
            }
        }
    }, [candidate]);

    const handlePopupOpen = () => {
        fetchFile();
        setPopupOpen(true);        
    }

    const saveNote = async () => {
        try {
            const response = await fetch('https://api.skillpool.tech/saveNotes', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    candidateId: candidate.id,
                    note: notes
                }),
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
        } catch (error) {
            console.error('Error saving notes', error);
        }
    }

    const closePopup = () => {
        setPopupOpen(false);
        saveNote();
    }

    return (
        <>
        <Tooltip title="Show Resume" onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            handlePopupOpen();
        }}>
            <div className="best_fit_candidate_action candidate_view_resume">
                <FaIcons.FaRegFilePdf />
            </div>
        </Tooltip>
        
        <Popup
            open={popupIsOpen}
            onOpen={handlePopupOpen}
            modal
            nested
            position="center center" 
        >
            <div className="popup_holder">
                <div className="popup" style={{boxShadow:"none"}}>
                    <h1 className="popup_heading">Candidate Resume</h1>
                    <div className="popup_close" onClick={closePopup}>
                        <X />
                    </div>

                    <div className="resume_content_holder">
                        <div className="resume_holder" style={{width:"100%"}}>
                            {isDocLink ? (
                                fileUrl && 
                                <DocViewer 
                                    documents={docs} 
                                    config={{
                                        header: {
                                            disableHeader: true,
                                            disableFileName: true,
                                            retainURLParams: false,
                                        },
                                    }}
                                    pluginRenderers={DocViewerRenderers} 
                                />
                            ) : (
                                fileUrl && <iframe src={fileUrl} toolbar="0" height="100%" width="100%" style={{ border: 'none' }} title="PDF Document"></iframe>
                            )}
                        </div>

                        <div className="notes_holder">
                            <div className='notes'>
                                <textarea 
                                    className='notes_writing_area' 
                                    placeholder='Write notes about the applicant here!' 
                                    value={notes} 
                                    onChange={(e) => setNotes(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Popup>
        </>
    );
};