import { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import { X } from "lucide-react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

export const CandidateResume = ({candidate, setActionsPopupOpen}) => {


    const [popupIsOpen, setPopupOpen] = useState(false);
    const [isDocLink, setIsDocLink] = useState(false);
    const [docs, setDocs] = useState([]);


    const closeResume = () => {
        setActionsPopupOpen(false);
        setPopupOpen(false);
    }

    const [fileUrl, setFileUrl] = useState('');

  
      
    const fetchPDF = async () => {
        try {
            const response = await fetch('https://api.skillpool.tech/get_file', {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                fileName: candidate?.fileName, // Specify your file name here
                }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const fileExtension = candidate?.fileName.split('.').pop();

            if (fileExtension === 'pdf') {
                const blob = await response.blob();
                const pdfBlobUrl = URL.createObjectURL(blob);
                setIsDocLink(false);
                setFileUrl(pdfBlobUrl);
            } else if ((fileExtension === 'doc') || (fileExtension === 'docx')) {
                const data = await response.json()
                // this will only work if the response is in json format, and so if the response contains the url link
                setIsDocLink(true);
                setDocs([{uri: data.url, fileType: fileExtension}])
                setFileUrl(data.url);
            }

        } catch (error) {
        console.error('Error fetching the PDF', error);
        }
    };

    useEffect(() => {

        if (candidate) {
            fetchPDF();
        }
        
    }, [candidate])

    return (
        <Popup
            trigger={
                <div className="candidate_action">
                    View Resume
                </div>
            }
            open={popupIsOpen}
            onOpen={() => setPopupOpen(true)}
            modal
            nested
            position="center center"
        >
            <div className="popup_holder">
                <div className="popup" style={{width: "50rem"}}>
                    <h1 className="popup_heading">Candidate Resume</h1>

                    <div className="popup_close" onClick={closeResume}>
                        <X />
                    </div>

                    <div className="resume_holder" style={{width:"100%"}}>
                        {
                            isDocLink ? (
                                fileUrl && 
                                <DocViewer 
                                documents={docs} 
                                config={{
                                    header: {
                                      disableHeader: true,
                                      disableFileName: true,
                                      retainURLParams: false,
                                    },
                                  }}
                                  pluginRenderers={DocViewerRenderers} 
                                  />
                            ) : (
                                fileUrl && <iframe src={fileUrl} toolbar="0" height="100%" width="100%" style={{ border: 'none' }} title="PDF Document"></iframe>
                            )
                        }
                    </div>

                   

                </div>
            </div>
        </Popup>
    )
}
