import { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import "./candidateDelete.css"
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import { useNavigate } from "react-router-dom";
import { globalPermissions } from "../../../routing";
import { sendNotification } from "../../notification";


export const CandidateDelete = ({candidate, candidateId, setActionsPopupOpen}) => {

    const navigate = useNavigate();

    const [popupIsOpen, setPopupOpen] = useState(false);


    const cancelDelete = () => {
        setActionsPopupOpen(false);
        setPopupOpen(false);
    }


    const deleteCandidate = async () => {

        try {
            // doing it through the backend as that deletes the associated file
            fetch('https://api.skillpool.tech/delete_candidate', {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                },
                body: JSON.stringify({ candidateId: candidateId }),
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                navigate("../job/" + candidate.job_id);
            })
            ;

            

        } catch (error) {
        console.error("Error deleting candidate ID:", candidateId, error);
        }
    
        setPopupOpen(false);
        setActionsPopupOpen(false);

        
    }

    return (
        <>
        <div className="candidate_action" onClick={() => {
            if (!globalPermissions.candidates.delete) {
                sendNotification("You do not have permission to delete candidates");
                setActionsPopupOpen(false);
                return;
            }
            setPopupOpen(true);
        }}>
            Delete Candidate
        </div>
        
        <Popup
            open={popupIsOpen}
            onOpen={() => setPopupOpen(true)}
            modal
            nested
            position="center center"
        >
            <div className="popup_holder">
                <div className="popup" style={{width: "30rem", height:"20rem"}}>
                    <h1 className="popup_heading">Delete Candidate</h1>

                    <div className="popup_body">
                        <p>Are you sure you want to delete this candidate?</p>
                        <p>This action cannot be undone!</p>
                    </div>

                   

                    <div className="popup_bottom_actions delete_bottom_actions">
                        <div className="cancel_delete" onClick={cancelDelete}>
                            Cancel
                        </div>

                        <div className="delete_candidate" onClick={deleteCandidate}>
                            Delete
                        </div>
                    </div>


                </div>
            </div>
        </Popup>
        </>
    )
}
