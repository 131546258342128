import ReactQuill from "react-quill";
import { Placeholder, placeholders } from "../../../components/candidateEmailHistory";
import { useState } from "react";
import Popup from "reactjs-popup";
import * as FaIcons from "react-icons/fa";
import './styles/createNewTemplate.css'
import { sendNotification } from "../../../components/notification";
import { collection, doc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import { globalPermissions } from "../../../routing";


export const CreateNewEmailTemplatePopup = ({employerID, employerData, setEmployerData}) => {

    const [popupIsOpen, setPopupOpen] = useState(false);


    const [templateName, setTemplateName] = useState("");
    const [currentSubject, setCurrentSubject] = useState("");
    const [currentBody, setCurrentBody] = useState("");

    const handleEmailBodyChange = (content, delta, source, editor) => {
        setCurrentBody(editor.getHTML()); // or content for just the HTML content
    };

    const cancelChanges = () => {
        setCurrentBody("");
        setCurrentSubject("");
        setPopupOpen(false);
    }


    const saveChanges = () => {
        // setSubject(currentSubject);
        // setBody(currentBody);

        if (!templateName) {
            sendNotification("Please enter a name for the template")
            return;
        }

        if (!currentSubject) {
            sendNotification("Please enter a subject for the template")
            return;
        }

        if (!currentBody) {
            sendNotification("Please enter a body for the template")
            return;
        }


        const email = {
            name: templateName,
            subject: currentSubject,
            body: currentBody
        }
        

        // Update the employer's settings

        const employerRef = doc(db, "teams", employerID);

        updateDoc(employerRef, {settings: {
            ...employerData.settings,
            email_templates: {
                ...employerData.settings.email_templates,
                custom: [...employerData.settings.email_templates.custom, email]
            }
        }});
        
        setEmployerData(prevState => ({
            ...prevState,
            settings: {
                ...prevState.settings,
                email_templates: {
                    ...prevState.settings.email_templates,
                    custom: [...prevState.settings.email_templates.custom, email]
                }
            }
        }));

        setPopupOpen(false);
        setTemplateName("");
        setCurrentBody("");
        setCurrentSubject("");
    }

    const openPopup = () => {

        if (globalPermissions.settings.change_templates === false) {
            sendNotification("You do not have permission to edit email templates");
            return;
        }
        
        setPopupOpen(true);
    }

    return (
        <>
        <div className="email_template_category email_create_new_template" onClick={openPopup}>
            <FaIcons.FaPlus style={{marginRight:"1rem"}} />
            <h3>Create New Template</h3>
        </div>

        <Popup
            open={popupIsOpen}
            onOpen={openPopup}
            modal
            nested
            position="center center"
        >

        <div className="popup_holder">
            <div className="popup">
                <h1 className="popup_heading">
                    Create New Template
                </h1>

            
                <div className="candidate_send_email_holder">
                
                    <div className="candidate_send_email_details">

                        <div className="get_template_name">
                            <h3>Template Name: </h3>
                            <input type="text" placeholder="Template Name" value={templateName} onChange={(e) => setTemplateName(e.target.value)} className="template_name_input send_email_subject" />
                        </div>

                        <h3>Subject: </h3>
                        <input type="text" placeholder="Subject" value={currentSubject} onChange={(e) => setCurrentSubject(e.target.value)} className="send_email_subject" />

                        <h3>Body: </h3>
                        <ReactQuill theme="snow" value={currentBody} onChange={handleEmailBodyChange} placeholder="Please enter the body here..." className="send_email_body create_template_email_body"/>
                    </div>

                    <div className="candidate_send_email_placeholders">
                        <h3>Usable Placeholders</h3>
                        <div className="placeholders_list">
                            {placeholders.map((text, index) => (
                                <Placeholder key={index} text={text} />
                            ))}
                        </div>
                    </div>
                </div>

                <div className="popup_bottom_actions">
                    <div className="popup_cancel" onClick={cancelChanges}>
                        Cancel
                    </div>

                    <div className="popup_action" onClick={saveChanges}>
                        Save
                    </div>
                </div>

            </div>
        </div>
            
        </Popup>
        </>
    )
}