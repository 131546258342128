import { Link } from "react-router-dom";
import "./styles/allCandidates.css"
import * as FaIcons from "react-icons/fa"
import { useEffect, useMemo, useRef, useState } from "react";
import { auth, db } from "../firebase";
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import IconButton from '@mui/material/IconButton';
import { HiSparkles } from "react-icons/hi2";
import { IoIosSend } from "react-icons/io";
import { FindBestFit } from "../components/findBestFit";
import Popup from "reactjs-popup";
import { X } from "lucide-react";
import ToggleButton from "../components/toggleButton";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { LocationEntry } from "../components/location";
import { CandidateEligibilityGeneral } from "./job_subPages/candidateUploadingComponents/candidateEligibilityGeneral";
import { CandidateSourcing } from "./job_subPages/candidateUploadingComponents/candidateSourcing";
import { CandidateDuplicateHandling } from "../components/candidate_duplicate_handling";
import { sendNotification } from "../components/notification";
import { AutoModel, AutoTokenizer } from "@xenova/transformers";
import { AllCandidatesActions } from "../components/allCandidatesActions";
import { setGlobalCandidates, setLastPage } from "./job";
import { ShowCandidateResume } from "../components/resume";



export const AllCandidates = () => {

    const [candidates, setCandidates] = useState([])
    const [jobs, setJobs] = useState([]);
    const [employer, setEmployer] = useState(null);
    const [employerID, setEmployerID] = useState(null);
    const [canRun, setCanRun] = useState(false);
    const [googleKey, setGoogleKey] = useState(null);


    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
            setCanRun(true);
            } else {
            // No user is signed in.
            setCanRun(false);
            }
        });

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, []);

    useEffect(() => {
      const fetchGoogleKey = async () => {
        const response = await fetch('https://api.skillpool.tech/google_api_key');
        const data = (await response.json()).key;
        setGoogleKey(data);
      }

      fetchGoogleKey();
    }, [])


    const getEmployerID = async () => {
        try {
            const user = auth.currentUser;
            if (!user) {
            throw new Error('No authenticated user found');
            }

            // Step 1: Get the user's member document to find their team_id
            const membersRef = collection(db, "members");
            const qMember = query(membersRef, where("email", "==", user.email));
            const memberSnapshot = await getDocs(qMember);
            if (memberSnapshot.empty) {
            throw new Error('Member not found');
            }

            const memberData = memberSnapshot.docs[0].data();
            const { team_id } = memberData;

            setEmployerID(team_id);

          } catch (e) {
            console.error('Error fetching roles:', e);
          }
    }


    useEffect(() => {
        getEmployerID();
    }, [canRun]);

    //Get all candidates across all jobs for an employer.

    useEffect(() => {

        // Change this to get all jobs first and then get all candidates. That's what allows me to know their job applied
        const fetchAllCandidates = async () => {
            try {
                const jobsQuery = query(collection(db, 'jobs'), where('employer_id', '==', employerID));
                const jobsSnapshot = await getDocs(jobsQuery);
                const jobs = jobsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setJobs(jobs);

                // Start fetching candidates linked to each job
                const candidateJobPromises = jobs.map(job => {
                    const candidatesRef = collection(db, 'candidates');
                    const candidatesQuery = query(candidatesRef, where('job_id', '==', job.id));
                    return getDocs(candidatesQuery);
                });

                // Start fetching candidates directly linked to the employer
                const candidatesRef = collection(db, 'candidates');
                const candidatesEmployerQuery = query(candidatesRef, where('employer_id', '==', employerID));
                const candidatesEmployerPromise = getDocs(candidatesEmployerQuery);

                // Wait for all candidate fetches to complete
                const [candidatesJobSnapshots, candidatesEmployerSnapshot] = await Promise.all([
                    Promise.all(candidateJobPromises),
                    candidatesEmployerPromise
                ]);

                // Combine results from job-related candidate queries
                let allCandidates = candidatesJobSnapshots.flatMap(snapshot =>
                    snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id, checked: false }))
                );

                // Add candidates with employer_id but no job_id
                const candidatesForEmployer = candidatesEmployerSnapshot.docs
                    .filter(doc => !doc.data().job_id)
                    .map(doc => ({ ...doc.data(), id: doc.id, checked: false }));

                allCandidates = allCandidates.concat(candidatesForEmployer);

                setLoading(false);

                setCandidates(allCandidates);
            } catch (error) {
                console.error("Error fetching candidates:", error);
            }
        }

        const fetchEmployer = async () => {
            const employerRef = doc(db, 'teams', employerID); // Create a reference to the employer document
            const employerSnap = await getDoc(employerRef); // Get the document snapshot
            if (employerSnap.exists()) {
                setEmployer(employerSnap.data()); // Set the employer state with the data from Firestore
            }
        }

        if (employerID) {
            fetchAllCandidates();
            fetchEmployer();
        }

    }, [employerID]);

    const activeJobs = jobs
    .filter(job => job.deleted !== true)








    //Handling the checkbox logic

    const [isAllChecked, setIsAllChecked] = useState(false);


    const handleAllCheck = (event) => {

        //should check all the candidates

        const updatedCandidates = filteredCandidates.map(candidate => {
            if (candidatesToShow.includes(candidate)) {
                return {
                    ...candidate,
                    checked: !isAllChecked
                };
            } else {
                return candidate;
            }
        })

        console.log(updatedCandidates);
        setFilteredCandidates(updatedCandidates);

        setIsAllChecked(!isAllChecked);
    };

    const handleSingleCheck = (event, id) => {
        const updatedCandidates = filteredCandidates.map(candidate => {
            if (candidate.id === id) {
                return {
                    ...candidate,
                    checked: !candidate.checked
                };
            }
            return candidate;
        });
        setFilteredCandidates(updatedCandidates);

        // Determine if all checkboxes are checked
        setIsAllChecked(updatedCandidates.every(candidate => candidate.checked));
        event.preventDefault();
    };



    // Logic to upload new candidates
    const [addCandidatePopupIsOpen, setAddCandidatePopupOpen] = useState(false);

    const [files, setFiles] = useState([]); // This will store file objects with { name, id }

    const handleFileChange = (event) => {
        const newFiles = Array.from(event.target.files);
        newFiles.forEach(file => {
            uploadFile(file);
        });
    };

    const uploadFile = (file) => {
        setUploadingFiles(true);
        const formData = new FormData();
        formData.append('file', file);
        fetch('https://api.skillpool.tech/apply/upload', {
            method: 'POST',
            body: formData,
        })
        .then(response => response.json())
        .then(data => {
            if (data && data.id) {
                setUploadingFiles(false);
                setFiles(prevFiles => [...prevFiles, { name: file.name, id: data.id }]);
            }
        })
        .catch(error => console.error('Error uploading file', error));
    };

    const handleRemoveFile = (fileId, fileName) => {
        fetch('https://api.skillpool.tech/apply/delete', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ fileName: fileId })
        })
        .then(response => response.json())
        .then(data => {
            setFiles(prevFiles => prevFiles.filter(file => file.id !== fileId));
        })
        .catch(error => console.error('Error removing file', error));
    };

    const handleUploadAll = () => {

        fetch('https://api.skillpool.tech/upload_candidates_general', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                files: files.map(file => file.id),
                employer_id: employerID,
                workAuthorization: workAuthorizationTrue,
                sponsorshipNeeded: sponsorshipNeeded,
                source: candidateSource,
            })
        })
        setWorkAuthorizationTrue(true);
        setSponsorshipNeeded(false);
        setFiles([]);

        setSubmitted(true);


        setTimeout(() => {
            setAddCandidatePopupOpen(false);
            setSubmitted(false);
            setReloadPage(true);
        }, 1000);
    };


    const cancelAddCandidates = () => {
        setWorkAuthorizationTrue(true);
        setSponsorshipNeeded(false);
        setFiles([]);
        setAddCandidatePopupOpen(false)
    }



    // Hanlding candidate filtering and sorting
    const [sortingCriteria, setSortingCriteria] = useState('name');

    const [filteredCandidates, setFilteredCandidates] = useState(candidates);

    useEffect(() => {
        setFilteredCandidates(candidates);
    }, [candidates]);

     // handling for location filtering

    const [locationInput, setLocationInput] = useState(null);


    const filterByLocation = async () => {
        let filtered = candidates;

        if (locationInput) {
            filtered = [];
            for (const candidate of candidates) {
                if (!candidate.location) {
                    continue;
                }

                // const distance = await calculateDistance(candidate.location.value.place_id, locationInput.value.place_id);
                const response = await fetch('https://api.skillpool.tech/calculate_distance', {
                    method: 'POST',
                    headers: {
                    'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        candidatePlaceID : candidate.location.value.place_id,
                        jobPlaceID: locationInput.value.place_id,
                    }),
                });

                const data = await response.json();


                let distance = 50001;

                try {
                    distance = data.distance.value;
                } catch (error) {
                    console.log(error);
                }
                

                if (distance <= 50000) { // 50 km in meters
                    filtered.push(candidate);
                }
            }
        }

        setFilteredCandidates(filtered);

    }

    const removeLocationFilter = () => {
        setLocationInput('');
        setFilteredCandidates(candidates);
    }

    // handling for work auth and sponsorship filtering

    const [filterNeedWorkAuth, setFilterNeedWorkAuth] = useState(false);

    const [filterNeedNoSponsorship, setFilterNeedNoSponsorship] = useState(false);

    useEffect(() => {
        setSearchTerm("");
    }, [filterNeedWorkAuth, filterNeedNoSponsorship, locationInput, sortingCriteria]);

    // function to sort and filter the other shit - cus its faster this way - and show the candidates


    // handling candidate search

   
    const [searchTerm, setSearchTerm] = useState('');

    const [semanticSearchResults, setSemanticSearchResults] = useState([]);

    useEffect(() => {
        setSemanticSearchResults([]);
    }, [searchTerm]);

    const conductSemanticSearch = async () => {
        // first get an embedding for the search term

        const response =await fetch('https://api.skillpool.tech/get_embeddings', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                text: searchTerm,
            }),
        });

        const data = await response.json();

        const searchEmbedding = Object.values(data);

        // Function to calculate cosine similarity
        const cosineSimilarity = (embeddingA, embeddingB) => {
            const dotProduct = embeddingA.reduce((sum, a, i) => sum + a * embeddingB[i], 0);
            const magnitudeA = Math.sqrt(embeddingA.reduce((sum, a) => sum + a * a, 0));
            const magnitudeB = Math.sqrt(embeddingB.reduce((sum, b) => sum + b * b, 0));
            return dotProduct / (magnitudeA * magnitudeB);
        };

        // Calculate similarity scores for each candidate
        const candidatesWithScores = candidates.map(candidate => {
            if (!candidate.embedding) {
                console.warn(`Candidate ${candidate.id} has no embedding`);
                return { ...candidate, similarityScore: -1 };
            }
            const similarityScore = cosineSimilarity(searchEmbedding, candidate.embedding);
            return { ...candidate, similarityScore };
        });

        // Sort candidates by similarity score in descending order
        const sortedCandidates = candidatesWithScores
            .sort((a, b) => b.similarityScore - a.similarityScore)
            .filter(candidate => candidate.similarityScore > -1);

        setSemanticSearchResults(sortedCandidates);

        // compare the embedding with the candidate embeddings

        

        // then sort by candidates which are most similar
    }


    const candidatesToShow = filteredCandidates
    .filter(candidate => {
        if (filterNeedWorkAuth) {
            return (candidate.workElibility === "Yes");
        }
        return true;
    })
    .filter(candidate => {
        if (filterNeedNoSponsorship) {
            return (candidate.sponsorship !== "Yes");
        }
        return true;
    })
    .filter(candidate => {
        if (searchTerm) {
            const searchTermLower = searchTerm.toLowerCase();
            return (
                candidate.name.toLowerCase().includes(searchTermLower) ||
                candidate.email.toLowerCase().includes(searchTermLower) ||
                candidate.phone.toString().toLowerCase().includes(searchTermLower) ||
                (jobs.find(job => job.id === candidate.job_id)?.jobTitle || "").toLowerCase().includes(searchTermLower) ||
                (candidate.location?.label || "").toLowerCase().includes(searchTermLower)
            );
        }
        return true;
    })
    .sort((a, b) => {
        switch (sortingCriteria) {
            case "name":
                return a.name.localeCompare(b.name); // Alphabetical sort by name
            case "apply_time":
                return a.application_time.seconds - b.application_time.seconds; // Ascending sort by application time
            case "apply_time_desc":
                return b.application_time.seconds - a.application_time.seconds; // Descending sort by application time
            default:
                    return 0; // Default case to handle unexpected sorting_type values
        }
    });
    
    //  - -- - - -- - - --

    // Handling candidate details for adding new candidates


    const [workAuthorizationTrue, setWorkAuthorizationTrue] = useState(true);
    const [sponsorshipNeeded, setSponsorshipNeeded] = useState(false);

    const [candidateSource, setCandidateSource] = useState({
        name: "Uploaded",
        category:"Other",
    });


    // Handle loading and displays for candidate table

    const [loading, setLoading] = useState(true);
    const [reloadPage, setReloadPage] = useState(false);


    // handle loading and displays for add candidate

    const [uploadingFiles, setUploadingFiles] = useState(false);
    const [submitted, setSubmitted] = useState(false);



    return (
        <div className="all_candidates_holder">

            <div className="candidates_page_header">
                <h1>
                    All Candidates
                </h1>
            </div>

            <div className = "allCandidatesSection">

                <div className="allCandidates_options_holder">
                    <div className="allCandidates_options_start" style={{width: "20%" }}>

                    </div>



                    <div className="allCandidates_options">

                        <div style={{display:"flex", alignItems:"center"}}>

                            <div style={{marginRight:"1rem"}}>

                                <Popup
                                    trigger={
                                        <IconButton>
                                            <FaIcons.FaPlus className="allCandidates_option_icon" />
                                        </IconButton>
                                    }
                                    open={addCandidatePopupIsOpen}
                                    onOpen={() => setAddCandidatePopupOpen(true)}
                                    modal
                                    nested
                                    position="center center"
                                >
                                    <div className="popup_holder">
                                        <div className="popup">
                                            <h1 className="popup_heading">Add Candidates</h1>

                                            {  submitted && (
                                                <>
                                                <p>The candidates have been submitted, they will be processed now!</p>
                                                <p>Please refresh the page to see the uploaded candidates in the candidates list!</p>
                                                </>
                                            )}

                                            {
                                                !submitted && (
                                                    <>
                                                    <div className="add_candidates_note">
                                                        <p className="add_candidates_note_head">Note: </p>

                                                        <div>
                                                            <p className="add_candidates_note_body" style={{marginBottom:"0.5rem"}}>This is to upload candidates to your general talent pool!</p>
                                                            <p className="add_candidates_note_body">If you want to add candidates to a specific job, please do so after selecting the job <Link to={"/jobs"}>here!</Link></p>
                                                        </div>
                                                         
                                                    </div>

                                                    <div className="add_candidates_body" style={{height: "70%"}}>

                                                        <div className="add_candidates_file_input">
                                                            <div className="file-input-container">
                                                                <label className="label-file-input">
                                                                    Add Resumes
                                                                    <input type="file" className="file-input" multiple onChange={handleFileChange}
                                                                        // style={{
                                                                        //     display:"none"
                                                                        // }}
                                                                    />
                                                                </label>
                                                            </div>


                                                            <div className="file_list_area">

                                                                {
                                                                    uploadingFiles && (
                                                                        <p>Uploading files...</p>
                                                                    )
                                                                }
                                                        
                                                                <ul className="file-list">
                                                                    {files.map((file, index) => (
                                                                        <li key={index}>
                                                                            {file.name}
                                                                            <button className="button button-remove" onClick={() => handleRemoveFile(file.id, file.name)}>
                                                                                <FaIcons.FaTrash
                                                                                    style={{
                                                                                        fontSize: ".8rem"
                                                                                    }}
                                                                                />
                                                                            </button>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>

                                                        </div>

                                                        <div className="add_candidates_details">

                                                            <h2 className="add_candidate_details_heading">Details</h2>

                                                            <CandidateEligibilityGeneral 
                                                                workAuthorizationTrue={workAuthorizationTrue}
                                                                setWorkAuthorizationTrue={setWorkAuthorizationTrue}
                                                                sponsorshipNeeded={sponsorshipNeeded}
                                                                setSponsorshipNeeded={setSponsorshipNeeded}
                                                            />

                                                            <CandidateSourcing 
                                                                employer={employer}
                                                                employerID={employerID}
                                                                candidateSource={candidateSource}
                                                                setCandidateSource={setCandidateSource}
                                                            />

                                                        </div>

                                                        </div>



                                                        <div className="popup_bottom_actions">
                                                        <div className="popup_cancel" onClick={cancelAddCandidates}>
                                                            Cancel
                                                        </div>

                                                        <div className="popup_action" onClick={handleUploadAll}>
                                                            Add
                                                        </div>
                                                        </div>
                                                    </>
                                                )
                                            }


                                            
                                        </div>
                                    </div>

                                </Popup>

                            </div>


                            <AllCandidatesActions 
                                selectedCandidates={filteredCandidates.filter(candidate => candidate.checked)}
                                candidates={candidates}
                                setCandidates={setCandidates}
                                setFilteredCandidates={setFilteredCandidates}
                                setIsAllChecked={setIsAllChecked}
                                jobs={jobs}
                                employer={employer}
                            />

                            

                        </div>

                        <div className="job_candidates_sort_by">
                            <select className="job_candidates_sort_by_select"
                                value={sortingCriteria}
                                onChange={(e) => setSortingCriteria(e.target.value)}
                            >
                                <option value="name">Sort By Name</option>
                                <option value="apply_time">Sort By Earliest - Latest</option>
                                <option value="apply_time_desc">Sort By Latest - Earliest</option>
                            </select>
                        </div>

                    </div>
                </div>

                <div style={{display:"flex", alignItems:"center", height:"100%"}}>

                    <div className="allCandidatesFilter"  >

                        <h3 className="all_candidates_filter_options_heading">
                            Filtering Options
                        </h3>

                        <div className="filter_options_location_eligibility">

                            <div className="filter_options_location_heading" style={{justifyContent:"space-between"}}>

                                <div style={{display:"flex", alignItems:"center"}}>
                                    <FaIcons.FaMapMarkerAlt style={{margin: 0, marginRight:"1rem", padding: 0}} />
                                    <p style={{fontWeight:500, margin: 0, padding: 0}}>Location</p>
                                </div>

                                <X onClick={removeLocationFilter} style={{cursor:"pointer", fontSize:"0.8rem"}} />

                            </div>


                            <div className="filter_options_location_input_holder">
                              {
                                googleKey && (
                                  <GooglePlacesAutocomplete
                                      apiKey={googleKey}
                                      selectProps={{
                                      locationInput,
                                      className: "location_selector",
                                      onChange: setLocationInput,
                                      styles: {
                                          control: (baseStyles, state) => ({
                                              ...baseStyles,
                                              borderColor: "var(--secondary40)",
                                              backgroundColor: "var(--bg)",
                                              outline: "none",
                                              boxShadow: "none",
                                              "&:hover": {
                                                  borderColor: "var(--secondary40)"
                                              }
                                          }),
                                          option: (provided, state) => ({
                                              ...provided,
                                              backgroundColor: state.isFocused ? 'var(--secondary20)' : 'var(--bg)',
                                          }),
                                          menu: (baseStyles, state) => ({
                                              ...baseStyles,
                                              backgroundColor: "var(--bg)",
                                              color: "var(--text)",
                                              zIndex: 999,
                                              border: "1px solid var(--secondary40)",
                                              borderTopWidth: 0,
                                              borderRadius: "0 0 5px 5px",
                                              boxShadow: "0px 2px 5px var(--secondary10)"
                                          }),
                                      },
                                      }}
                                  />
                                )
                              }


                            </div>



                            <div className="filter_location_submit" onClick={filterByLocation}>

                                {/* <IoIosSend /> */}
                                <FaIcons.FaSearch style={{marginRight: "1rem"}} />
                                Filter
                            </div>

                        </div>

                        <div className="filter_options_country_elibility">

                            <div className="filter_options_country_heading">
                                <FaIcons.FaGlobe style={{margin: 0, marginRight:"1rem", padding: 0}} />
                                <p style={{fontWeight:500, margin: 0, padding: 0}}>Eligibility</p>
                            </div>

                            <div className="filter_options_legally_authorized" style={{display:"flex", alignItems:"center"}}>
                                <input
                                    type="checkbox"
                                    style={{marginRight:"0.5rem", fontSize:"0.8rem"}}
                                    checked={filterNeedWorkAuth}
                                    onChange={() => setFilterNeedWorkAuth(!filterNeedWorkAuth)}
                                />
                                <p style={{fontSize:".9rem"}}>Authorized to Work</p>
                            </div>

                            <div className="filter_options_legally_authorized" style={{display:"flex", alignItems:"center"}}>
                                <input
                                    type="checkbox"
                                    style={{marginRight:"0.5rem", fontSize:"0.8rem"}}
                                    checked={filterNeedNoSponsorship}
                                    onChange={() => setFilterNeedNoSponsorship(!filterNeedNoSponsorship)}
                                />
                                <p style={{fontSize:".9rem"}}> <b>Doesn't</b> need sponsorship</p>
                            </div>

                        </div>

                       <FindBestFit jobs={activeJobs} candidates={candidates} />

                    </div>


                        
                    <div className="allCandidatesTable">

                        <div className="candidatesSearchHolder">
                            <FaIcons.FaSearch style={{opacity:0.5}} />
                            <input
                                className="searchBar"
                                value={searchTerm}
                                onChange={e => setSearchTerm(e.target.value)}
                                placeholder={(
                                    "Search!"
                            )}/>
                        </div>

                        
                        <div className="allCandidateTableHead">

                            <div className="candidate_checkbox_cell">
                                <div
                                    style={{
                                        border: "1px solid var(--primary)",
                                        width: ".7rem",
                                        height: ".7rem",
                                        borderRadius: "20%",
                                        cursor: "pointer",
                                        backgroundColor: isAllChecked ? "var(--primary)" : "transparent"
                                    }}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        handleAllCheck(event);
                                    }}
                                />
                            </div>

                            <div className="candidate_name_cell">
                                Name
                            </div>

                            <div className="candidate_email_cell">
                                Email
                            </div>

                            <div className="candidate_phone_cell">
                                Phone
                            </div>

                            <div className="candidate_job_applied_cell">
                                Job Applied
                            </div>

                            <div className="candidate_location_cell">
                                Location
                            </div>

                            <div className="candidate_view_cell">
                                View
                            </div>

                            <div className="candidate_info_cell">
                                {/* this is for any icons (such as the thing I'll use to show duplicate candidates) */}
                                {/* obviously nothing to show in the header */}
                            </div>

                        </div>

                        <div className="allCandidatesTableBody">

                            {
                                loading && (
                                    <div className="loading_candidates">
                                        <div className="loading_candidates_spinner">Loading Candidates...</div>
                                    </div>
                                )
                            }

                            {
                                !loading && !reloadPage && candidatesToShow.length === 0 && semanticSearchResults.length === 0 && (

                                    searchTerm === "" ? (
                                        <div className="no_candidates">
                                            <div className="no_candidates_text">
                                                No candidates yet! Feel free to add some candidates!
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="no_candidates">
                                            <div className="no_candidates_text">
                                                <p style={{margin:0}}>Looks like no candidates match your exact search! Do you want to try a semantic search instead?</p>
                                                <button className="semantic_search_button" onClick={conductSemanticSearch}>
                                                    <HiSparkles style={{marginRight:"0.5rem"}} />
                                                    Semantic Search
                                                </button>
                                            </div>
                                        </div>
                                    )
                                    
                                )
                            }

                            {
                                !loading && reloadPage && (
                                <div className="loading_candidates">
                                    <div className="loading_candidates_spinner">Please reload the page to view the uploaded candidates!</div>
                                </div>
                            )}

                            {!loading && !reloadPage && (semanticSearchResults.length > 0 ? semanticSearchResults : candidatesToShow).map((candidate, index) => (
                                <Link to={candidate.job_id ? `../job/${jobs.find(job => job.id === candidate.job_id)?.id}/candidate/${candidate.id}` : `../candidate/${candidate.id}`} className='candidate_link' style={{width:"100%"}} onClick={() => {
                                    // set global candidates to the candidates with the job id that was clicked. If no job id then global candidates should the the candidate that has been clicked only
                                    if (candidate.job_id) {
                                        setLastPage("all_candidates");
                                        setGlobalCandidates(candidates.filter(c => c.job_id === candidate.job_id).sort((a, b) => {
                                            if (a.stage !== b.stage) {
                                                return a.stage - b.stage;
                                            }
                                            const scoreA = a.score || 0;
                                            const scoreB = b.score || 0;
                                            if (scoreA < 0 && scoreB < 0) return scoreA - scoreB;
                                            if (scoreA < 0) return 1;
                                            if (scoreB < 0) return -1;
                                            return scoreA - scoreB;
                                        }).map(c => c.id));
                                    } else {
                                        setLastPage("all_candidates");
                                        setGlobalCandidates([candidate.id]);
                                    }
                                }}>
                                    <div key={index} style = {{width: "100%"}} className="candidate_allCandidates">

                                        <div className="candidate_checkbox_cell">
                                            <div
                                                style={{
                                                    border: "1px solid var(--primary)",
                                                    width: ".7rem",
                                                    height: ".7rem",
                                                    borderRadius: "20%",
                                                    cursor: "pointer",
                                                    backgroundColor: candidate?.checked ? "var(--primary)" : "transparent"
                                                }}
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    handleSingleCheck(event, candidate?.id);
                                                }}
                                            />
                                        </div>

                                        <div className="candidate_name_cell">
                                            {candidate?.name}
                                        </div>

                                        <div className="candidate_email_cell">
                                            {candidate?.email}
                                        </div>

                                        <div className="candidate_phone_cell">
                                            {candidate?.phone}
                                        </div>

                                        <div className="candidate_job_applied_cell">
                                            {jobs.find(job => job.id === candidate.job_id)?.jobTitle || "—"}
                                        </div>

                                        <div className="candidate_location_cell">
                                            {candidate?.location?.label || "—"}
                                        </div>

                                        <div className="candidate_view_cell">
                                            <ShowCandidateResume candidate={candidate} /> 
                                        </div>

                                        <div className="candidate_info_cell">
                                            {
                                                (candidate.duplicates && candidate.duplicates.length > 0) && (
                                                    <CandidateDuplicateHandling 
                                                        candidate={candidate}
                                                        duplicates={candidates.filter(c => candidate.duplicates.includes(c.id))}
                                                        jobs={jobs}
                                                        candidates={candidates}
                                                        setCandidates={setCandidates}
                                                    />
                                                )
                                            }
                                            {/* this is for any icons (such as the thing I'll use to show duplicate candidates) */}
                                        </div>

                                    </div>
                                </Link>

                            ))}

                        </div>
                    </div>
                    


                </div>

            </div>
        </div>
    );
};







