import { Handle } from "reactflow";

import "./styles/node.css"
import "./styles/interviewingNode.css"
import Popup from "reactjs-popup";
import { useEffect, useState } from "react";
import * as FaIcons from "react-icons/fa";
import { FaRegUser, FaUser } from "react-icons/fa6";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Autosuggest from "react-autosuggest";
import { auth, db } from '../../firebase';
import { collection, query, where, getDocs, getDoc, doc, updateDoc } from "firebase/firestore";
import { User, UserRound, X } from "lucide-react";
import { globalEmployerData, updateNodeData } from "../../pages/createJob";
import { sendNotification } from "../notification";
import { FeedbackForm } from "./feedbackForm/feedbackForm";
import { LocationEntry } from "../location";
import { Interviewers } from "./interviewingCategories/interviewers";
import { Feedback } from "./interviewingCategories/feedback";
import { Scheduling } from "./interviewingCategories/scheduling";
import { Debrief } from "./interviewingCategories/debrief";
import { Details } from "./interviewingCategories/details";

export const InterviewNode = ({data}) => {
  const [popupIsOpen, setPopupOpen] = useState(false);

  // handling the node subnav items
  const [activeSection, setActiveSection] = useState('details');



  // Handling loading from database

  const [canRun, setCanRun] = useState(false);
  const [employerID, setEmployerID] = useState(false);
  const [employerData, setEmployerData] = useState(null);

  const [teamMembers, setTeamMembers] = useState([]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setCanRun(true);
      } else {
        setCanRun(false);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
      const fetchTeamMembers = async () => {
        try {
          const user = auth.currentUser;
          if (!user) {
            throw new Error('No authenticated user found');
          }

          const membersRef = collection(db, "members");
          const qMember = query(membersRef, where("email", "==", user.email));
          const memberSnapshot = await getDocs(qMember);
          if (memberSnapshot.empty) {
            throw new Error('Member not found');
          }

          const memberData = memberSnapshot.docs[0].data();
          const { team_id } = memberData;
          setEmployerID(team_id);

          const teamSnapshot = await getDoc(doc(db, "teams", team_id));
          if (!teamSnapshot.exists()) {
            throw new Error('Team not found');
          }

          const teamData = teamSnapshot.data();
          setEmployerData(teamData);

          if (teamData.interviewingNodes) {
            setInterviewingNodes(teamData.interviewingNodes);
          }

          let teamMembersIds = teamData.members.map(member => member);
          const teamMembersPromises = teamMembersIds.map(async id => {
            const memberSnap = await getDoc(doc(db, "members", id));
            if (!memberSnap.exists()) {
              console.warn(`Member with id ${id} not found, removing from team members.`);
              teamMembersIds = teamMembersIds.filter(memberId => memberId !== id);
              return null;
            } else {
              const memberData = memberSnap.data();
              return {
                id: id,
                ...memberData,
              };
            }
          });

          const teamMembers = (await Promise.all(teamMembersPromises)).filter(member => member !== null);

          if (teamMembersIds.length !== teamData.members.length) {
            await updateDoc(doc(db, "teams", team_id), { members: teamMembersIds });
          }

          const team = teamMembers;
          setTeamMembers(team);
        } catch (error) {
          console.error("Failed to fetch team members:", error);
        }
      };

      fetchTeamMembers();
  }, [canRun]);


  // handling the complete data for each of the sections

  const [complete, setComplete] = useState(false);
  const [detailsComplete, setDetailsComplete] = useState(false);
  const [interviewersComplete, setInterviewersComplete] = useState(false);
  const [feedbackComplete, setFeedbackComplete] = useState(true);
  const [schedulingComplete, setSchedulingComplete] = useState(true);
  const [debriefComplete, setDebriefComplete] = useState(true);


  useEffect(() => {
    if (detailsComplete && interviewersComplete && feedbackComplete && schedulingComplete && debriefComplete) {
      setComplete(true);
    } else {
      setComplete(false);
    }
  }, [detailsComplete, interviewersComplete, feedbackComplete, schedulingComplete, debriefComplete]);

   

  // handling editing the name

 


  // Handling node stuff
  const deleteNode = () => {
    data.deleteNode(data.id, data.pipelineIndex);
    setPopupOpen(false);
  }


  // Handling all the stages' details

  // details

  const [name, setName] = useState("Interview");
  const [duration, setDuration] = useState(30);
  const [interviewType, setInterviewType] = useState('');
  const [location, setLocation] = useState('');
  const [inPersonLocation, setInPersonLocation] = useState(null);
  const [interviewNotes, setInterviewNotes] = useState('');

  const handleNameChange = (e) => {

    setName(e.target.value);
    // if (e.target.value === "") {
    //   sendNotification("Interview name can't be empty!")
    //   return;
    // } else {
     
    // }
  };

  const handleDurationChange = (e) => {
    const value = e.target.value;

    if (value < 0) {
      sendNotification("Please send a valid duration!");
      return;
    }

    if (value > 90) {
      sendNotification("Please enter a duration less than 90 minutes!");
      return;
    }

    if (value === "") {
      setDuration(0);
      return;
    }

    setDuration(parseInt(value));
  }

  const handleLocationChange = (type) => {
    setLocation(type);
  };


  // interviewing

  const [interviewers, setInterviewers] = useState([]);
  const [hasEmails, setHasEmails] = useState(false);

 

  // feedback

  const [feedbackEnabled, setFeedbackEnabled] = useState(false);
  const [feedbackRemindersIn, setFeedbackRemindersIn] = useState(0);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [feedbackForms, setFeedbackForms] = useState(globalEmployerData?.feedbackForms);
  const [selectedForm, setSelectedForm] = useState(null);

  // scheduling

  const [automateScheduling, setAutomateScheduling] = useState(false);
  const [automatedSchedulingChoice, setAutomatedSchedulingChoice] = useState(null);

  useEffect(() => {
    if (hasEmails) {
         if (automatedSchedulingChoice === "direct") {
             sendNotification("Can't choose direct booking when using external interviewers");
             setAutomatedSchedulingChoice("availability");
         }
     }
 }, [automatedSchedulingChoice, hasEmails])

  const [enableInterviewReminder, setEnableInterviewReminder] = useState(false);
  const [reminderTime, setReminderTime] = useState(24);


  // debrief

  const [debriefEnabled, setDebriefEnabled] = useState(false);

  const [debriefDuration, setDebriefDuration] = useState(30);

  const [scheduleDebriefAutomatically, setScheduleDebriefAutomatically] = useState(false);

  const [sendEmailDebriefReminders, setSendEmailDebriefReminders] = useState(false);

  const [debriefEmailRemindersTime, setDebriefEmailRemindersTime] = useState(24);

  const [debriefMembers, setDebriefMembers] = useState([]);



  // handling saving the node data

  useEffect(() => {

    const nodeData = {
        complete: complete,
        details: {
          name: name,
          duration: duration,
          interviewType: interviewType,
          location: location,
          inPersonLocation: inPersonLocation,
          interviewNotes: interviewNotes,
        },
        interviewers: {
          interviewers: interviewers,
          hasEmails: hasEmails,
        },
        feedback: {
          enabled: feedbackEnabled,
          feedbackRemindersIn: feedbackRemindersIn,
          selectedQuestions: selectedQuestions,
        },
        scheduling: {
          automateScheduling: automateScheduling,
          automatedSchedulingChoice: automatedSchedulingChoice,
          enableInterviewReminder: enableInterviewReminder,
          reminderTime: reminderTime,
        },
        debrief: {
          enabled: debriefEnabled,
          debriefDuration: debriefDuration,
          scheduleAutomatically: scheduleDebriefAutomatically,
          debriefMembers: debriefMembers,
          enableEmailReminder: sendEmailDebriefReminders,
          reminderTime: debriefEmailRemindersTime,
        }
    }
    //
    updateNodeData(data.index, nodeData);

  }, [complete, detailsComplete, interviewersComplete, feedbackComplete, schedulingComplete, debriefComplete, name, duration, interviewType, location, inPersonLocation, interviewNotes, interviewers, hasEmails, feedbackEnabled, feedbackRemindersIn, selectedQuestions, automateScheduling, automatedSchedulingChoice, enableInterviewReminder, reminderTime, debriefEnabled, debriefDuration, scheduleDebriefAutomatically])


  // handling saving and loading the data

  const [interviewingNodes, setInterviewingNodes] = useState([]);

  const [savePopupIsOpen, setSavePopupOpen] = useState(false);
  const [loadPopupIsOpen, setLoadPopupOpen] = useState(false);

  const [saveInterviewNodeName, setSaveInterviewNodeName] = useState("");
  const [selectedInterviewNode, setSelectedInterviewNode] = useState("");

  const [createNewInterviewShow, setCreateNewInterviewShow] = useState(false);

  const saveInterviewNode = async () => {

    if (!employerID) {
      console.error("No employer ID found");
      return;
    }

    const nodeData = {
      complete,
      details: {
        detailsComplete,
        name,
        duration,
        interviewType,
        location,
        inPersonLocation,
        interviewNotes,
      },
      interviewers: {
        interviewersComplete,
        interviewers,
        hasEmails,
      },
      feedback: {
        feedbackComplete,
        enabled: feedbackEnabled,
        feedbackRemindersIn,
        selectedQuestions,
      },
      scheduling: {
        schedulingComplete,
        automateScheduling,
        automatedSchedulingChoice,
        enableInterviewReminder,
        reminderTime,
      },
      debrief: {
        debriefComplete,
        enabled: debriefEnabled,
        debriefDuration,
        scheduleAutomatically: scheduleDebriefAutomatically,
        debriefMembers,
      }
    };

    try {
      const teamRef = doc(db, "teams", employerID);
      
      if ((selectedInterviewNode !== null) && (selectedInterviewNode !== "")) {
        // Update existing interview node
        const updatedNodes = interviewingNodes.map((node, index) => 
          index === parseInt(selectedInterviewNode) ? {
            ...node,
            ...nodeData,
          } : node
        );

        await updateDoc(teamRef, { interviewingNodes: updatedNodes });
        setInterviewingNodes(updatedNodes);
      } else {
        // Create new interview node
        const newNode = {
          name: saveInterviewNodeName,
          ...nodeData
        };
        const newNodes = [...interviewingNodes, newNode];
        await updateDoc(teamRef, { interviewingNodes: newNodes });
        setInterviewingNodes(newNodes);
      }

      setSelectedInterviewNode("");
      setSaveInterviewNodeName("");
      setSavePopupOpen(false);
    } catch (error) {
      console.error("Error saving interview node:", error);
    }
  };

  const cancelSaveInterviewNode = () => {
    setSaveInterviewNodeName("");
    setSelectedInterviewNode(null);
    setCreateNewInterviewShow(false);
    setSavePopupOpen(false);
  }

  const loadInterviewNode = () => {

    if ((!selectedInterviewNode) || (selectedInterviewNode === "")) {
      console.error("No interview node selected");
      return;
    }

    const nodeToLoad = interviewingNodes[selectedInterviewNode];
    if (!nodeToLoad) {
      console.error("Selected interview node not found");
      return;
    }

    // Update all the state variables with the loaded data
    setComplete(nodeToLoad.complete);
    setDetailsComplete(nodeToLoad.details.detailsComplete);
    setInterviewersComplete(nodeToLoad.interviewers.interviewersComplete);
    setFeedbackComplete(nodeToLoad.feedback.feedbackComplete);
    setSchedulingComplete(nodeToLoad.scheduling.schedulingComplete);
    setDebriefComplete(nodeToLoad.debrief.debriefComplete);
    setName(nodeToLoad.details.name);
    setDuration(nodeToLoad.details.duration);
    setInterviewType(nodeToLoad.details.interviewType);
    setLocation(nodeToLoad.details.location);
    setInPersonLocation(nodeToLoad.details.inPersonLocation);
    setInterviewNotes(nodeToLoad.details.interviewNotes);
    setInterviewers(nodeToLoad.interviewers.interviewers);
    setHasEmails(nodeToLoad.interviewers.hasEmails);
    setFeedbackEnabled(nodeToLoad.feedback.enabled);
    setFeedbackRemindersIn(nodeToLoad.feedback.feedbackRemindersIn);
    setSelectedQuestions(nodeToLoad.feedback.selectedQuestions);
    setAutomateScheduling(nodeToLoad.scheduling.automateScheduling);
    setAutomatedSchedulingChoice(nodeToLoad.scheduling.automatedSchedulingChoice);
    setEnableInterviewReminder(nodeToLoad.scheduling.enableInterviewReminder);
    setReminderTime(nodeToLoad.scheduling.reminderTime);
    setDebriefEnabled(nodeToLoad.debrief.enabled);
    setDebriefDuration(nodeToLoad.debrief.debriefDuration);
    setScheduleDebriefAutomatically(nodeToLoad.debrief.scheduleAutomatically);
    setDebriefMembers(nodeToLoad.debrief.debriefMembers);
    setLoadPopupOpen(false);
    setSelectedInterviewNode("");
  }

  const cancelLoadInterviewNode = () => {
    setSelectedInterviewNode("");
    setLoadPopupOpen(false);
  }


  return (
      <Popup trigger={

        <div className='pipeline_node_display'>
            <div className="pipeline_node_actual_display">
              <p>{name}</p>
              <FaIcons.FaExpandAlt />
            </div>
            
            <Handle type="target" position="left" id="5"  style={{pointerEvents:"none"}}/>
            {/* <Handle type="target" position="top" id="6"  style={{pointerEvents:"none"}}/> */}
            <Handle type="source" position="right" id="4"  style={{pointerEvents:"none"}}/>
            {/* <Handle type="source" position="bottom" id="3"  style={{pointerEvents:"none"}}/> */}



            {!complete && (
                <div className="node_incomplete">
                    !
                    {/* Note that this will actually be an orange circle / symbol.  */}
                </div>
            )}

        </div>

    }

    open={popupIsOpen}
    onOpen={() => setPopupOpen(true)}
    modal
    nested
    position="center center"
    >

      <div className="create_stage_holder">
          <div className="create_stage">

            
            <div className="save_load_interview_node">
            
              <Popup trigger={
                <button className="load_interview_node">Load</button>
              }
              open={loadPopupIsOpen}
              onOpen={() => setLoadPopupOpen(true)}
              modal
              nested
              position="center center"
              >
                <div className="popup_holder">
                  <div className="popup" style={{width:"30%", height:"30%"}}>
                    <h2 className="popup_heading">Load Interview Node</h2>

                    <div className="popup_close" onClick={cancelLoadInterviewNode}>
                      <X />
                    </div>
                    
                    <div className="popup_body">
                      <select 
                        className="save_interview_node_select" 
                        style={{width:"100%"}}
                        onChange={(e) => setSelectedInterviewNode(e.target.value)}
                        value={selectedInterviewNode}
                      >
                            <option value="" selected>Select saved interview</option>
                            {
                              interviewingNodes.map((node, index) => {
                                return (
                                  <option key={index} value={index}>{node.name}</option>
                                )
                              })
                            }
                      </select>
                    </div>

                    <div className="popup_bottom_actions" style={{padding: "0rem 2rem", boxSizing:"border-box"}}>
                      <div></div>
                      <button 
                        className="save_interview_node_button" 
                        onClick={loadInterviewNode}
                        disabled={!selectedInterviewNode}
                      >
                        Load
                      </button>
                    </div>

                  </div>
                </div>
              </Popup>


              <Popup trigger={
                <button className="save_interview_node">Save</button>
              }
              open={savePopupIsOpen}
              onOpen={() => setSavePopupOpen(true)}
              modal
              nested
              position="center center"
              >
                <div className="popup_holder">
                  <div className="popup" style={{width:"40%", height:"40%"}}>
                    <h2 className="popup_heading">Save Interview Node</h2>

                    <div className="popup_close" onClick={cancelSaveInterviewNode}>
                      <X />
                    </div>

                    <div className="popup_body">
                      <div className="create_interview_override_save save_interview_section">
                        <p>Override existing saved item?</p>

                        <select 
                          className="save_interview_node_select"
                          onChange={(e) => setSelectedInterviewNode(e.target.value)}
                          value={selectedInterviewNode}
                        >
                          <option value="" selected>Select saved interview</option>
                          {
                              interviewingNodes.map((node, index) => {
                                
                                return (
                                  <option key={index} value={index}>{node.name}</option>
                                )
                              })
                            }
                        </select>
                      </div>

                      <hr style={{background:"var(--primary)", border:".1px solid var(--secondary40)"}}></hr>

                      <div className="create_interview_create_node save_interview_section">
                        <p>or <span className="create_interview_create_node_span" onClick={() => 
                          {
                            setCreateNewInterviewShow(!createNewInterviewShow);
                            setSelectedInterviewNode("");
                          }
                        }>create a new saved interview node</span></p>

                        {
                          createNewInterviewShow && (
                            <div className="save_interview_subsection">
                              <p style={{fontWeight:"500"}}>Name: </p>
                              <input type="text" className="save_interview_node_input" 
                                onChange={(e) => setSaveInterviewNodeName(e.target.value)}
                                value={saveInterviewNodeName}
                                placeholder="Enter the interview name here!"
                              />
                            </div>
                          )
                        }

                        
                      </div>
                    </div>

                    <div className="popup_bottom_actions" style={{padding: "0rem 2rem", boxSizing:"border-box"}}>
                      <div></div>
                      <button className="save_interview_node_button" onClick={saveInterviewNode}>Save</button>
                    </div>
                  </div>
                </div>
              </Popup>

            </div>

            <div className="top_section">
{/* 
                <div className="interview_heading">
                  <FaIcons.FaPen />
                  <input className="create_stage_heading input" value={name} onChange={handleNameChange} />
                </div> */}

                <h1 className="create_stage_heading" style={{marginBottom:"2rem"}} >{name}</h1>               

                <div className="popup_close" onClick={() => setPopupOpen(false)}>
                  <X />
                </div>

                <div className="interview_node_switcher subnav">
                    <div className={`subnav_item ${activeSection === 'details' ? 'active_subnav_item' : ''}`} onClick={() => setActiveSection('details')}>
                        <p>Details</p>

                        {!detailsComplete && (
                            <div className="interview_section_incomplete">
                                !
                                {/* Note that this will actually be an orange circle / symbol.  */}
                            </div>
                        )}

                    </div>
                    <div className={`subnav_item ${activeSection === 'interviewers' ? 'active_subnav_item' : ''}`} onClick={() => setActiveSection('interviewers')}>
                        <p>Interviewers</p>

                        {!interviewersComplete && (
                            <div className="interview_section_incomplete">
                                !
                                {/* Note that this will actually be an orange circle / symbol.  */}
                            </div>
                        )}
                    </div>
                    <div className={`subnav_item ${activeSection === 'feedback' ? 'active_subnav_item' : ''}`} onClick={() => setActiveSection('feedback')}>
                        <p>Feedback</p>

                        {!feedbackComplete && (
                            <div className="interview_section_incomplete">
                                !
                                {/* Note that this will actually be an orange circle / symbol.  */}
                            </div>
                        )}

                    </div>
                    <div className={`subnav_item ${activeSection === 'scheduling' ? 'active_subnav_item' : ''}`} onClick={() => setActiveSection('scheduling')}>
                        <p>Scheduling</p>

                        {!schedulingComplete && (
                            <div className="interview_section_incomplete">
                                !
                                {/* Note that this will actually be an orange circle / symbol.  */}
                            </div>
                        )}
                    </div>
                    {/* <div className={`subnav_item ${activeSection === 'debrief' ? 'active_subnav_item' : ''}`} onClick={() => setActiveSection('debrief')}>
                        <p>Debrief</p>

                        {!debriefComplete && (
                            <div className="interview_section_incomplete">
                                !
                            </div>
                        )}
                    </div> */}
                </div>

                {activeSection === 'details' && (
                    <Details 
                      name={name}
                      handleNameChange={handleNameChange}
                      interviewType={interviewType}
                      setInterviewType={setInterviewType}
                      duration={duration}
                      handleDurationChange={handleDurationChange}
                      location={location}
                      handleLocationChange={handleLocationChange}
                      inPersonLocation={inPersonLocation}
                      setInPersonLocation={setInPersonLocation}
                      interviewNotes={interviewNotes}
                      setInterviewNotes={setInterviewNotes}
                      complete={detailsComplete}
                      setComplete={setDetailsComplete}
                    />
                )}
                {activeSection === 'interviewers' && (
                    <Interviewers 
                      interviewers={interviewers}
                      setInterviewers={setInterviewers}
                      teamMembers={teamMembers}
                      setHasEmails={setHasEmails}
                      complete={interviewersComplete}
                      setComplete={setInterviewersComplete}
                    />
                )}

                {activeSection === 'feedback' && (
                    <Feedback 
                      enabled={feedbackEnabled}
                      setEnabled={setFeedbackEnabled}
                      feedbackRemindersIn ={feedbackRemindersIn}
                      setFeedbackRemindersIn={setFeedbackRemindersIn}
                      selectedQuestions={selectedQuestions}
                      setSelectedQuestions={setSelectedQuestions}
                      feedbackForms={feedbackForms}
                      setFeedbackForms={setFeedbackForms}
                      selectedForm={selectedForm}
                      setSelectedForm={setSelectedForm}      
                      complete={feedbackComplete}
                      setComplete={setFeedbackComplete}
                    />
                )}
                {activeSection === 'scheduling' && (
                    <Scheduling 
                      automateScheduling={automateScheduling}
                      setAutomateScheduling={setAutomateScheduling}
                      automatedSchedulingChoice={automatedSchedulingChoice}
                      setAutomatedSchedulingChoice={setAutomatedSchedulingChoice}
                      enableInterviewReminder={enableInterviewReminder}
                      setEnableInterviewReminder={setEnableInterviewReminder}
                      reminderTime ={reminderTime}
                      setReminderTime={setReminderTime}
                      employerData={employerData}
                      employerID={employerID}
                      hasEmails={hasEmails}
                    />
                )}
                {activeSection === 'debrief' && (
                    <Debrief 
                      enabled={debriefEnabled}
                      setEnabled={setDebriefEnabled}
                      debriefDuration={debriefDuration}
                      setDebriefDuration={setDebriefDuration}
                      scheduleAutomatically={scheduleDebriefAutomatically}
                      setScheduleAutomatically={setScheduleDebriefAutomatically}
                      sendEmailReminders={sendEmailDebriefReminders}
                      setSendEmailReminders={setSendEmailDebriefReminders}
                      emailRemindersTime={debriefEmailRemindersTime}
                      setEmailRemindersTime={setDebriefEmailRemindersTime}
                      complete={debriefComplete}
                      debriefMembers={debriefMembers}
                      setDebriefMembers={setDebriefMembers}
                      setComplete={setDebriefComplete}
                      index={data.index}
                    />
                )}

                <div className="stage_bottom_actions interview">
                  <div className="stage_delete interview" onClick={deleteNode}>
                      Delete
                  </div>

                  <div className="stage_save interview" onClick={(e) => setPopupOpen(false)}>
                      Save
                  </div>
                </div>


              </div>
            </div>
      </div>
    </Popup>
  )
}

// export const InterviewNodeOld = ({ data }) => {

//     const [popupIsOpen, setPopupOpen] = useState(false);
//     const [complete, setComplete] = useState(false);
//     const [interviewers, setInterviewers] = useState([]);
//     const [newInterviewer, setNewInterviewer] = useState('');
//     const [suggestions, setSuggestions] = useState([]);
//     const [teamMembers, setTeamMembers] = useState([]);
//     const [employerID, setEmployerID] = useState(null);
//     const [canRun, setCanRun] = useState(false);
//     const [duration, setDuration] = useState(30);
//     const [numInterviewers, setNumInterviewers] = useState(1);
//     const [employerData, setEmployerData] = useState(null);
//     const [selectedForm, setSelectedForm] = useState(null);
//     const [formName, setFormName] = useState(null);
//     const [feedbackQuestions, setFeedbackQuestions] = useState(null);

//     const handleChangeNumInterviewers = (e) => {
//         const value = e.target.value;
//         if (value < 0) {
//           sendNotification("Please enter a valid number of interviewers!");
//           return;
//         }

//         if (value > 5) {
//           sendNotification("Please enter a number of interviewers less than 5!");
//           return;
//         }

//         if (value === "") {
//           setNumInterviewers(0);
//           return;
//         }
//         setNumInterviewers(parseInt(value));
//     }



//     const handleChangeDuration = (e) => {
//       const value = e.target.value;

//       if (value < 0) {
//         sendNotification("Please send a valid duration!");
//         return;
//       }

//       if (value > 90) {
//         sendNotification("Please enter a duration less than 90 minutes!");
//         return;
//       }

//       if (value === "") {
//         setDuration(0);
//         return;
//       }

//       setDuration(parseInt(value));
//     }

//     const [interviewerNotes, setInterviewerNotes] = useState('');

//     const handleInterviewerNotesChange = (e) => {
//         setInterviewerNotes(e.target.value);
//     };



//     const deleteNode = () => {
//         data.deleteNode(data.id, data.pipelineIndex);
//         setPopupOpen(false);
//     }

//     // Get team members

//     useEffect(() => {
//         const unsubscribe = auth.onAuthStateChanged((user) => {
//           if (user) {
//             setCanRun(true);
//           } else {
//             setCanRun(false);
//           }
//         });

//         return () => unsubscribe();
//       }, []);

//       useEffect(() => {
//         const fetchTeamMembers = async () => {
//           try {
//             const user = auth.currentUser;
//             if (!user) {
//               throw new Error('No authenticated user found');
//             }

//             const membersRef = collection(db, "members");
//             const qMember = query(membersRef, where("email", "==", user.email));
//             const memberSnapshot = await getDocs(qMember);
//             if (memberSnapshot.empty) {
//               throw new Error('Member not found');
//             }

//             const memberData = memberSnapshot.docs[0].data();
//             const { team_id } = memberData;
//             setEmployerID(team_id);

//             const teamSnapshot = await getDoc(doc(db, "teams", team_id));
//             if (!teamSnapshot.exists()) {
//               throw new Error('Team not found');
//             }

//             const teamData = teamSnapshot.data();
//             setEmployerData(teamData);
//             const teamMembersIds = teamData.members.map(member => member);
//             const teamMembersPromises = teamMembersIds.map(async id => {
//               const memberSnap = await getDoc(doc(db, "members", id));
//               if (!memberSnap.exists()) {
//                 throw new Error('Member not found');
//               }
//               const memberData = memberSnap.data();
//               return {
//                 id: id,
//                 ...memberData,
//               };
//             });

//             const team = await Promise.all(teamMembersPromises);
//             setTeamMembers(team);
//           } catch (error) {
//             console.error("Failed to fetch team members:", error);
//           }
//         };

//         fetchTeamMembers();
//     }, [canRun]);


//     const handleAddInterviewer = () => {
//         if (teamMembers.find(member => member.first_name === newInterviewer)) {
//             const member = teamMembers.find(member => member.first_name === newInterviewer);
//             if (!interviewers.some(interviewer => interviewer.name === newInterviewer)) {
//                 setInterviewers([...interviewers, { name: newInterviewer, id: member.id, required: false }]);
//             }
//             setNewInterviewer('');
//         } else {
//           alert("Please select a valid team member");
//         }
//     };

//     const handleInterviewerKeyPress = (e) => {
//         if (e.key === 'Enter') {
//           handleAddInterviewer();
//         }
//     };

//     const handleDeleteInterviewer = (index) => {
//         const updatedInterviewers = interviewers.filter((_, i) => i !== index);
//         setInterviewers(updatedInterviewers);
//     };

//     const toggleInterviewerRequired = (index) => {
//         const updatedInterviewers = interviewers.map((interviewer, i) =>
//           i === index ? { ...interviewer, required: !interviewer.required } : interviewer
//         );
//         setInterviewers(updatedInterviewers);
//     };


//     const getSuggestions = (value) => {
//         const inputValue = value.trim().toLowerCase();
//         const inputLength = inputValue.length;

//         return inputLength === 0 ? [] : teamMembers.filter(member =>
//           member.first_name.toLowerCase().includes(inputValue)
//         );
//     };

//       const getSuggestionValue = (suggestion) => suggestion.first_name;

//       const renderSuggestion = (suggestion) => (
//         <div>
//           {suggestion.first_name}
//         </div>
//       );

//       const onSuggestionsFetchRequested = ({ value }) => {
//         setSuggestions(getSuggestions(value));
//       };

//       const onSuggestionsClearRequested = () => {
//         setSuggestions([]);
//       };

//       const onSuggestionSelected = (event, { suggestionValue }) => {
//         setNewInterviewer(suggestionValue);
//         handleAddInterviewer(suggestionValue);
//       };

//     const inputProps = {
//         className: "interviewers_input_area",
//         placeholder: "Add a potential interviewer!",
//         value: newInterviewer,
//         onChange: (e, { newValue }) => {
//           setNewInterviewer(newValue);
//         },
//         onKeyPress: handleInterviewerKeyPress
//     };



//     // HANDLING SAVING THE DATA

//     useEffect(() => {
//         const nodeData = {
//           complete: complete,
//           interviewers: interviewers,
//           interviewerNotes: interviewerNotes,
//           interviewersNeeded: numInterviewers,
//           duration: duration,
//           feedbackQuestions: feedbackQuestions,
//           feedbackForm: formName,
//         }

//         updateNodeData(data.index, nodeData);
//     }, [interviewers, interviewerNotes, duration, numInterviewers, complete, feedbackQuestions])


//     // HANDLING THE COMPLETENESS

//     const [durationComplete, setDurationComplete] = useState(false);
//     const [interviewersComplete, setInterviewersComplete] = useState(false);
//     const [notesComplete, setNotesComplete] = useState(false);
//     const [numInterviewersComplete, setNumInterviewersComplete] = useState(false);
//     const [feedbackComplete, setFeedbackComplete] = useState(false);

//   useEffect(() => {
//     // if all are true then set complete to true

//     if (durationComplete && interviewersComplete && notesComplete && numInterviewersComplete && feedbackComplete) {
//       setComplete(true);
//     } else {
//       setComplete(false);
//     }
//   }, [durationComplete, interviewersComplete, notesComplete, numInterviewersComplete, feedbackComplete])

//   // now handling the completion of each subcategory


//   // Duration

//   useEffect(() => {
//       if (duration <= 0) {
//         setDurationComplete(false);
//       } else {
//         setDurationComplete(true);
//       }
//   }, [duration])

//   // Number of Interviewers

//   useEffect(() => {
//     if (numInterviewers <= 0) {
//       setNumInterviewersComplete(false);
//     } else {
//       setNumInterviewersComplete(true);
//     }
//   }, [numInterviewers])


//   // Interviewers

//   useEffect(() => {
//     if (interviewers.length >= numInterviewers) {
//       setInterviewersComplete(true);
//     } else {
//       setInterviewersComplete(false);
//     }
//   }, [interviewers, numInterviewers])

//   // notes

//   useEffect(() => {
//     if (interviewerNotes.length > 0) {
//       setNotesComplete(true);
//     } else {
//       setNotesComplete(false);
//     }
//   }, [interviewerNotes])

  


//     return (
    
//     <Popup trigger={

//         <div className='pipeline_node_display'>
//             <div className="pipeline_node_actual_display">
//               <p>Interview </p>
//               <FaIcons.FaExpandAlt />
//             </div>
            
//             <Handle type="target" position="left" id="5"  style={{pointerEvents:"none"}}/>
//             {/* <Handle type="target" position="top" id="6"  style={{pointerEvents:"none"}}/> */}
//             <Handle type="source" position="right" id="4"  style={{pointerEvents:"none"}}/>
//             {/* <Handle type="source" position="bottom" id="3"  style={{pointerEvents:"none"}}/> */}



//             {!complete && (
//                 <div className="node_incomplete">
//                     !
//                     {/* Note that this will actually be an orange circle / symbol.  */}
//                 </div>
//             )}

//         </div>

//     }

//     open={popupIsOpen}
//     onOpen={() => setPopupOpen(true)}
//     modal
//     nested
//     position="center center"
//     >

//     <div className="create_stage_holder">
//         <div className="create_stage">

//             <div className="top_section">

//               <h1 className="create_stage_heading"> Interview </h1>

//               <div className="popup_close" onClick={() => setPopupOpen(false)}>
//                 <X />
//               </div>


//               <div className="interview_top">
//                 <div className="duration_section interview_section">
//                   {!durationComplete && (
//                       <div className="node_incomplete">
//                           !
//                           {/* Note that this will actually be an orange circle / symbol.  */}
//                       </div>
//                   )}
//                   <h3 className="interview_section_heading" style={{margin:0}}>Duration</h3>
//                   <div className="interview_section_number_input_holder">
//                     <input
//                       type="number"
//                       className="interview_section_number_input"
//                       value={duration}
//                       onChange={handleChangeDuration}
//                     />

//                     <p>minute{duration !== 1 ? 's' : ''}</p>
//                   </div>
//                 </div>

//                 <div className="numInterviewersSection interview_section">
//                   {!numInterviewersComplete && (
//                       <div className="node_incomplete">
//                           !
//                           {/* Note that this will actually be an orange circle / symbol.  */}
//                       </div>
//                   )}

//                   <h3 className="interview_section_heading" style={{margin:0}}>Number of Interviewers</h3>

//                   <div className="interview_section_number_input_holder">

//                     {/* <FaIcons.FaPen style={{opacity:0.7, marginRight:"0.05rem"}} /> */}
//                     <input
//                       type="number"
//                       className="interview_section_number_input"
//                       value={numInterviewers}
//                       onChange={handleChangeNumInterviewers}
//                       style={{ width: "1.5rem" }}
//                     />

//                     {/* add an extra s if numInterviewers is more than 1 */}
//                     <p>interviewer{numInterviewers !== 1 ? 's' : ''}</p>
//                   </div>

//                 </div>
//               </div>

//               <div className="interview_bottom">

//                 <div className="interview_bottom_first">

//                   <FeedbackForm 
//                     employerData={employerData}
//                     employerID={employerID}
//                     complete={feedbackComplete} 
//                     setComplete={setFeedbackComplete}
//                     selectedForm={selectedForm}
//                     setSelectedForm={setSelectedForm}
//                     selectedQuestions={feedbackQuestions}
//                     setSelectedQuestions={setFeedbackQuestions}
//                     setFormName={setFormName}
//                   />
                  
//                   <div className="interviewers_section interview_section">
//                       {!interviewersComplete && (
//                           <div className="node_incomplete">
//                               !
//                               {/* Note that this will actually be an orange circle / symbol.  */}
//                           </div>
//                       )}

//                       <h3 className="interview_section_heading">Possible Interviewers</h3>

//                       <div className="interviewers_input_container">
//                           <Autosuggest
//                               className="autosuggest_testing"
//                               suggestions={suggestions}
//                               onSuggestionsFetchRequested={onSuggestionsFetchRequested}
//                               onSuggestionsClearRequested={onSuggestionsClearRequested}
//                               getSuggestionValue={getSuggestionValue}
//                               renderSuggestion={renderSuggestion}
//                               onSuggestionSelected={onSuggestionSelected}
//                               inputProps={inputProps}
//                               theme={{
//                                   container: 'autosuggest-container',
//                                   suggestionsContainer: 'suggestions-container',
//                                   suggestionsList: 'suggestions-list',
//                                   suggestion: 'suggestion-item'
//                               }}
//                           />
//                       </div>

//                       <div className="interviewers_list">
//                       {interviewers.map((interviewer, index) => (
//                           <div key={index} className="interviewer_item">
//                               <span>{interviewer.name}</span>

//                               <div style={{display:"flex", alignItems:"center"}}>
//                                   {
//                                       !interviewer.required ? (
//                                           <Tooltip title="Mark Required">
//                                               <IconButton>
//                                                   <FaRegUser
//                                                   className="interviewer_icon"
//                                                   onClick={() => toggleInterviewerRequired(index)}
//                                                   />
//                                               </IconButton>
//                                           </Tooltip>
//                                       ) : (

//                                           <Tooltip title="Mark Optional">
//                                               <IconButton>
//                                                   <FaUser
//                                                   className="interviewer_icon"
//                                                   onClick={() => toggleInterviewerRequired(index)}
//                                                   />
//                                               </IconButton>
//                                           </Tooltip>

//                                       )
//                                   }
//                                   <FaIcons.FaTrashAlt className="delete_interviewer" onClick={() => handleDeleteInterviewer(index)} />
//                               </div>
//                           </div>
//                       ))}
//                       </div>
//                   </div>
//                 </div>

               

//                 <div className="notes_section interview_section">

//                     {!notesComplete && (
//                         <div className="node_incomplete">
//                             !
//                             {/* Note that this will actually be an orange circle / symbol.  */}
//                         </div>
//                     )}

//                     <h3 className="interview_section_heading">Interview Criteria</h3>
//                     <textarea
//                         className="notes_input"
//                         placeholder="Please type what the interviewer should focus on!"
//                         value={interviewerNotes}
//                         onChange={handleInterviewerNotesChange}
//                     ></textarea>
//                 </div>

//               </div >

//             </div>


//             <div className="stage_bottom_actions">
//                 <div className="stage_delete" onClick={deleteNode}>
//                     Delete
//                 </div>

//                 <div className="stage_save" onClick={(e) => setPopupOpen(false)}>
//                     Save
//                 </div>
//             </div>

//         </div>
//     </div>

//     </Popup>
// )};
