import * as FaIcons from "react-icons/fa"
import React, { useState, useEffect, useRef } from 'react';
import Popup from "reactjs-popup";
import { X } from "lucide-react";
import "./styles/candidateDuplicate.css"
import { deleteDoc, doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";

export const CandidateDuplicateHandling = ({candidate, duplicates, jobs, candidates, setCandidates}) => {
    
    const [popupIsOpen, setPopupOpen] = useState(false);
    const [duplicateCandidates, setDuplicateCandidates] = useState([]);

    const [loading, setLoading] = useState(false);

    const setupDuplicates = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setPopupOpen(true);

        setLoading(true);
        const missingCandidates = candidate.duplicates.filter(duplicateId => !duplicates.some(duplicate => duplicate.id === duplicateId));
        const fetchAllCandidates = async () => {
            const missingCandidatesData = await Promise.all(missingCandidates.map(async (missingCandidate) => {
                const docRef = doc(db, "candidates", missingCandidate);
                const candidateSnap = await getDoc(docRef);
                return candidateSnap.exists() ? {...candidateSnap.data(), id: missingCandidate} : null;
            }));

            const validMissingCandidates = missingCandidatesData.filter(data => data !== null);
            // If any of the missing candidates don't exist, remove them from the duplicates array and update the duplicates array in firestore for the candidate
            if (validMissingCandidates.length !== missingCandidates.length) {
                const updatedDuplicates = duplicates.filter(duplicate => !validMissingCandidates.some(missingCandidate => missingCandidate.id === duplicate.id));
                await updateDoc(doc(db, "candidates", candidate.id), { duplicates: updatedDuplicates.map(duplicate => duplicate.id) });
            }

            const candidates = [...duplicates, ...validMissingCandidates, candidate]

            const fetchMissingJobs = async () => {

                candidates.forEach(candidate => {
                    const job = jobs.find(job => job.id === candidate.job_id);
                    if (job) {
                        candidate.job = job.jobTitle;
                    }
                });

                const missingJobs = candidates.filter(candidate => candidate.job_id && !jobs.some(job => job.id === candidate.job_id));
                const missingJobsData = await Promise.all(missingJobs.map(async (missingJob) => {
                    const jobRef = doc(db, "jobs", missingJob.job_id);
                    const jobSnap = await getDoc(jobRef);
                    if (jobSnap.exists) {
                        return {...jobSnap.data(), id: missingJob.job_id};
                    }
                    return null;
                }));
                const validMissingJobs = missingJobsData.filter(Boolean);
                validMissingJobs.forEach((job) => {
                    const index = candidates.findIndex(candidate => candidate.job_id && candidate.job_id === job.id);

                    if (index !== -1) {
                        candidates[index].job = job.jobTitle;
                    }
                });
                // Additionally, set the job for candidates that are in the jobs array
                
            };
            await fetchMissingJobs();

            setDuplicateCandidates(candidates);
        };
        fetchAllCandidates().finally(() => setLoading(false));

    }
    
    const [selectedCandidate, setSelectedCandidate] = useState(candidate.id);

    useEffect(() => {
        setSelectedCandidate(candidate.id);
    }, [candidate])

    const handleSelectCandidate = (candidateId) => {
        setSelectedCandidate(candidateId);
    };


    // handling the mark not duplicate popup

    const [markNotDuplicatePopupOpen, setMarkNotDuplicatePopupOpen] = useState(false);
    const [markNotDuplicateCandidate, setMarkNotDuplicateCandidate] = useState(null);

    const markNotDuplicate = async () => {
        const updatedCandidates = duplicateCandidates.map(duplicate => {
            if (duplicate.id !== markNotDuplicateCandidate.id) {
                const index = duplicate.duplicates.indexOf(markNotDuplicateCandidate.id);
                if (index !== -1) {
                    duplicate.duplicates.splice(index, 1);
                }
                const duplicateRef = doc(db, "candidates", duplicate.id);
                updateDoc(duplicateRef, { duplicates: duplicate.duplicates });
            } else {
                delete duplicate.duplicates;
                const candidateRef = doc(db, "candidates", markNotDuplicateCandidate.id);
                updateDoc(candidateRef, { duplicates: [] });
            }
            return duplicate;
        });

        const updatedCandidatesList = candidates.map(candidate => {
            if (candidate.id === markNotDuplicateCandidate.id) {
                delete candidate.duplicates;
            } else {
                if (candidate.duplicates) {
                    const index = candidate.duplicates.indexOf(markNotDuplicateCandidate.id);
                    if (index !== -1) {
                        candidate.duplicates.splice(index, 1);
                    }
                }
                
            }
            return candidate;
        });

        setCandidates(updatedCandidatesList);

        const filteredCandidates = updatedCandidates.filter(duplicate => duplicate.id !== markNotDuplicateCandidate.id);
        setDuplicateCandidates(filteredCandidates);
        setMarkNotDuplicatePopupOpen(false);
    }
    
    const openMarkNotDuplicatePopup = (candidate) => {
        setMarkNotDuplicateCandidate(candidate);
        setMarkNotDuplicatePopupOpen(true);
    }

    // handling candidate merging

    const mergeCandidates = () => {

        //  handling the candidates' notes

        setShowMergingMessage(true);

        const sortedDuplicates = duplicateCandidates.sort((a, b) => a.application_time.seconds - b.application_time.seconds);
        const mergedNotes = sortedDuplicates.reduce((acc, duplicate) => acc + (duplicate.notes ? duplicate.notes + "\n" : ""), "");

        // handling the candidates' emails. 

        const mergedEmailHistory = duplicateCandidates.reduce((acc, duplicate) => {
            return acc.concat(duplicate.emailHistory);
        }, []).sort((a, b) => a.time - b.time);


        const mergedHistory = duplicateCandidates.reduce((acc, duplicate) => {
            return acc.concat(duplicate.history || []);
        }, []).sort((a, b) => a.time - b.time);


        const updateSelectedCandidate = async () => {
            const selectedCandidateRef = doc(db, "candidates", selectedCandidate);

            await updateDoc(selectedCandidateRef, {
                notes: mergedNotes,
                emailHistory: mergedEmailHistory,
                history: mergedHistory,
                duplicates: [],
            });

            // Update the local candidates array
            candidate.duplicates = [];
        };

        const deleteDuplicateCandidates = async () => {
            await Promise.all(duplicateCandidates.filter(duplicate => duplicate.id !== selectedCandidate).map(duplicate => {
                const duplicateRef = doc(db, "candidates", duplicate.id);
                return deleteDoc(duplicateRef);
            }));
        };

        updateSelectedCandidate().then(() => {
            deleteDuplicateCandidates().then(() => {
                setShowMergingMessage(false);
                setShowMergedMessage(true);
                let timeoutId;
                if (timeoutId) clearTimeout(timeoutId);
                timeoutId = setTimeout(() => {
                    let updatedCandidates = candidates.map(candidate => {
                        if (candidate.id === selectedCandidate) {
                            return { ...candidate, duplicates: [] };
                        }
                        return !duplicateCandidates.some(duplicate => duplicate.id === candidate.id) ? candidate : null;
                    }).filter(Boolean);
                    setCandidates(updatedCandidates);
                    setShowMergedMessage(false);
                    setPopupOpen(false);
                }, 1500);
                
            }).catch((error) => {
                console.error("Error deleting duplicate candidates:", error);
            });
        }).catch((error) => {
            console.error("Error updating selected candidate:", error);
        });
    }

    const [showMergingMessage, setShowMergingMessage] = useState(false);

    const [showMergedMessage, setShowMergedMessage] = useState(false);
    

    // handling date formatting
    function getOrdinalSuffix(day) {
        if (day > 3 && day < 21) return 'th';
        switch (day % 10) {
            case 1: return 'st';
            case 2: return 'nd';
            case 3: return 'rd';
            default: return 'th';
        }
    }

    return (
        <>
        <div className="candidate_duplicate_icon" onClick={setupDuplicates}>
            <FaIcons.FaCopy />

            <div className="tiny_exclamation">
                !
            </div>
        </div>

        <Popup
            open={popupIsOpen}
            onOpen={() => setPopupOpen(true)}
            modal
            nested
            position="center center"
        >
            <div className="popup_holder">
                <div className="popup">
                    <div className="popup_close" onClick={() => setPopupOpen(false)}>
                        <X />
                    </div>

                    <h1 className="popup_heading">Duplicate Candidates</h1>

                    <div className="popup_body">
                        <p>The following candidates may be duplicates! Select a candidate to merge their information into:</p>

                        <div className="duplicate_candidates_holder">
                            <div className="duplicate_candidate_table_heading">
                                <div className="duplicate_candidate_selector">
                                    {/* space for the selector */}
                                </div>

                                <div className="duplicate_candidate_name">
                                    Name
                                </div>

                                <div className="duplicate_candidate_email">
                                    Email
                                </div>

                                <div className="duplicate_candidate_phone">
                                    Phone
                                </div>

                                <div className="duplicate_candidate_job_applied">
                                    Job Applied
                                </div>

                                <div className="duplicate_candidate_date_added">
                                    Date Added
                                </div>

                                <div className="duplicate_candidate_action">
                                    {/* Space for marking as not duplicate */}
                                </div>

                            </div>


                            <div className="duplicate_candidates_body">

                                {
                                    loading ? (
                                        <div className="duplicates_message">
                                            <p>Loading duplicate candidates...</p>
                                        </div>
                                    ) : (

                                        showMergingMessage ? (
                                            <div className="duplicates_message">
                                                <p>Merging candidates...</p>
                                            </div>
                                        ) : (

                                            showMergedMessage ? (
                                                <div className="duplicates_message">
                                                    <p>Merged candidates!</p>
                                                </div>
                                            ) : (
                                                duplicateCandidates && duplicateCandidates.map((duplicateCandidate) => (
                                                    <div className={`duplicate_candidate ${selectedCandidate === duplicateCandidate.id ? 'selected' : ''}`} onClick={() => handleSelectCandidate(duplicateCandidate.id)}>
                                                        <div className="duplicate_candidate_selector">
                                                            <div 
                                                                className={`duplicate_candidate_selector_radio ${selectedCandidate === duplicateCandidate.id ? 'selected' : ''}`} 
                                                                onClick={() => handleSelectCandidate(duplicateCandidate.id)}
                                                            />
                                                        </div>
    
                                                        <div className="duplicate_candidate_name">
                                                            {duplicateCandidate.name}
                                                        </div>
    
                                                        <div className="duplicate_candidate_email">
                                                            {duplicateCandidate.email}
                                                        </div>
    
                                                        <div className="duplicate_candidate_phone">
                                                            {duplicateCandidate.phone}
                                                        </div>
    
                                                        <div className="duplicate_candidate_job_applied">
                                                            {duplicateCandidate.job || '—'}
                                                        </div>
    
                                                        <div className="duplicate_candidate_date_added">
                                                            {(() => {
                                                                const date = new Date((duplicateCandidate.application_time.seconds || duplicateCandidate.application_time._seconds) * 1000);
                                                                const day = date.getDate();
                                                                const month = date.toLocaleString('en-GB', { month: 'long' });
                                                                return `${day}${getOrdinalSuffix(day)} ${month}`;
                                                            })()}
                                                        </div>
    
                                                        <div className="duplicate_candidate_action">
                                                            {/* Space for marking as not duplicate */}
                                                            <>
                                                                <div className={`mark_not_duplicate ${selectedCandidate === duplicateCandidate.id ? 'selected' : ''}`} onClick={(e) => openMarkNotDuplicatePopup(duplicateCandidate)}>
                                                                    Mark Not Duplicate
                                                                </div>  
                                                        
                                                                <Popup
                                                                    open={markNotDuplicatePopupOpen}
                                                                    modal
                                                                    nested
                                                                    position="center center"
                                                                >
                                                                    <div className="popup_holder second">
                                                                        <div className="popup" style={{width: "40%", height: "40%"}}>
                                                                            <h1 className="popup_heading">Are you sure?</h1>
                                                                            <div className="popup_body">
                                                                                <p>You’re about to mark this candidate as <b>not duplicate</b>!</p>
                                                                                <p>They will no longer be classified as a duplicate to the other candidate{duplicateCandidates.length > 1 ? 's' : ''} in this list.</p>                                                            
                                                                            </div>
                                                                            
                                                                            <div className="popup_bottom_actions" style={{width:"80%", bottom:"2rem"}}>
                                                                                <div className="mark_not_duplicate_cancel" onClick={(e) => setMarkNotDuplicatePopupOpen(false)}>
                                                                                    Cancel
                                                                                </div>
    
                                                                                <div className="mark_not_duplicate_confirm" onClick={() => markNotDuplicate()}>
                                                                                    Confirm
                                                                                </div>
                                                                            </div>
                                                                        </div>  
                                                                    </div>
                                                                </Popup>
                                                            </>
                                                        </div>
                                                    </div>
                                                ))
                                            )
                                            
                                        )
                                    )
                                }

                            </div>
                        </div>

                        <div className="merge_candidates_button" onClick={mergeCandidates}>
                            Merge Into Selected Candidate
                        </div>

                    </div>
                </div>
            </div>
        </Popup>
        </>
    )
}