// using this file to store the permissions given to each role

export const permissions = {
    "Super Admin": {
        "dashboard": {
            "view": true,
        },
        "candidates": {
            "view": true,
            "view_unrelated": true,
            "add": true,
            "edit": true,
            "send_email": true,
            "move_stage": true,
            "delete": true,
            "view_feedback": true
        },
        "all_candidates": {
            "view": true,
        },
        "team": {
            "view_all": true,
            "add": true,
            "edit": true,
            "delete": true,
            "add_superadmin": true,
            "view_profile": true,
            "delete_admin": true
        },
        "interviews": {
            "view_calendar": true,
            "view_events": true,
            "schedule": true,
        },
        "jobs": {
            "create": true,
            "edit": true,
            "delete": true,
            "view": true,
            "view_all": true,
        },
        "settings": {
            "delete_account": true,
            "edit_details": true,
            "change_templates": true,
            "change_scheduling": true,
            "view": true,
        }
    },
    "Admin": {
        "dashboard": {
            "view": true,
        },
        "candidates": {
            "view": true,
            "view_unrelated": true,
            "add": true,
            "edit": true,
            "send_email": true,
            "move_stage": true,
            "delete": true,
            "view_feedback": true
        },
        "all_candidates": {
            "view": true,
        },
        "team": {
            "view_all": true,
            "add": true,
            "edit": true,
            "delete": true,
            "add_superadmin": false,
            "view_profile": true,
            "delete_admin": false
        },
        "interviews": {
            "view_calendar": true,
            "view_events": true,
            "schedule": true,
        },
        "jobs": {
            "create": true,
            "edit": true,
            "delete": true,
            "view": true,
            "view_all": true,
        },
        "settings": {
            "delete_account": false,
            "edit_details": true,
            "change_templates": true,
            "change_scheduling": true,
            "view": true,
        }
    },
    "Recruiting Lead": {
        "dashboard": {
            "view": true,
        },
        "candidates": {
            "view": true,
            "view_unrelated": true,
            "add": true,
            "edit": true,
            "send_email": true,
            "move_stage": true,
            "delete": true,
            "view_feedback": true
        },
        "all_candidates": {
            "view": true,
        },
        "team": {
            "view_all": true,
            "add": false,
            "edit": false,
            "delete": false,
            "add_superadmin": false,
            "view_profile": true,
            "delete_admin": false
        },
        "interviews": {
            "view_calendar": true,
            "view_events": true,
            "schedule": true,
        },
        "jobs": {
            "create": true,
            "edit": true,
            "delete": true,
            "view": true,
            "view_all": true,
        },
        "settings": {
            "delete_account": false,
            "edit_details": false,
            "change_templates": true,
            "change_scheduling": true,
            "view": true,
        }
    },
    "Recruiter": {
        "dashboard": {
            "view": true,
        },
        "candidates": {
            "view": true,
            "view_unrelated": false,
            "add": true,
            "edit": true,
            "send_email": true,
            "move_stage": true,
            "delete": true,
            "view_feedback": true
        },
        "all_candidates": {
            "view": true,
        },
        "team": {
            "view_all": true,
            "add": false,
            "edit": false,
            "delete": false,
            "add_superadmin": false,
            "view_profile": false,
            "delete_admin": false
        },
        "interviews": {
            "view_calendar": true,
            "view_events": true,
            "schedule": true,
        },
        "jobs": {
            "create": true,
            "edit": true,
            "delete": false,
            "view": true,
            "view_all": false,
        },
        "settings": {
            "delete_account": false,
            "edit_details": false,
            "change_templates": false,
            "change_scheduling": false,
            "view": true,
        }
    },
    "Hiring Manager": {
        "dashboard": {
            "view": true,
        },
        "candidates": {
            "view": true,
            "view_unrelated": false,
            "add": true,
            "edit": false,
            "send_email": false,
            "move_stage": false,
            "delete": false,
            "view_feedback": true
        },
        "all_candidates": {
            "view": false,
        },
        "team": {
            "view_all": true,
            "add": false,
            "edit": false,
            "delete": false,
            "add_superadmin": false,
            "view_profile": false,
            "delete_admin": false
        },
        "interviews": {
            "view_calendar": true,
            "view_events": true,
            "schedule": false,
        },
        "jobs": {
            "create": true,
            "edit": true,
            "delete": false,
            "view": true,
            "view_all": false,
        },
        "settings": {
            "delete_account": false,
            "edit_details": false,
            "change_templates": false,
            "change_scheduling": false,
            "view": true,
        }
    },
    "Interviewer": {
        "dashboard": {
            "view": false,
        },
        "candidates": {
            "view": true,
            "view_unrelated": false,
            "add": false,
            "edit": false,
            "send_email": false,
            "move_stage": false,
            "delete": false,
            "view_feedback": false
        },
        "all_candidates": {
            "view": false,
        },
        "team": {
            "view_all": false,
            "add": false,
            "edit": false,
            "delete": false,
            "add_superadmin": false,
            "view_profile": false,
            "delete_admin": false
        },
        "interviews": {
            "view_calendar": true,
            "view_events": true,
            "schedule": false,
        },
        "jobs": {
            "create": false,
            "edit": false,
            "delete": false,
            "view": false,
            "view_all": false,
        },
        "settings": {
            "delete_account": false,
            "edit_details": false,
            "change_templates": false,
            "change_scheduling": false,
            "view": true,
        }
    },

}