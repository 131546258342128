import React, { useEffect, useRef, useState } from 'react';
import "./styles/calendar_settings.css"
import * as FaIcons from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { auth, db } from '../firebase';
import { collection, doc, getDoc, getDocs, query, updateDoc, where, arrayUnion, addDoc, deleteDoc, setDoc } from '@firebase/firestore';
import { useHistory, useLocation } from 'react-router-dom';
import { set } from 'date-fns';
import TimezoneSelect, { allTimezones, useTimezoneSelect } from "react-timezone-select"
import Popup from 'reactjs-popup';

// import { google } from 'googleapis';




// Component for individual time slot
const TimeSlot = ({ times, setTimes, index, removeTimeSlot }) => {
  const updateTimeSlot = (startOrEnd, value) => {
    const updatedTimes = [...times];
    updatedTimes[index][startOrEnd] = value;
    setTimes(updatedTimes);
  };

  const generateTimeOptions = (time) => {
    const options = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const suffix = hour >= 12 ? 'PM' : 'AM';
        const displayHour = hour % 12 === 0 ? 12 : hour % 12;
        const displayMinute = minute.toString().padStart(2, '0');
        const displayValue = `${displayHour}:${displayMinute} ${suffix}`;

        const value = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;

        if (time == value) {
            options.push(<option key={value} value={value} selected>{displayValue}</option>);
        } else {
            options.push(<option key={value} value={value}>{displayValue}</option>);
        }
        
        
      }
    }
    return options;
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
      <select
        value={times[index].start}
        onChange={(e) => updateTimeSlot('start', e.target.value)}
        className='timeslot_select'
      >
        {generateTimeOptions()}
        {/* ... other options ... */}
      </select>
      <span style={{ margin: '0 5px' }}>–</span>
      <select
        value={times[index].end}
        onChange={(e) => updateTimeSlot('end', e.target.value)}
        className='timeslot_select'
      >
        {generateTimeOptions()}
      </select>

      { (index > 0) && (
        <div onClick={() => removeTimeSlot(index)} className='remote_timeslot'>
          <FaIcons.FaTrash />
        </div>
      )}
    </div>
  );
};

// A functional component for the availability indicator




export const CalSettings = () => {

    const [canRun, setCanRun] = useState(false);
    const [memberID, setMemberID] = useState(null);
    const [hasTokens, setHasTokens] = useState(false);
    const [calendarTokens, setCalendarTokens] = useState(null);
    const [memberData, setMemberData] = useState(null);

    const initialSchedule = {
        Monday: [{ start: '09:00', end: '17:00' }],
        Tuesday: [{ start: '09:00', end: '17:00' }],
        Wednesday: [{ start: '09:00', end: '17:00' }],
        Thursday: [{ start: '09:00', end: '17:00' }],
        Friday: [{ start: '09:00', end: '17:00' }],
    };

    const [schedule, setSchedule] = useState(initialSchedule);

    const navigate = useNavigate();
    const location = useLocation();


    // check if calendar tokens are on the url

    const decryptAndStoreToken = async (encryptedToken) => {
      const response = await fetch('https://api.skillpool.tech/calendar_decrypt_token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ encryptedToken }),
      });

      // store this in the member document

      const tokens = JSON.parse((await response.json()).token)

      const docRef = doc(db, "members", memberID);
      updateDoc(docRef, {calendar_tokens: tokens})
      setMemberData({...memberData, calendar_tokens: tokens})
      setHasTokens(true);
      navigate('/calendar/settings');
      
    }

    useEffect(() => {

      if (memberID) {
        const getQueryParams = (query) => {
          return new URLSearchParams(query);
        }
  
        const queryParams = getQueryParams(location.search);
        const encryptedToken = queryParams.get('tokens');
        if (encryptedToken) {
          decryptAndStoreToken(encryptedToken);
        } 
      }
      
    
    }, [location, memberID])

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
            setCanRun(true);
            } else {
            // No user is signed in.
            setCanRun(false);
            }
        });

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, []);

    useEffect(() => {

        const getSchedule = async () => {

            try {
                const user = auth.currentUser;
                if (!user) {
                throw new Error('No authenticated user found');
                }
                
                // Step 1: Get the user's member document to find their team_id
                const membersRef = collection(db, "members");
                const qMember = query(membersRef, where("email", "==", user.email));
                const memberSnapshot = await getDocs(qMember);
                
                if (memberSnapshot.empty) {
                    throw new Error('Member not found');
                }
                const docs = memberSnapshot.docs[0]
                const user_id = docs.id;
                setMemberID(user_id);
                setMemberData(docs.data());

                const tokens = docs.data().calendar_tokens;

                if (tokens) {
                  const expiryDate = new Date(tokens.expiry_date);
                  const currentDate = new Date();
  
                  if (expiryDate > currentDate) {
                    setHasTokens(true);
                  }
                } else {
                  // trying to get them from local storage
                  const localTokens = localStorage.getItem('tokens');
                  if (localTokens) {
                    setHasTokens(true);
                    localStorage.removeItem('tokens');
                  }
                }

                const availability = docs.data().availability;
                if (availability) {
                    setSchedule(availability);
                } else {
                    
                    saveSchedule(user_id);
                }
                
            } catch (error) {
                console.error("Failed to fetch team members:", error);
            }
        };

        getSchedule();
    }, [canRun]);

   
    
    // Function to add a new time slot
    const addTimeSlot = (day) => {
        const newTimeSlot = { start: '9:00am', end: '5:00pm' };
        const updatedSchedule = {
            ...schedule,
            [day]: [...schedule[day], newTimeSlot],
        };
        setSchedule(updatedSchedule);
    };

    const removeTimeSlot = (day, index) => {
        const updatedTimes = schedule[day].filter((_, i) => i !== index);
        const updatedSchedule = {
            ...schedule,
            [day]: updatedTimes,
        };
        setSchedule(updatedSchedule);
    };

    const saveSchedule = (userID) => {
      setSavedAvailability(true);
      const docRef = doc(db, "members", userID);
      updateDoc(docRef, {availability: schedule})
      setTimeout(() => {
        setSavedAvailability(false)
      }, 1500)
        
    }

    const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"]


    // Handling the code for google calendar sync

    const syncCalendar = async () => {

      setSyncing(true);

      const tokens = memberData.calendar_tokens;
      const response = await fetch('https://api.skillpool.tech/calendar_auth_sync', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ tokens }),
      });

      const data = await response.json();

      // First, remove existing events with external: "google" and prepare new events
      const docRef = doc(db, "members", memberID);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const existingEvents = docSnap.data().events;
        const updatedEvents = existingEvents.filter(async event => {
          const eventRef = doc(db, "events", event);
          const eventSnap = await getDoc(eventRef);
          if (eventSnap.exists() && eventSnap.data().external === "google") {
            await deleteDoc(eventRef);
            return false; // Remove the event from the member's events array
          }
          return true; // Keep the event in the member's events array
        });

        // Iterate over the events and create new event objects with Firestore timestamps
        const newEvents = data.map(event => {
          const startDateTime = event.start.dateTime;
          const endDateTime = event.end.dateTime;
          const startDate = new Date(startDateTime);
          const endDate = new Date(endDateTime);
          return {
            startTime: startDate,
            endTime: endDate,
            title: `Google Calendar: ${event.summary}`,
            external: "google"
          };
        });

        // Create new event documents and add their IDs to the member's events array
        const newEventIds = [];
        for (const newEvent of newEvents) {
          const newEventRef = doc(collection(db, "events"));
          await setDoc(newEventRef, newEvent);
          newEventIds.push(newEventRef.id);
        }

        // Consolidate the update into one operation
        await updateDoc(docRef, {
          events: arrayUnion(...newEventIds, ...updatedEvents)
        });
      }

      setSyncing(false);
      setSynced(true);

      setTimeout(() => {
        setSynced(false);
      }, 1500)

    }


    const connectToCalendar = () => {
      window.location.href = 'https://api.skillpool.tech/calendar_auth_connect';
    }



    // Handling the timezone aspect

    const labelStyle = "original"
    const timezones = {
    ...allTimezones,
    "Europe/Berlin": "Frankfurt",
    }


    const { options, parseTimezone } = useTimezoneSelect({ labelStyle, timezones })


    const [selectedTimezone, setSelectedTimezone] = useState(parseTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone));

    useEffect(() => {
      if (memberData) {
        setSelectedTimezone(memberData.timezone);
      }
    }, [memberData])

    const timezoneRef = useRef(null);

    const customStyles = {
      control: (provided, state) => ({
        ...provided,
        backgroundColor: 'transparent',
        background: 'transparent',
        border: '1px solid #ccc',
        borderRadius: '0.5rem',
        fontSize: '14px',
        padding: '5px 10px',
        width: '40%',
        height: "3rem",
        display: 'flex',
        alignItems: 'center',
        marginBottom:"1rem",
      }),
      menu: (provided) => ({
        ...provided,
        width: '40%',
        borderRadius: '4px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      }),
    };

    const saveTimezone = () => {
      setSavedTimezone(true);
      const docRef = doc(db, "members", memberID);
      updateDoc(docRef, {timezone: selectedTimezone})

      setTimeout(() => {
        setSavedTimezone(false);
      }, 1500)
    }



    // handlign the confirmation messages

    const [savedTimezone, setSavedTimezone] = useState(false);
    const [savedAvailability, setSavedAvailability] = useState(false);
    const [syncing, setSyncing] = useState(false);
    const [synced, setSynced] = useState(false);


    // handling the copy day popup


    const copyAvailability = (selectedDayToCopy, daysToCopyTo, closePopup) => {

      
      if (selectedDayToCopy) {
        const updatedSchedule = { ...schedule };
        for (const dayToCopy of daysToCopyTo) {
          updatedSchedule[dayToCopy] = updatedSchedule[selectedDayToCopy];
        }
        setSchedule(updatedSchedule);
        closePopup();
      }
    }


    const CopyDayPopup = ({day, copyAvailability}) => {

      const [copyDayPopupOpen, setCopyDayPopupOpen] = useState(false);
      const [selectedDayToCopy, setSelectedDayToCopy] = useState(null);
      const [daysToCopyTo, setDaysToCopyTo] = useState([]);

      const openCopyDayPopup = (day) => {

        setSelectedDayToCopy(day);
        setDaysToCopyTo(days.filter(d => d !== day));
        setCopyDayPopupOpen(true);
      }
  
      const closeCopyDayPopup = () => {
        setCopyDayPopupOpen(false);
        setSelectedDayToCopy(null);
        setDaysToCopyTo([]);
      }

      const toggleDayToCopy = (day) => {
        if (daysToCopyTo.includes(day)) {
          setDaysToCopyTo(daysToCopyTo.filter(d => d !== day)); 
        } else {
          setDaysToCopyTo([...daysToCopyTo, day]);
        }
      }


      return (
        <>
        <Popup
        trigger={
          <div className='copy_day'>
            <FaIcons.FaCopy />
          </div>
        }
        position="bottom center"
        open={copyDayPopupOpen}
        onOpen={() => {
          openCopyDayPopup(day);
          setCopyDayPopupOpen(true);
        }}
        nested
        >
          <div className='copy_day_popup_overlay'>
            <div className='copy_day_popup'>
              <h4>Copy availability to:</h4>
                      {days.filter(d => d !== selectedDayToCopy).map(day => (
                          <div key={day} className='copy_day_option'>
  
                              <div
                                  className="checkbox"
                                  style={{
                                      border: "1px solid var(--primary)",
                                      width: ".7rem",
                                      height: ".7rem",
                                      borderRadius: "20%",
                                      backgroundColor: daysToCopyTo.includes(day) ? "var(--primary)" : "transparent",
                                      cursor: "pointer",
                                      marginRight:"0.5rem"
                                  }}
                                  onClick={(event) => {
                                      event.stopPropagation();
                                      event.preventDefault();
                                      toggleDayToCopy(day);
                                  }}
                              />
                              <label htmlFor={`copy-to-${day}`}>{day}</label>
                          </div>
                      ))}
                      <div className='copy_day_actions'>
                          <button onClick={() => setCopyDayPopupOpen(false)}>Cancel</button>
                          <button onClick={() => copyAvailability(selectedDayToCopy, daysToCopyTo, closeCopyDayPopup)}>Copy</button>
                      </div>
                  </div>
              </div>
        </Popup>
        </>
      )
    }




    return (
        <div className="calendar_settings_holder">

            <div className='cal_settings_heading'>
                <Link to="/calendar"><FaIcons.FaChevronLeft /></Link>
                <h1 >Calendar Settings</h1>
            </div>

            <div className='calendar_settings_body'>

            <div className='calendar_settings_section'>
              <h3 className='calendar_settings_section_heading'>Timezone</h3>

              {/* <div style={{display: "flex", alignItems:"center", width: "100%", border: "1px solid red"}}> */}
                <TimezoneSelect ref={timezoneRef} value={selectedTimezone} onChange={setSelectedTimezone} styles={customStyles} />
                
                {
                    savedTimezone ? 
                    (
                    <div className="save_availability">
                        <FaIcons.FaCheck style={{marginRight:"0.5rem"}} />
                        <p>Saved!</p>
                    </div>
                    ) : (
                      <div onClick={saveTimezone} className='save_availability'>
                          <FaIcons.FaSave style={{marginRight:"0.5rem"}} />
                          <p>Save</p>
                      </div>
                    )
                }

              {/* </div> */}

             
            </div>

            <div className='calendar_settings_section' style={{ maxHeight: "45%", overflowY: "auto"}}>

              <h3 className='calendar_settings_section_heading'>Availability</h3>

              <div className='weekly_availability'>
                  {days.map(day => (
                      <div key={day} className='day'>
                          <div className='day_name'>{day}</div>
                          <div className='day_slots'>
                              {schedule[day].map((times, index) => (
                                  <TimeSlot
                                      key={`${day}-${index}`}
                                      times={schedule[day]}
                                      setTimes={(updatedTimes) => {
                                      const updatedSchedule = { ...schedule, [day]: updatedTimes };
                                      setSchedule(updatedSchedule);
                                      }}
                                      index={index}
                                      removeTimeSlot={() => removeTimeSlot(day, index)}
                                  />
                              ))}
                          </div>
                          <div className='day_actions'>
                              <div className='add_new_day' onClick={() => addTimeSlot(day)}>
                                  <FaIcons.FaPlus />
                              </div>

                              <CopyDayPopup 
                                day={day} 
                                copyAvailability={copyAvailability} 
                              />
                              
                          </div>
                          <p> {schedule[{day}]} </p>
                      </div>
                  ))}
              </div>

              {
                  savedAvailability ? 
                  (
                  <div className="save_availability">
                      <FaIcons.FaCheck style={{marginRight:"0.5rem"}} />
                      <p>Saved!</p>
                  </div>
                  ) : (
                    <div onClick={(e) => saveSchedule(memberID)} className='save_availability'>
                        <FaIcons.FaSave style={{marginRight:"0.5rem"}} />
                        <p>Save</p>
                    </div>
                  )
              }

            </div>


            <div className='calendar_settings_section'>

              <h3 className='calendar_settings_section_heading'>Sync Calendars</h3>

              <div className='google_calendar_options'>
                {hasTokens && (
                  synced ? (
                    <div className='calendar_sync calendar_option'>
                      <FaIcons.FaCheck style={{marginRight:"0.5rem"}} />
                      Synced!
                    </div>
                  ) : (
                    syncing ? (
                      <div className='calendar_sync calendar_option'>
                        <FaIcons.FaSpinner style={{marginRight:"0.5rem"}} />
                        Syncing...
                      </div>
                    ) : (
                      <div className='calendar_sync calendar_option' onClick={syncCalendar}>
                        Sync with Google Calendar
                      </div>
                    )
                    
                  )
                )}
                  
                {!hasTokens && (
                  <div className='calendar_connect calendar_option' onClick={connectToCalendar}>
                    Connect to Google Calendar
                  </div>
                
                )}

              </div>

            </div>
            

            

            </div>

            

            



        </div>
    )
}
