import { X } from "lucide-react";
import { useState } from "react";
import Popup from "reactjs-popup";
import * as FaIcons from "react-icons/fa";
import { sendNotification } from "../notification";
import { deleteField, doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { globalPermissions } from "../../routing";

export const DeleteTeam = ({team, setTeams, teams, employerID, currentMember, members, setMembers , index}) => {

    const [popupIsOpen, setPopupOpen] = useState(false);

    const openDeletePopup = (e) => {
        
        e.stopPropagation();
        e.preventDefault();

        if (!globalPermissions.team.delete) {
            sendNotification("You do not have permission to delete teams");
            return;
        }



        setPopupOpen(true);
    }

    const closePopup = () => {
        setPopupOpen(false);
    }

    const [deleted, setDeleted] = useState(false);

    const deleteTeam = async () => {

        // to delete the team, we first have to actually remove the team from the teams array in the employer document
        const updatedTeams = teams.filter((team, i) => i !== index);
        setTeams(updatedTeams);

        const employerRef = doc(db, "teams", employerID);

        updateDoc(employerRef, {
            teams: updatedTeams
        });

        setDeleted(true); // Show deleting message

        // then we have to delete the team variable from all the members in the team. 

        team.members.forEach(async (member) => {
            const memberRef = doc(db, "members", member.id);
            await updateDoc(memberRef, {
                team: deleteField()
            });
        });

        const updatedMembers = members.map((member) => {
            if (team.members.includes(member.id)) {
                return {
                    ...member,
                    team: null
                }
            }
            return member;
        });

        setMembers(updatedMembers);

        setTimeout(() => {
            setDeleted(false);
            closePopup();
        },  1500);
    
    };

    return (
        <>
            <div className='member_action' onClick={openDeletePopup}>
                <FaIcons.FaTrash />
            </div>

            <Popup 
                open={popupIsOpen}
                modal
                nested
                position="center center"
            >
                <div className="popup_holder">
                    <div className="popup" style={{height: "35%", width: "35%"}}>
                        <h1 className="popup_heading">Delete Team</h1>

                        <div className="popup_close" onClick={closePopup}>
                            <X />
                        </div>

                        <div className="popup_body">
                            {
                                deleted ? 
                                <p>Team has been successfully deleted!</p>
                                : 
                                <>
                                    <p>Are you sure you want to delete this team?</p>
                                    <p>Any associated members will be unassigned from the team, but will <strong>not</strong> be deleted!</p>

                                    
                                </>
                            }
                        </div>  

                        <div className='actions_bottom'>
                            <div className='close_popup' onClick={closePopup}>Close</div>

                            <div className='create_member delete_member' onClick={deleteTeam}>
                                Delete
                            </div>
                        </div>

                    </div>
                </div>
            </Popup>
        </>
    )
}