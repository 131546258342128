import { Form } from "@quillforms/renderer-core";
import "@quillforms/renderer-core/build-style/style.css";
import { registerCoreBlocks } from "@quillforms/react-renderer-utils";
import * as FaIcons from "react-icons/fa"
import "./styles/create_job.css"
import "./custom_form_blocks/richTextBlock.js"
import "./custom_form_blocks/locationSelect.js"
import "./custom_form_blocks/skillSelector.js"
import { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import { Resizable, ResizableSkills } from "../components/resizable.js";
import { ReflexContainer, ReflexSplitter, ReflexElement} from 'react-reflex'
import 'react-reflex/styles.css'
import { LocationEntry } from "../components/location.js";
import NumberSlider from "../components/slider.js";
import { sendNotification } from "../components/notification.js";
import { Link, useNavigate } from "react-router-dom";
import { auth, db } from "../firebase.js";
import { collection, doc, getDoc, getDocs, query, where } from "@firebase/firestore";
import { PipelineEditor } from "../components/pipelineEditor.js";
import { HiSparkles } from "react-icons/hi2";
import Popup from "reactjs-popup";
import { Skeleton } from "@mui/material";
import { X } from "lucide-react";
import Autosuggest from "react-autosuggest";


// Handling all of the pipeline data

export var pipeline = [
  { label: 'applied'},
  { label: 'skillscore'},
  { label: 'hired' }
]; // Define the pipeline state

export var nodesOrder = []  // pipelines stores the nodes in the order they were placed. This array is to identify the order in whcih they actually are.

export const updateNodesOrder = (newOrder) => {
  nodesOrder = newOrder;
}

export const updatePipeline = (newPipeline) => {
  pipeline = newPipeline; // Function to update the pipeline state
  console.log(newPipeline);
  

  // checking to see if the pipeline is now complete
  let pipelineComplete = true;
  pipeline.forEach((node, index) => {
    if (node.complete !== undefined) {
      if (node.deleted !== true) {
        if (!node.complete) {
          pipelineComplete = false;
        }
      }
    }
  })

  if (pipelineComplete) {
    document.getElementById("pipeline_incomplete_section").style.display = "none";
    document.getElementById("pipeline_incomplete_section_placeholder").style.display = "flex";
  } else {
    document.getElementById("pipeline_incomplete_section").style.display = "block";
    document.getElementById("pipeline_incomplete_section_placeholder").style.display = "none";
  }
};

export const deleteNode = (index) => {
  pipeline[index].deleted = true;

  updatePipeline(pipeline);
}

export const updateNodeData = (index, data) => {

  console.log(index, data);

  let actualIndex = 0;
  let found = false;
  for (let i = 0; i < pipeline.length; i++) {
    if (!pipeline[i].deleted) {
      if (actualIndex === index) {
        found = true;
        Object.entries(data).forEach(([key, value]) => {
          pipeline[i][key] = value;
        });
        break;
      }
      actualIndex++;
    }
  }
  if (!found) {
    console.error(`Index ${index} is out of bounds or refers to a deleted node.`);
    return;
  }
  updatePipeline(pipeline);
};

export var job_location = null;

export var globalEmployerData = null;

// registerCoreBlocks();

const PageSwitcher = ({ count, currentActive }) => {
  return (
      <div className="page_switcher">
          {Array.from({ length: count }, (_, i) => (
              <div key={i} className={`page_switcher_dot ${i === currentActive ? 'active' : ''}`}></div>
          ))}
      </div>
  );
};



//Helper functions
function ToggleSwitch({ isOn, handleToggle, index }) {
  return (
    <div className={`toggle-switch ${isOn ? 'on' : 'off'}`} onClick={handleToggle}>
      <div className="toggle-switch-slider"></div>
      {index}
    </div>
  );
}


export const CreateJob = () => {

  let navigate = useNavigate();

  var animHappening = false;
  const jobInfoRef = useRef(null);
  const jobDescRef = useRef(null);
  const assignedTeamRef = useRef(null);
  const pipelineEditorRef = useRef(null);
  const teamRef = useRef(null);
  const [currentActive, setCurrentActive] = useState(0);

  const sections = [jobInfoRef, jobDescRef, pipelineEditorRef, assignedTeamRef];
  // const sections = [jobInfoRef, jobDescRef, pipelineEditorRef];

  const [employerID, setEmployerID] = useState(null);
  const [employerEmail, setEmployerEmail] = useState(null);
  const [employerData, setEmployerData] = useState(null);
    const [teamMembers, setTeamMembers] = useState([]);
    const [currentMemberID, setCurrentMemberID] = useState(null);
    const [canRun, setCanRun] = useState(false);
    const newRolePopup = useRef(null);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
            setCanRun(true);
            } else {
            // No user is signed in.
            setCanRun(false);
            }
        });

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, []);

    //Team Selection Functions
    //Team Selection Process

  const [toggles, setToggles] = useState([])

  // Step 3: Ensure at least one is always true
  const initiateToggles = (teamMembers) => {
    const initialToggles = [];
    teamMembers.forEach(element => {
      initialToggles.push(true);
    })
    // Use the setToggles function to update the state
    setToggles(initialToggles);
  };

  // Function to handle toggle change
  const handleToggle = (id) => {
    const newToggles = toggles.map((toggle, index) => {
      // If the current index matches the id, return the negated value, otherwise return the original value.
      return index === id ? !toggle : toggle;
    });
    // if (Object.values(newToggles).filter(val => val).length === 0) {
    //   newToggles[id] = true; // Prevent all toggles from being false
    // }
    if (teamMembers[id].role === "Admin") {
      sendNotification("Admin must be in the team!");
      return;
    }
    setToggles(newToggles);
  };


    useEffect(() => {

        const fetchTeamMembers = async () => {
            try {
                const user = auth.currentUser;
                if (!user) {
                throw new Error('No authenticated user found');
                }

                // Step 1: Get the user's member document to find their team_id
                const membersRef = collection(db, "members");
                const qMember = query(membersRef, where("email", "==", user.email));
                const memberSnapshot = await getDocs(qMember);
                if (memberSnapshot.empty) {
                throw new Error('Member not found');
                }

                setCurrentMemberID(memberSnapshot.docs[0].id);

                const memberData = memberSnapshot.docs[0].data();

                const { team_id } = memberData;
                setEmployerID(team_id);

                // Step 2: Use team_id to find the team and retrieve the members array
                const teamSnapshot = await getDoc(doc(db, "teams", team_id));
                if (!teamSnapshot.exists()) {
                    throw new Error('Team not found');
                }

                const teamData = teamSnapshot.data();
                setEmployerData(teamData);
                globalEmployerData = {
                  ...teamData,
                  id: team_id
                };
                setEmployerEmail(teamData.settings.employerEmail);
                const teamMembersIds = teamData.members.map(member => member);
                const teamMembersPromises = teamMembersIds.map(async id => {
                    const memberSnap = await getDoc(doc(db, "members", id));
                    if (!memberSnap.exists()) {
                        throw new Error('Member not found');
                    }
                    return {
                      id: memberSnap.id,
                      ...memberSnap.data(),
                    };
                });

                const team = await Promise.all(teamMembersPromises);
                setTeamMembers(team);
                initiateToggles(team);


            } catch (error) {
                console.error("Failed to fetch team members:", error);
            }
        };

        fetchTeamMembers();
    }, [canRun]);



  const previous = () => {
    if (!animHappening) {
      if (currentActive != 0) {
        animHappening = true;
        sections[currentActive].current.style.animation = "moveOutRight 1s ease";
        setTimeout(() => {
          sections[currentActive].current.style.display = "none";
          animHappening = false;
        }, 900);
        sections[currentActive - 1].current.style.display = "block";
        sections[currentActive - 1].current.style.animation = "moveInLeft 1s ease";
        setCurrentActive(currentActive - 1)
      }
    }

  }

  const next = () => {
    if (!animHappening) {
      if (currentActive != (sections.length - 1)) {
        animHappening = true;
        sections[currentActive].current.style.animation = "moveOutLeft 1s ease";
        setTimeout(() => {
          sections[currentActive].current.style.display = "none";
          animHappening = false;
        }, 900);
        sections[currentActive + 1].current.style.display = "block";
        sections[currentActive + 1].current.style.animation = "moveInRight 1s ease";
        setCurrentActive(currentActive+1);
      }
    }

  }

  const [jobDepartment, setJobDepartment] = useState('');

  const [jobDesc, setJobDesc] = useState('');

  const handleDescChange = (content, delta, source, editor) => {
    setJobDesc(editor.getHTML()); // or content for just the HTML content
  };

  const [salaryFrom, setSalaryFrom] = useState('');

  const handleSalaryFromChange = (e) => {
    // Prevent non-numeric characters
    const newValue = e.target.value.replace(/[^0-9.]/g, '');

    console.log(newValue);

    // Update the value state
    setSalaryFrom(newValue);
  };

  const [salaryTo, setSalaryTo] = useState('');

  const handleSalaryToChange = (e) => {
    // Prevent non-numeric characters
    const newValue = e.target.value.replace(/[^0-9.]/g, '');

    // Update the value state
    setSalaryTo(newValue);
  };


  //Sliders

  const [minYOE, setMinYOE] = useState(0)
  const handleMinYOEChange = (event) => {
    setMinYOE(event.target.value);
  };


  //Skills

  const [skills, setSkills] = useState([]);
  const [skillsInputValue, setSkillsInputValue] = useState('');
  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && skillsInputValue && skills.length < 3) {
      event.preventDefault();
      event.stopPropagation();
      const newSkills = [...skills, skillsInputValue.trim()];
      setSkills(newSkills);
      setSkillsInputValue('');
    }
  };

  const removeSkill = (index) => {
    const newSkills = skills.filter((_, i) => i !== index);
    setSkills(newSkills);
  };

   //Weights

   const [capabilityWeights, setCapabilityWeights] = useState([0.33, 0.33, 0.33]);
   const [skillsWeights, setSkillsWeights] = useState([0.33, 0.33, 0.33]);
   const [educationWeights, setEducationWeights] = useState([0.33, 0.33, 0.33]);
   const [experienceWeights, setExperienceWeights] = useState([0.33, 0.33, 0.33]);
   const [jobWeights, setJobWeights] = useState([0.33, 0.33, 0.33]);


  //Location

  const [location, setLocation] = useState(null);

  useEffect(() => {
    job_location = location;
  }, [location])

  const [isRemote, setIsRemote] = useState(false);

  useEffect(() => {
    setLocation(null);
  }, [isRemote])

  useEffect(() => {
    if (location !== null) {
      setIsRemote(false);
    }
  }, [location])


  //Submit

  const [creatingJob, setCreatingJob] = useState(false);

  const createJobSubmit = async () => {

    


    // TODO: ADD A CHECK TO SEE IF THE PIPELINE IS COMPLETE OR NOT, IF THERE ARE ANY NODES LEFT UNDONE.



    //Get all the data for the job, check if all the requireds are done, and then send it to the backend if yes.

    /*
    What all data to collect:
    1. Job Title
    2. Job Description
    3. Location
    4. Remote
    5. Position Type
    6. Salary
      a. From
      b. To
      c. Autoreject if higher?
    7. Minimum YOE
    8. Minimum Education
    9. Skills
    10. Weights
      a. Capability
      b. Skills
      c. Education
      d. Experience
      e. Job
    */

    let isFormIncomplete = false;



    pipeline.forEach((node, index) => {

      if (node.complete !== undefined) {
        if (node.deleted !== true) {
          if (!node.complete) {
            sendNotification("Please complete all parts of the pipeline!");
            isFormIncomplete = true;
          }
        }
      }
    })

    if (isFormIncomplete) {
      return;
    }

    const updatedPipeline = pipeline.filter(node => !node.deleted);

    const orderedPipeline = nodesOrder.map(index => updatedPipeline[index])
    // .filter(node => !node.deleted);


    //Should probably do checks for all of these first

    const job_title = document.getElementById('job_title').value;
    if (job_title == "") {
      sendNotification("Please enter a Title!");
      return;
    }

    if (jobDepartment == "") {
      sendNotification("Please enter a Department!");
      return;
    }

    if (jobDesc == "") {
      sendNotification("Please enter a Job Description!");
      return;
    }


    if (location == null && !isRemote) {
      sendNotification("Please enter a location (or select remote)!");
      return;
    }

    // if (skills.length == 0) {
    //   sendNotification("Please enter atleast 1 Skill!");
    //   return;
    // }



    //Not required

    const position_type = document.getElementById('position_type_select').value
    if (position_type == "") {
      sendNotification("Please enter the Position Type!");
      return;
    }

    if (assignedTeamMembers.length == 0) {
      sendNotification("Please assign atleast one recruiter to the job!");
      return;
    }



    setCreatingJob(true);

    const jobData = {
      createdTime: new Date().toISOString(),
      employer_id: employerID, //This will change later
      jobTitle: job_title,
      department: jobDepartment,
      jobDesc: jobDesc,
      location: location,
      remote: isRemote,
      position_type: position_type,
      salary: {
        from: salaryFrom,
        to: salaryTo,
      },
      pipeline: orderedPipeline,
      employerEmail: employerEmail,
      createdBy: currentMemberID,
      team: assignedTeamMembers,
    }

    const response = await fetch('https://api.skillpool.tech/create-job', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(jobData),
      // Include headers if your server requires them
    });

    setCreatingJob(false);


    navigate('../jobs');
  }


  const cancelCreateJob = () => {
    navigate('../jobs');
  }

  const getAllData = () => {

    try {
      const job_title = document.getElementById('job_title').value;





      //Not required

      const position_type = document.getElementById('position_type_select').value

      const jobData = {
        employer_id: employerID, //This will change later
        jobTitle: job_title,
        jobDesc: jobDesc,
        location: location,
        remote: isRemote,
        position_type: position_type,
        hired: false,
        salary: {
          from: salaryFrom,
          to: salaryTo,
          // autoreject: document.getElementById('autorejectIfHigher').checked,
        },
      }
      const encodedData = encodeURIComponent(JSON.stringify(jobData));
      const url = `https://jobs.skillpool.tech/preview?data=${encodedData}`;
      return url
    } catch {}

  }






  // Handlign the AI job description generation

  const [aiJobDescPopup, setAiJobDescPopup] = useState(false);

  const [descToDisplay, setDescToDisplay] = useState("");

  const [descriptionPlaceholder, setDescriptionPlaceholder] = useState(false);

  const closeJobDescPopup = () => {
    setDescToDisplay("");
    setAiJobDescPopup(false);
  }


  const generateJobDescription = async () => {

    setDescriptionPlaceholder(true);

    const jobTitle = document.getElementById('job_title').value;

    if (jobTitle == "") {
      sendNotification("Please enter a Title!");
      return;
    }

    setAiJobDescPopup(true);

    const response = await fetch('https://api.skillpool.tech/generate_job_description', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        jobTitle,
        jobDepartment,
        jobDesc,
        employerName: employerData.name,
        employerDesc: employerData.description,
      }),
        // Include headers if your server requires them
    });

    const description = await response.json();

    setDescriptionPlaceholder(false);
    setDescToDisplay(description);
    // get the string in the variable response between the two html tags (excluding the tags)



    // this shoudl take in the current job and the current job description, and then generate a job description based on that.
  }

  const acceptJobDescription = () => {
    setJobDesc(descToDisplay);
    closeJobDescPopup();
  }



  // handle assigning a team

  const [assignedTeamMembers, setAssignedTeamMembers] = useState([]);

  const [assignedTeamPopup, setAssignedTeamPopup] = useState(false);

  const closeAssignedTeamPopup = () => {
    setAssignedTeamPopup(false);
    setSelectedTeamMembers([]);
    setSelectedTeam(null);
    setSelectedRecruiters([]);
  }

  const [assignedTeamPopupSection, setAssignedTeamPopupSection] = useState("specific");

  const [selectedRecruiters, setSelectedRecruiters] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [selectedTeamMembers, setSelectedTeamMembers] = useState([]);

  const handleTeamChange = (e) => {
    // the team itself will have a list of member ids. Go through the teamMembers array to get the actual member objects and then set that as the selectedTeamMembers
    
    if (e.target.value === "") {
      setSelectedTeamMembers([]);
      setSelectedTeam(null);
      return;
    }

    const team = employerData.teams[e.target.value];
    const members = teamMembers.filter(member => team.members.some(teamMember => teamMember.id === member.id));
    setSelectedTeamMembers(members.map(member => ({ ...member, name: member.first_name + ' ' + member.last_name })));
    setSelectedTeam(e.target.value);
  }

  const [newInterviewer, setNewInterviewer] = useState('');



  const handleAddInterviewer = (interviewerName) => {
      
    if (teamMembers.find(member => member.first_name === interviewerName)) {
        const member = teamMembers.find(member => member.first_name === interviewerName);
        setSelectedRecruiters([...selectedRecruiters, { name: interviewerName, id: member.id, email: member.email, role: member.role }]);
        setNewInterviewer('');
    } 
  };

  const handleInterviewerKeyPress = (e) => {
      if (e.key === 'Enter') {
        handleAddInterviewer();
      }
  };



  const handleDeleteInterviewer = (index) => {
      const updatedInterviewers = selectedRecruiters.filter((_, i) => i !== index);
      setSelectedRecruiters(updatedInterviewers);
  };

  const handleSwitchSection = (section) => {
    setAssignedTeamPopupSection(section);
    setSelectedRecruiters([]);
    setSelectedTeam(null);
    setSelectedTeamMembers([]);
  }

  const [suggestions, setSuggestions] = useState([]);

  const getSuggestions = (value) => {
      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue.length;

      return inputLength === 0 ? [] : teamMembers.filter(member =>
        member.first_name.toLowerCase().includes(inputValue)
      );
  };

  const getSuggestionValue = (suggestion) => suggestion.first_name;

  const renderSuggestion = (suggestion) => (
      <div>
        {suggestion.first_name + " " + suggestion.last_name}
      </div>
  );

  const onSuggestionsFetchRequested = ({ value }) => {
      setSuggestions(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
      setSuggestions([]);
  };

  const onSuggestionSelected = (event, { suggestionValue }) => {
      setNewInterviewer(suggestionValue);
      handleAddInterviewer(suggestionValue);
  };

  const inputProps = {
      className: "interviewers_input_area",
      placeholder: "Add a potential interviewer!",
      value: newInterviewer,
      onChange: (e, { newValue }) => {
        setNewInterviewer(newValue);
      },
      onKeyPress: handleInterviewerKeyPress
  };

  const addRecruiter = () => {
    // adding a new recruiter here
    // first check the active section
    if (assignedTeamPopupSection === "specific") {
      const newRecruiters = selectedRecruiters.filter(recruiter => 
        !assignedTeamMembers.some(member => member.email === recruiter.email)
      );
      setAssignedTeamMembers([...assignedTeamMembers, ...newRecruiters]);
    } else {
      const newTeamMembers = selectedTeamMembers.filter(member => 
        !assignedTeamMembers.some(assigned => assigned.email === member.email)
      );
      setAssignedTeamMembers([...assignedTeamMembers, ...newTeamMembers]);
    }

    closeAssignedTeamPopup();
  }


  console.log(assignedTeamMembers);


  return (
    <form style={{width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems:"center"}}>
      <div className="create_job_container">

        <div className="header_section">
          <div></div>

          <a href={getAllData()} target="_blank" style={{textDecoration: "none", color:"black"}}>
          <div className="preview_button">
              <span style={{marginRight:"0.5rem"}}> Preview  </span>
              <FaIcons.FaExternalLinkAlt />
          </div>
          </a>
        </div>

        <div className="content_section">
          <div className="section_left_arrow section_arrow" onClick={previous}>
            <FaIcons.FaChevronLeft />
          </div>


          <div className="section_holder">

         

            <div className="create_job_section" ref={jobInfoRef} style={{display: "block"}}>

              <div className="job_info_section">

                <div className="job_info_subsection job_title_section">
                  <p>Let's start it off with the job title!</p>
                  <input type="text" id = "job_title" name="job_title" placeholder="Please enter the title here..." className="create_job_input" />
                </div>

                <div className="job_info_subsection job_category_section">
                  <p>Please select the department your job belongs to!</p>
                  <select name="job_type" id="job_type" className="create_job_select" style={{width: "80%"}} onChange={(e) => setJobDepartment(e.target.value)} value={jobDepartment}>
                    <option value="" selected disabled>Select</option>
                    <option value="Software Development">Software Development</option>
                    <option value="Data Science">Data Science</option>
                    <option value="Product Management">Product Management</option>
                    <option value="Marketing">Marketing</option>
                    <option value="Finance">Finance</option>
                    <option value="Sales">Sales</option>
                    <option value="Engineering">Engineering</option>
                    <option value="Design">Design</option>
                    <option value="Business">Business</option>
                    <option value="Consulting">Consulting</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                
              </div>

              <div className="job_description_section">

                <div className="job_description_section_heading">
                  <p>And now onto the job description!</p>


                  <div className="job_description_writeforme_holder" onClick={generateJobDescription}>
                      <div className="job_description_writeforme">
                        <HiSparkles style={{marginRight: ".5rem"}} />
                        <p>Write for me!</p>
                      </div>
                  </div>

                  <Popup
                    open={aiJobDescPopup}
                    modal
                    nested
                    position="center center"
                  >
                    <div className="popup_holder" style={{}}>
                      <div className="popup">
                          {/* <div className="popup_close" onClick={() => setPersonalizeRejectionPopupOpen(false)}>
                              <X />
                          </div> */}

                          <h1 className="popup_heading">Generated Job Description</h1>

                          <div className="popup_body">

                            {/* <> */}
                                {
                                  descriptionPlaceholder &&
                                  <div className="description_placeholder_holder" style={{marginTop:"1rem"}}>
                                    <Skeleton className="description_placeholder" style={{width: "50%", marginBottom:"1rem"}} />

                                    <Skeleton className="description_placeholder" style={{marginBottom:".3rem"}} />
                                    <Skeleton className="description_placeholder" style={{marginBottom:".3rem"}} />
                                    <Skeleton className="description_placeholder" style={{marginBottom:".3rem"}} />

                                    <Skeleton className="description_placeholder" style={{width: "50%", marginBottom:"1rem", marginTop:"2rem"}} />

                                    <Skeleton className="description_placeholder" style={{marginBottom:".3rem"}} />
                                    <Skeleton className="description_placeholder" style={{marginBottom:".3rem"}} />
                                    <Skeleton className="description_placeholder" style={{marginBottom:".3rem"}} />

                                    <Skeleton className="description_placeholder" style={{width: "50%", marginBottom:"1rem", marginTop:"2rem"}} />

                                    <Skeleton className="description_placeholder" style={{marginBottom:".3rem"}} />
                                    <Skeleton className="description_placeholder" style={{marginBottom:".3rem"}} />
                                    <Skeleton className="description_placeholder" style={{marginBottom:".3rem"}} />
                                  </div>
                                }

                                {
                                  !descriptionPlaceholder &&
                                  <div className="display_generated_job_desc" dangerouslySetInnerHTML={{
                                      __html: descToDisplay
                                    }}
                                  />
                                }
                              {/* </> */}

                          </div>

                          <div className="popup_bottom_actions personalize_actions">
                              <div className="personalize_cancel_holder" onClick={closeJobDescPopup}>
                                  <div className="personalize_cancel">
                                      Cancel
                                  </div>
                              </div>

                              <div className="personalize_accept_holder" onClick={acceptJobDescription}>
                                  <div className="personalize_accept">
                                      Accept
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  </Popup>



                </div>

                <ReactQuill theme="snow" value={jobDesc} onChange={handleDescChange} placeholder="Please enter the description here..." className="create_job_desc"/>
              </div>

            </div>

            <div className="create_job_section" ref={jobDescRef}>
              <p> Now some basic information about the job! </p>

              <table className="job_details_table">

                <tr>
                  <td>
                    <div className="job_location_section">
                      <p>Where is this job based?</p>
                      <LocationEntry value={location} setValue={setLocation}/>
                      <p style={{margin:"0.5rem 0rem", marginLeft:"0.5rem"}}><strong>or</strong></p>
                      <div style={{display: "flex", alignItems:"center"}}>
                        <div
                            style={{
                                border: "1px solid var(--primary)",
                                width: ".7rem",
                                height: ".7rem",
                                borderRadius: "20%",
                                marginRight:"0.5rem",
                                cursor: "pointer",
                                backgroundColor: isRemote ? "var(--primary)" : "transparent"
                            }}
                            onClick={(event) => {
                                event.stopPropagation();
                                setIsRemote(!isRemote);
                            }}
                        />
                        <p style={{margin:"0rem"}}>Is this a fully remote job?</p>
                      </div>
                    </div>
                  </td>

                  {/* <td>
                  <div className="skills">
                        <p>What are 1-3 skills that you're looking for?</p>
                        <input type="text" name="skills_enter" placeholder="Write a skill and press enter!" className="create_job_input enter_skills" value={skillsInputValue}
                            onChange={(e) => setSkillsInputValue(e.target.value)}
                            onKeyDown={handleKeyDown}
                            disabled={skills.length >= 3}/>
                        <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '8px' }} className="skills_showcase_area">
                          {skills.map((skill, index) => (
                            <div key={index} style={{
                              margin: '2px', padding: '4px 8px',
                              backgroundColor: '#ddeeff', borderRadius: '12px',
                              display: 'flex', alignItems: 'center'
                            }}>
                              {skill}
                              <div onClick={() => removeSkill(index)} style={{ marginLeft: '4px', cursor: 'pointer' }}>×</div>
                            </div>
                          ))}
                        </div>
                    </div>
                  </td> */}

                </tr>

                <tr>
                  <td>
                    <div className="salary_range_section">
                      <p>What's the salary range?</p>
                      <div className="salary_range">
                        <input type="text" id="salaryFrom" name="salaryFrom" placeholder="From" value={salaryFrom ? `$ ${salaryFrom}` : ''} onChange={handleSalaryFromChange} className="create_job_input salary_input" />
                        <div className="dash">–</div>
                        <input type="text" id="salaryTo" name="salaryTo" placeholder="To" value={salaryTo ? `$ ${salaryTo}` : ''} onChange={handleSalaryToChange} className="create_job_input salary_input" />
                      </div>

                      {/* <div style={{display: "flex", alignItems:"center"}}>
                        <input type="checkbox" id="autorejectIfHigher"/>
                        <p>Automatically reject applicants who demand a higher salary?</p>
                      </div> */}
                    </div>
                  </td>

                  {/* <td>
                    <div className="min_yoe">
                        <p>Is there a minimum years of experience?</p>

                        <div style={{display: "flex", alignItems: "center"}}>
                        <NumberSlider value={minYOE} onChange={handleMinYOEChange} max={30} />
                        <p>{minYOE}</p>
                        </div>

                    </div>

                  </td> */}
                </tr>

                <tr>
                  <td width={"50%"}>
                    <div className="position_type">
                      <p>What is the position type?</p>
                      <select name="position_type" id="position_type_select" className="create_job_select">
                        <option value="" selected disabled>Select</option>
                        <option value="Full-Time">Full-Time</option>
                        <option value="Part-Time">Part-Time</option>
                        <option value="Internship">Internship</option>
                      </select>
                    </div>
                  </td>

                  {/* <td width={"50%"}>
                    <div className="min_education">
                        <p>What about a minimum education requirement?</p>
                        <select name="min_education" id="min_education_select" className="create_job_select">
                        <option value="None" selected>None</option>
                        <option value="High School">High School</option>
                        <option value="Bachelors">Bachelor's</option>
                        <option value="Masters">Master's</option>
                        <option value="PhD">PhD</option>
                      </select>
                    </div>
                  </td> */}
                </tr>

              </table>



            </div>

            <div className="create_job_section" ref={pipelineEditorRef}>

              <div className="pipeline_incomplete_information" id="pipeline_incomplete_section">
                <div style={{marginBottom:"0.5rem"}}>
                  Let's go ahead and create a candidate pipeline to represent your recruitment process!
                </div>

                <div className="pipeline_incomplete_section">
                  <div className="pipeline_incomplete_display">
                    !
                  </div>
                  
                  Please expand and complete each stage that has been marked as incomplete!
                </div>
              </div>

              <div className="pipeline_incomplete_information"  id="pipeline_incomplete_section_placeholder">
                <div style={{marginBottom:"0.5rem"}}>
                  Let's go ahead and create a candidate pipeline to represent your recruitment process!
                </div>
              </div>

              <PipelineEditor />
              {/* <PipelineEditor pipeline={pipeline} updatePipeline={updatePipeline} /> */}
            </div>

            <div className="create_job_section" ref={assignedTeamRef}>

              <div className="assigned_team_section_instructions">
                  <p>Now you can assign the recruiters for this job!</p>

                  <Popup
                    trigger={
                      <div className="add_assigned_team_button">
                        <FaIcons.FaPlus />
                        <p>Add</p>
                      </div>
                    }
                    open={assignedTeamPopup}
                    onOpen={() => setAssignedTeamPopup(true)}
                    modal
                    nested
                    position="center center"
                  >
                    <div className="popup_holder"> 
                      <div className="popup">
                        <h1 className="popup_heading">Add Recruiter</h1>

                        <div className="popup_close" onClick={closeAssignedTeamPopup}>
                          <X />
                        </div>

                        <div className="add_interviewer_subnav">
                            <div className={`add_interviewer_section_option ${(assignedTeamPopupSection === "specific") ? 'selected' : ''}`} onClick={() => handleSwitchSection('specific')}>
                                Select Specific
                            </div>

                            <div  className={`add_interviewer_section_option ${(assignedTeamPopupSection === "team") ? 'selected' : ''}`} onClick={() => handleSwitchSection("team")}>
                                Select Team
                            </div>
                        </div>

                        <div className="popup_body">
                          {
                            assignedTeamPopupSection === "specific" && (
                              <div className="add_interviewer_section" style={{height: "75%", padding: "0rem 1rem", boxSizing: "border-box"}}>
                                  <p>Choose specific team members to add!</p>

                                  <div className="add_interviewer_selected_interviewers" style={{height: "100%"}}>
                                      <div className="interviewers_input_container">
                                          <Autosuggest
                                              className="autosuggest_testing"
                                              suggestions={suggestions}
                                              onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                                              onSuggestionsClearRequested={onSuggestionsClearRequested}
                                              getSuggestionValue={getSuggestionValue}
                                              renderSuggestion={renderSuggestion}
                                              onSuggestionSelected={onSuggestionSelected}
                                              inputProps={inputProps}
                                              theme={{
                                                  container: 'autosuggest-container',
                                                  suggestionsContainer: 'suggestions-container',
                                                  suggestionsList: 'suggestions-list',
                                                  suggestion: 'suggestion-item'
                                              }}
                                          />
                                      </div>

                                      <div className="interviewers_list specific">
                                          {selectedRecruiters.map((interviewer, index) => (
                                              <div key={index} className="interviewer_item">
                                                  <span>{interviewer.name}</span>

                                                  <div style={{display:"flex", alignItems:"center"}}>
                                                      <FaIcons.FaTrashAlt className="delete_interviewer" onClick={() => handleDeleteInterviewer(index)} />
                                                  </div>
                                              </div>
                                          ))}
                                      </div>
                                  </div>
                              </div>
                            )
                          }

                          {
                            assignedTeamPopupSection === "team" && (

                              <div className="add_interviewer_section" style={{height: "75%", padding: "0rem 1rem", boxSizing: "border-box"}}>
                                <p>Choose a team to add!</p>

                                <div className="team_section">
                                  <div className="team_selection_section">
                                      <select name="team_select" id="team_select" className="create_job_select" value={selectedTeam} onChange={(e) => handleTeamChange(e)}>
                                        <option value="" selected>Select Team</option>
                                        {
                                          employerData.teams.map((team, index) => (
                                            <option value={index} key={index}>{team.name}</option>
                                          ))
                                        }
                                      </select>
                                  </div>

                                  <div className="team_members">

                                    <div className="team_members_header">
                                        <div className="assigned_team_name">
                                          <p>Name</p>
                                        </div>

                                        <div className="assigned_team_email">
                                          <p>Email</p>
                                        </div>

                                        <div className="assigned_team_role">
                                          <p>Role</p>
                                        </div>
                                    </div>

                                    <div className="team_members_body">
                                      {
                                        selectedTeamMembers.map((member, index) => (
                                          <div key={index} className="assigned_team_member">
                                            <div className="assigned_team_name">
                                              <p>{member.first_name} {member.last_name}</p>
                                            </div>

                                            <div className="assigned_team_email">
                                              <p>{member.email}</p>
                                            </div>

                                            <div className="assigned_team_role">
                                              <p>{member.role}</p>
                                            </div>
                                          </div>
                                        ))
                                      }

                                      {
                                        selectedTeamMembers.length === 0 && (
                                          <div className="no_team_members">
                                            No team members yet!
                                          </div>
                                        )
                                      }
                                    </div>
                                      
                                  </div>
                                </div>
                              </div>
                              
                            )
                          }
                        </div>
                        

                        <div className="popup_bottom_actions">
                          <div className="popup_cancel" onClick={closeAssignedTeamPopup}>Cancel</div>
                          <div className="popup_action" onClick={addRecruiter}>Add</div>
                        </div>
                      </div>
                    </div>
                  </Popup>
                  
              </div>

              <div className="assigned_team_area">

                <div className="assigned_members_header">
                  <div className="assigned_team_name">
                    <p>Name</p>
                  </div>

                  <div className="assigned_team_email">
                    <p>Email</p>
                  </div>

                  <div className="assigned_team_role">
                    <p>Role</p>
                  </div>
                </div>


                  <div className="team_members_body">
                  {
                    assignedTeamMembers.map((member, index) => (
                      <div key={index} className="assigned_team_member">
                      
                        <div className="assigned_team_name">
                          <p>{member.name}</p>
                        </div>

                        <div className="assigned_team_email">
                          <p>{member.email}</p>
                        </div>

                        <div className="assigned_team_role">
                          <p>{member.role}</p>
                        </div>
                      </div>
                    ))
                  }

                  {
                    assignedTeamMembers.length === 0 && (
                      <div className="no_team_members">
                        No team members yet!
                      </div>
                    )
                  }
                  </div>
                  
                
              </div>
            </div>
            

          </div>

          <div className="section_right_arrow section_arrow" onClick={next}>
            <FaIcons.FaChevronRight />
          </div>
        </div>

        <div className="navigator_section">

            <div className="cancel_button" onClick={cancelCreateJob} style={{display: currentActive === 0 ? 'block' : 'none'}}>
              <Link to="../jobs" style={{textDecoration:"none", color: "black"}}>
                Cancel
              </Link>
            </div>

            <div className="createjob_navigation_button" onClick={previous} style={{display: currentActive > 0 ? 'block' : 'none'}}>
              Back
            </div>

            {
              currentActive === sections.length - 1 && (

                creatingJob ? (
                  <div className="creating_job_button" onClick={createJobSubmit} style={{display: currentActive === sections.length - 1 ? 'block' : 'none'}}>
                      Creating
                  </div>
                ) : (
                  <div className="submit_button" onClick={createJobSubmit} style={{display: currentActive === sections.length - 1 ? 'block' : 'none'}}>
                      Submit
                  </div>
                )
                
              )
            }

            
            
            <div className="createjob_navigation_button" onClick={next} style={{display: currentActive < sections.length - 1 ? 'block' : 'none'}}>
              Next
            </div>
        </div>

        <PageSwitcher count={sections.length} currentActive={currentActive} />

      </div>
    </form>
  )
}
