import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import { Timestamp, doc, getDoc, updateDoc } from 'firebase/firestore';
import Tooltip from '@mui/material/Tooltip';
import Popup from 'reactjs-popup';
import { Placeholder } from "./candidateEmailHistory";
import ReactQuill from "react-quill";
import * as FaIcons from "react-icons/fa";
import { set } from 'date-fns';



export const AskToApply = ({candidate, selectedJob, employer, jobs}) => {
    const [sendEmailPopupIsOpen, setSendEmailPopupOpen] = useState(false);

    const sendCandididateEmail = async (candidate, job) => {

        let emailBody = newEmailBody; 
        
        let subject = newEmailSubject;

        subject = subject.replace(/{{candidate_name}}/g, candidate?.name.split(" ")[0]);
        subject = subject.replace(/{{previous_job_title}}/g, job?.jobTitle);
        subject = subject.replace(/{{new_job_title}}/g, jobs.find(job => job.id === selectedJob).jobTitle);
        subject = subject.replace(/{{employer_name}}/g, employer.name);

        emailBody = emailBody.replace(/{{candidate_name}}/g, candidate?.name.split(" ")[0]);
        emailBody = emailBody.replace(/{{previous_job_title}}/g, job?.jobTitle);
        emailBody = emailBody.replace(/{{new_job_title}}/g, jobs.find(job => job.id === selectedJob).jobTitle);
        emailBody = emailBody.replace(/{{employer_name}}/g, employer.name);
        emailBody = emailBody.replace(/{{application_link}}/g, `<a href=${jobs.find(job => job.id === selectedJob).applicationLink}>Link To Apply!</a>`);

        fetch('https://api.skillpool.tech/send_email', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            senderEmail: candidate?.employerEmail,
            receiverEmail: candidate?.email,
            subject: subject,
            body: emailBody,
            candidateID: candidate.id,
        }),
        // Include headers if your server requires them
        });

        setNewEmailBody(emailBodyDefault);
        setNewEmailSubject(emailSubjectDefault);
        setSendEmailPopupOpen(false)
    
    }

    let emailBodyDefault;

    if (candidate.job_id) {
        emailBodyDefault = `<p>Dear {{candidate_name}},</p><p>I hope this message finds you well.</p><p>Firstly, I would like to extend my gratitude for your interest in the {{previous_job_title}} at {{employer_name}}. While we have decided to move forward with another candidate for that particular position, we were very impressed with your skills and experience.</p><p>I am reaching out because I believe your qualifications would be a great fit for another exciting opportunity we currently have available as a {{new_job_title}}. We think you could bring significant value to our team within this role.</p><p>If this position interests you, I would love to see you apply to {{employer_name}} at the following link.</p><p>{{application_link}}</p><p>Thank you once again for considering a career with {{employer_name}}.</p><p>I look forward to the possibility of working together in the future.</p><p>Best regards,</p><p>The {{employer_name}} Recruitment Team</p>`    
    } else {
        emailBodyDefault = `<p>Dear {{candidate_name}},</p><p>I hope this message finds you well.</p><p>Firstly, I would like to extend my gratitude for your interest in working at {{employer_name}}. While looking into your details, we were very impressed with your skills and experience.</p><p>I am reaching out because I believe your qualifications would be a great fit for another exciting opportunity we currently have available as a {{new_job_title}}. We think you could bring significant value to our team within this role.</p><p>If this position interests you, I would love to see you apply to {{employer_name}} at the following link.</p><p>{{application_link}}</p><p>Thank you once again for considering a career with {{employer_name}}.</p><p>I look forward to the possibility of working together in the future.</p><p>Best regards,</p><p>The {{employer_name}} Recruitment Team</p>`
    }

    const emailSubjectDefault = "New Opportunity at {{employer_name}}!";
    const [newEmailSubject, setNewEmailSubject] = useState(emailSubjectDefault);
    const [newEmailBody, setNewEmailBody] = useState(emailBodyDefault);

    useEffect(() => {
        var subject = newEmailSubject;
        var emailBody = newEmailBody;
        let newJob = jobs.find(job => job.id === selectedJob);
        subject = subject.replace(/{{candidate_name}}/g, candidate?.name.split(" ")[0]);
        subject = subject.replace(/{{previous_job_title}}/g, jobs.find(job => job.id === candidate.job_id)?.jobTitle);
        subject = subject.replace(/{{new_job_title}}/g, jobs.find(job => job.id === selectedJob).jobTitle);
        subject = subject.replace(/{{employer_name}}/g, employer.name);

        emailBody = emailBody.replace(/{{candidate_name}}/g, candidate?.name.split(" ")[0]);
        emailBody = emailBody.replace(/{{previous_job_title}}/g, jobs.find(job => job.id === candidate.job_id)?.jobTitle);
        emailBody = emailBody.replace(/{{new_job_title}}/g, newJob.jobTitle);
        emailBody = emailBody.replace(/{{employer_name}}/g, employer.name);
        let applyLink;
        if (newJob.applicationLink) {
            applyLink = newJob.applicationLink;
        } else {
            applyLink = `https://jobs.skillpool.tech/${newJob.employer_id}/job/${newJob.id}`;
        }
        emailBody = emailBody.replace(/{{application_link}}/g, `<a href=${applyLink}>Link To Apply!</a>`);

        setNewEmailBody(emailBody);
        setNewEmailSubject(subject);
    }, [newEmailSubject, newEmailBody])

    const handleEmailBodyChange = (content, delta, source, editor) => {
        setNewEmailBody(editor.getHTML()); // or content for just the HTML content
    };


    // Handling teh email template aspect

    const askToApplyPlaceholders = ["{{candidate_name}}", "{{employer_name}}", "{{previous_job_title}}", "{{new_job_title}}", "{{application_link}}"];


    return (
        <Popup trigger={

            <Tooltip title="Ask to Apply">
                <div className="best_fit_candidate_action candidate_ask_to_apply">
                    <FaIcons.FaRegPaperPlane />
                </div>
            </Tooltip>
        }
        open={sendEmailPopupIsOpen}
        onOpen={() => setSendEmailPopupOpen(true)}
        modal
        nested
        position="center center"
        >
            <div className="popup_holder" style={{backdropFilter:"none"}}>
                <div className="popup" style={{boxShadow:"none"}}>
                    <h1 className="popup_heading" style={{marginBottom:"1rem"}}>Send Email</h1>

                    <div className="candidate_send_email_description" style={{display: "flex", alignItems:"center", justifyContent:"space-between", width:"100%"}}>
                        <p style={{width:"70%"}}>Go ahead and write an email asking the candidate to apply to the new job! </p>
                        {/* <div className="send_email_template">
                            <select className="send_email_select" onChange={(e) => loadTemplate(e.target.value)}>
                                <option value="default" selected >Load Template</option>
                                <option value="interview_invite">Interview Invite</option>
                                <option value="no_update">No Update</option>
                                <option value="rejection_default">Rejection</option>
                                {/* <option value="rejection_authorization">Rejection - Authorization</option>
                                <option value="rejection_location">Rejection - Location</option>
                                <option value="rejection_sponsorship">Rejection - Sponsorship</option> */}
                                {/* <option value="screening_interview">Screening Interview Invite</option> */}
                            {/* </select> */}
                        {/* </div>  */}
                    </div>

                    

                    <div className="candidate_send_email_holder">
                        <div className="candidate_send_email_details">

                            <h3>Subject: </h3>
                            <input type="text" placeholder="Subject" value={newEmailSubject} onChange={(e) => setNewEmailSubject(e.target.value)} className="send_email_subject" />

                            <h3>Body: </h3>
                            <ReactQuill theme="snow" value={newEmailBody} onChange={handleEmailBodyChange} placeholder="Please enter the body here..." className="send_email_body"/>
                        </div>

                        <div className="candidate_send_email_placeholders">
                            <h3>Usable Placeholders</h3>
                            <div className="placeholders_list">
                                {askToApplyPlaceholders.map((text, index) => (
                                    <Placeholder key={index} text={text} />
                                ))}
                            </div>
                        </div>
                    </div>
                

                    <div className="popup_bottom_actions">
                        <div className="popup_cancel" onClick={(e) => setSendEmailPopupOpen(false)}>
                            Cancel
                        </div>

                        <div className="popup_action" onClick={() => sendCandididateEmail(candidate, jobs.find(job => job.id === candidate.job_id))}>
                            Send
                        </div>
                    </div>

                </div>
            </div>
        </Popup>

    )
}