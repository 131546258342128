import React, { useEffect, useState } from "react";
import './styles/sidebar.css';
import * as FaIcons from "react-icons/fa"
import { auth, db } from "../firebase";
import { BrowserRouter as Router, Route, Link, Routes, NavLink } from 'react-router-dom';
import { collection, getDocs, query, where } from "firebase/firestore";
import { Tasks } from "./tasks";
import { Alerts } from "./alerts";

var globalEmployerID = null;


function Sidebar({permissions}) {

  const [canRun, setCanRun] = useState(false);
  const [employerID, setEmployerID] = useState(globalEmployerID);

  const [member, setMember] = useState(null);

  useEffect(() => {
      const unsubscribe = auth.onAuthStateChanged((user) => {
          if (user) {
          setCanRun(true);
          } else {
          // No user is signed in.
          setCanRun(false);
          }
      });

      // Cleanup subscription on unmount
      return () => unsubscribe();
  }, []);



  useEffect(() => {



      const getTeam = async () => {
          try {
            const user = auth.currentUser;
            if (!user) {
            throw new Error('No authenticated user found');
            }

            // Step 1: Get the user's member document to find their team_id
            const membersRef = collection(db, "members");
            const qMember = query(membersRef, where("email", "==", user.email));
            const memberSnapshot = await getDocs(qMember);
            if (memberSnapshot.empty) {
            throw new Error('Member not found');
            }

            const memberData = memberSnapshot.docs[0].data();
            setMember({
                id: memberSnapshot.docs[0].id,
                ...memberData
            });
            const { team_id } = memberData;
            setEmployerID(team_id)
            globalEmployerID = team_id

          } catch (error) {
              console.error("Failed to fetch user data", error);
          }
      };

      if (employerID === null && canRun) {
        getTeam();
      }

    }, [canRun]);


    //   handling the tasks section

    const [tasks, setTasks] = useState([]);

    const [isOpen, setIsOpen] = useState(false);

    const toggleContainer = () => {
        setIsOpen(!isOpen);
    };


    // handling the alerts section

    const [alerts, setAlerts] = useState([]);

    const [alertsIsOpen, setAlertsIsOpen] = useState(false);

    const toggleAlertsContainer = () => {
        setAlertsIsOpen(!alertsIsOpen);
    };
    


    return (
        <>
        <div className="overall_nav_holder">
            <nav id="nav">
                <div className="navHolder">

                    <div className="topSection">
                        {/* add the logo and branding here */}
                        <div className="logo"></div>
                        {/* <h1>SkillPool</h1> */}
                    </div>


                    <div className="midSection">
                        <ul style={{fontSize: "1rem"}}>
                            
                            {
                                 permissions?.dashboard.view && (
                                    <li><NavLink to="/" className={({ isActive }) => isActive ? 'navItemActive' : 'navItem'}>Dashboard</NavLink></li>
                                )
                            }

                            {
                                permissions?.jobs.view && (
                                    <li><NavLink to="/jobs" className={({ isActive }) => isActive ? 'navItemActive' : 'navItem'}>Jobs</NavLink></li>
                                )
                            }
                            
                            {
                                permissions?.all_candidates.view && (
                                    <li><NavLink to="/candidates" className={({ isActive }) => isActive ? 'navItemActive' : 'navItem'}>All Candidates</NavLink></li>
                                )
                            }

                            {
                                permissions?.team.view_all && (
                                    <li><NavLink to="/team" className={({ isActive }) => isActive ? 'navItemActive' : 'navItem'}>Team</NavLink></li>
                                )
                            }
                            <li><NavLink to="/calendar" className={({ isActive }) => isActive ? 'navItemActive' : 'navItem'}>Calendar</NavLink></li>
                            {/* <li><NavLink to="/reports" className={({ isActive }) => isActive ? 'navItemActive' : 'navItem'}>Reports</NavLink></li> */}
                        </ul>
                    </div>

                    <div className="bottomSection">
                        <ul>

                            <li style={{marginBottom:".75rem"}}>
                              <a onClick={toggleAlertsContainer} target="_blank" rel="noreferrer" className='navItem'>
                                <div style={{display: "flex", alignItems: "center", fontSize: "1.2rem", position:"relative"}}>
                                  <FaIcons.FaRegBell />
                                  <span style={{marginLeft: "0.5rem", fontSize: "1rem"}}>Alerts</span>
                                  {alerts && alerts?.length > 0 && (
                                        <div className="tasks_heading_count">
                                            {alerts.length} 
                                        </div>
                                    )}
                                </div>
                              </a>
                            </li>


                            <li style={{marginBottom:".75rem"}}>
                              <a onClick={toggleContainer} target="_blank" rel="noreferrer" className='navItem'>
                                <div style={{display: "flex", alignItems: "center", fontSize: "1.2rem", position:"relative"}}>
                                  <FaIcons.FaTasks />
                                  <span style={{marginLeft: "0.5rem", fontSize: "1rem"}}>Tasks</span>
                                  {tasks && tasks?.length > 0 && (
                                        <div className="tasks_heading_count">
                                            {tasks.length} 
                                        </div>
                                    )}
                                </div>
                              </a>
                            </li>

                            <li style={{marginBottom:".75rem"}}>
                              <a href={"https://jobs.skillpool.tech/" + employerID} target="_blank" rel="noreferrer" className='navItem'>
                                <div style={{display: "flex", alignItems: "center", fontSize: "1.2rem"}}>
                                  <FaIcons.FaExternalLinkAlt />
                                  <span style={{marginLeft: "0.5rem", fontSize: "1rem"}}>Jobs Page</span>
                                </div>
                              </a>
                            </li>
                            <li>
                                <NavLink to="/settings" className={({ isActive }) => isActive ? 'navItemActive' : 'navItem'}>
                                    <div style={{display: "flex", alignItems: "center", fontSize: "1.2rem"}}>
                                        <FaIcons.FaCog />
                                        <span style={{marginLeft: "0.5rem", fontSize: "1rem"}}>Settings</span>
                                    </div>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>


        <Alerts 
            isOpen={alertsIsOpen} 
            toggleContainer={toggleAlertsContainer}
            alerts={alerts}
            setAlerts={setAlerts}
            member={member}
        />

        <Tasks 
            isOpen={isOpen} 
            toggleContainer={toggleContainer}
            tasks={tasks}
            setTasks={setTasks}
            member={member}
        />

        
        </>
    );

};

export default Sidebar;
