import { useState } from "react";



export const PrettyCheckbox = ({checked, setChecked}) => {


    return (
        <div
            className="checkbox"
            style={{
                border: "1px solid var(--primary)",
                width: ".7rem",
                height: ".7rem",
                borderRadius: "20%",
                backgroundColor: checked ? "var(--primary)" : "transparent",
                cursor: "pointer",
                marginRight:"0.5rem"
            }}
            onClick={(event) => {
                event.stopPropagation();
                setChecked(!checked);
            }}
        />
    )
}