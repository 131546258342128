import React, { useEffect, useState, useRef } from 'react';
import { collection, query, where, getDocs, getDoc, doc, addDoc, updateDoc, arrayUnion } from 'firebase/firestore';
import "./styles/team.css"
import { auth, db } from '../firebase';
import Popup from 'reactjs-popup';
import { Link, Outlet } from 'react-router-dom';
import * as FaIcons from 'react-icons/fa';
import { sendNotification } from "../components/notification";
import { EditMember } from '../components/teamActions/editMember';
import { DeleteMember } from '../components/teamActions/deleteMember';
import { X } from 'lucide-react';
import { FiRefreshCcw } from "react-icons/fi";
import Autosuggest from "react-autosuggest";
import { EditTeam } from '../components/teamActions/editTeam';
import { DeleteTeam } from '../components/teamActions/deleteTeam';


export const Team = ({permissions}) => {

    const [employerID, setEmployerID] = useState(null);
    const [currentMember, setCurrentMember] = useState(null);
    const [teamMembers, setTeamMembers] = useState([]);
    const [canRun, setCanRun] = useState(false);
    const [teams, setTeams] = useState([]);
    const newRolePopup = useRef(null);

    useEffect(() => {
      const unsubscribe = auth.onAuthStateChanged((user) => {
          if (user) {
          setCanRun(true);
          } else {
          // No user is signed in.
          setCanRun(false);
          }
      });

      // Cleanup subscription on unmount
      return () => unsubscribe();
    }, []);


    useEffect(() => {

        const fetchTeamMembers = async () => {
            try {
                const user = auth.currentUser;
                if (!user) {
                throw new Error('No authenticated user found');
                }

                // Step 1: Get the user's member document to find their team_id
                const membersRef = collection(db, "members");
                const qMember = query(membersRef, where("email", "==", user.email));
                const memberSnapshot = await getDocs(qMember);
                if (memberSnapshot.empty) {
                throw new Error('Member not found');
                }

                const memberData = memberSnapshot.docs[0].data();
                setCurrentMember(memberData);
                const { team_id } = memberData;
                setEmployerID(team_id);

                // Step 2: Use team_id to find the team and retrieve the members array
                const teamSnapshot = await getDoc(doc(db, "teams", team_id));
                if (!teamSnapshot.exists()) {
                    throw new Error('Team not found');
                }

                const teamData = teamSnapshot.data();
                const teamMembersIds = teamData.members;
                const validMemberIds = [];
                const teamMembersPromises = teamMembersIds.map(async id => {
                    const memberSnap = await getDoc(doc(db, "members", id));
                    if (!memberSnap.exists()) {
                        return null; // Return null for non-existing members
                    }
                    validMemberIds.push(id); // Add valid member ID to the array
                    return {...memberSnap.data(), id: id};
                });

                let team = await Promise.all(teamMembersPromises);
                team = team.filter(member => member !== null); // Remove null entries
                // Check if any IDs were removed, and update the team document if necessary

                // this accounts for when I delete members (since that doesnt update the team immedidately)
                if (validMemberIds.length !== teamMembersIds.length) {
                    await updateDoc(doc(db, "teams", team_id), {
                        members: validMemberIds
                    });
                }
                setTeamMembers(team);
                setTeams(teamData.teams || []);
            } catch (error) {
                console.error("Failed to fetch team members:", error);
            }
        };

        fetchTeamMembers();
    }, [canRun]);


    const [addedMember, setAddedMember] = useState(false);

    const addNewMember = async () => {
        const first_name = memberName;
        const last_name = memberSurname;
        const email = memberEmail;
        const role = memberRole;
        const department = memberDepartment;

        if (first_name == "") {
            sendNotification("Please enter the first name!");
            return;
        }

        if (last_name == "") {
            sendNotification("Please enter the last name!");
            return;
        }

        if (email == "") {
            sendNotification("Please enter the email!");
            return;
        }

        if (role == "") {
            sendNotification("Please select a role!");
            return;
        }

        if (role == "Super Admin") {
          if (!permissions.team.add_superadmin) {
            sendNotification("You cannot add a super admin!");
            return;
          }
        }

        // setAddMemberMessage(true);

        try {

         

          // Step 1: Add a new member to the 'members' collection
          const membersRef = collection(db, 'members');
          const newMemberRef = await addDoc(membersRef, {
              email: email,
              first_name: first_name,
              last_name: last_name,
              role: role,
              department: department,
              team_id: employerID,
              team: memberTeam,
              availability: currentMember.availability,
              timezone: currentMember.timezone,
          });

           // we must check to update the teams
           let updatedTeams = teams;
           if (memberTeam !== "") {
             updatedTeams = teams.map(team => {
               if (team.name === memberTeam) {
                 return {
                   ...team,
                   members: [...team.members, {
                     id: newMemberRef.id,
                     first_name: first_name,
                     last_name: last_name,
                   }]
                 }
               }
               return team;
             });
             setTeams(updatedTeams);
           }

          // Step 2: Update the 'teams' document to include the new member's ID in the 'members' array
          const teamDocRef = doc(db, 'teams', employerID); // Assuming employerID is the document ID for the team
          await updateDoc(teamDocRef, {
            members: arrayUnion(newMemberRef.id), // Add the new member's ID to the 'members' array
            teams: updatedTeams,
          });


          // append the new member to the teamMembers array

          const newMember = {
              first_name: first_name,
              last_name: last_name,
              email: email,
              team_id: employerID,
              team: memberTeam,
              role: role,
              department: department,
          }

          setTeamMembers([...teamMembers, {...newMember, id: newMemberRef.id}]);

          setAddedMember(true);

          setTimeout(() => {
            setAddedMember(false);
            closeAllPopups();
          }, 1500);
        } catch (error) {
          console.error('Error adding new member and updating team:', error);
        }



    }


    const [addedGroup, setAddedGroup] = useState(false);

    const addNewGroup = async () => {

      console.log("adding new group");

      if (teamName == "") {
        sendNotification("Please enter the team name!");
        return;
      }

      let updatedTeams = teams;

      const groupData = {
        name: teamName,
        department: teamDepartment,
        members: newTeamMembers.map(member => {
          return {
            first_name: member.first_name,
            last_name: member.last_name,
            id: member.id
          }
        }),
      }

      updatedTeams = [...updatedTeams, groupData];

      // change the team value for each member in the new team

      // if any of the members are already in another team, they need to be removed from that team. 
      // and you would also need to update the members' array within the local state (teamMembers)

      newTeamMembers.forEach(async member => {

        if (member.team !== "") {
          // clearly this person was already in a different team.
          updatedTeams = updatedTeams.map(team => {
            if (team.name === member.team) {
              return {
                ...team,
                members: team.members.filter(m => m.id !== member.id)
              }
            }
            return team;
          });
          
        }

        await updateDoc(doc(db, "members", member.id), {
          team: teamName,
          department: teamDepartment
        });
      });


      const updatedTeamMembers = teamMembers.map(member => {
        if (newTeamMembers.some(newMember => newMember.id === member.id)) {
          return {
            ...member,
            team: teamName,
            department: teamDepartment
          }
        }
        return member;
      });

      setTeamMembers(updatedTeamMembers);
      // add the new team to the teams array within the employer document
      const employerDocRef = doc(db, "teams", employerID);
      await updateDoc(employerDocRef, {
        teams: updatedTeams
      });

      setTeams(updatedTeams);

      setAddedGroup(true);

      setTimeout(() => {
        setAddedGroup(false);
        closeAllPopups();
      }, 1500);

    }

    


    // handle the team members actions

    const [activePopupMemberId, setActivePopupMemberId] = useState(null);

    const openMemberActionsPopup = (memberId) => {
      setActivePopupMemberId(memberId);
    }



    // handling adding a new member

    const [memberName, setMemberName] = useState("");
    const [memberSurname, setMemberSurname] = useState("");
    const [memberEmail, setMemberEmail] = useState("");
    const [memberTeam, setMemberTeam] = useState("");

    const handleMemberTeamChange = (e) => {

      const selectedTeam = teams.find(team => team.name === e.target.value);
      if (selectedTeam && memberDepartment !== selectedTeam?.department) {
        setMemberDepartment(selectedTeam.department);
      }

      setMemberTeam(e.target.value);
    }


    const [memberRole, setMemberRole] = useState("");
    const [memberDepartment, setMemberDepartment] = useState("All");

    const handleMemberDepartmentChange = (e) => {

      const selectedTeam = teams.find(team => team.name === memberTeam);
      if (selectedTeam && e.target.value !== selectedTeam?.department) {
        setMemberTeam("");
      }

      setMemberDepartment(e.target.value);
    }

    const [dropdownVisible, setDropdownVisible] = useState(false);
    
    const toggleDropdown = () => {
      setDropdownVisible(!dropdownVisible);
    };

    const [addMemberPopupOpen, setAddMemberPopupOpen] = useState(false);

    const closeAddMemberPopup = () => {
      setAddMemberPopupOpen(false);
      setMemberName("");
      setMemberSurname("");
      setMemberEmail("");
      setMemberTeam("");
      setMemberRole("");
      setMemberDepartment("All");
      closeAllPopups(true);
    }

    // handling adding a new group

    const [teamName, setTeamName] = useState("");
    const [newTeamMembers, setNewTeamMembers] = useState([]);
    const [teamDepartment, setTeamDepartment] = useState("All");

    const [addGroupPopupOpen, setAddGroupPopupOpen] = useState(false);

    const closeAddGroupPopup = () => {
      setTeamName("");
      setTeamDepartment("");
      setNewTeamMembers([]);
      closeAllPopups(true);
    }

    // handling roles
    const roles = ["Super Admin", "Admin", "Recruiting Lead", "Recruiter", "Hiring Manager", "Interviewer"];

    // handling departments
    const departments = ["All", "Software Development", "Data Science", "Product Management", "Marketing", "Finance", "Sales", "Engineering", "Design", "Business", "Consulting", "Other"];

    // handling the role info popup
    const [roleInfoPopupOpen, setRoleInfoPopupOpen] = useState(false);    
    const [selectedRole, setSelectedRole] = useState("");


    const closeAllPopups = (called = false) => {
      if (!called) {
        closeAddMemberPopup();
        closeAddGroupPopup();
      } else {
        setAddMemberPopupOpen(false);
        setAddGroupPopupOpen(false);
      }
      setRoleInfoPopupOpen(false);
      setDropdownVisible(false);
    }

    // handling team sorting

    const [sortBy, setSortBy] = useState("role");

    const handleSortChange = (e) => {
      setSortBy(e.target.value);
    }

    // handling team filtering

    const [roleFilter, setRoleFilter] = useState([]);
    const [departmentFilter, setDepartmentFilter] = useState([]);

    const [departmentsSearchTerm, setDepartmentsSearchTerm] = useState("");

    const toggleRoleFilter = (role) => {
      setRoleFilter((prev) =>
        prev.includes(role) ? prev.filter((r) => r !== role) : [...prev, role]
      );
    };
    
    const toggleDepartmentFilter = (department) => {
      setDepartmentFilter((prev) =>
        prev.includes(department)
          ? prev.filter((d) => d !== department)
          : [...prev, department]
      );
    };

    // handling team member search

    const [searchTerm, setSearchTerm] = useState("")

    const filteredTeamMembers = teamMembers
    .filter((member) => {
      if (roleFilter.length > 0 && !roleFilter.includes(member.role)) {
        return false;
      }
      // Filter by Department (if departments are selected)
      if (departmentFilter.length > 0 && member.department !== "All" && !departmentFilter.includes(member.department)) {
        return false;
      }
      // Search Term Filtering
      if (searchTerm) {
        const searchTermLower = searchTerm.toLowerCase();
        return (
          member.first_name.toLowerCase().includes(searchTermLower) ||
          member.last_name.toLowerCase().includes(searchTermLower) ||
          member.email.toLowerCase().includes(searchTermLower)
        );
      }
      return true;
    })
    .sort((a, b) => {
      if (sortBy === "name") {
        const aFullName = `${a.first_name || ''} ${a.last_name || ''}`.trim().toLowerCase();
        const bFullName = `${b.first_name || ''} ${b.last_name || ''}`.trim().toLowerCase();
        return aFullName.localeCompare(bFullName);
      } else if (sortBy === "role") {
        const aRoleIndex = roles.indexOf(a.role);
        const bRoleIndex = roles.indexOf(b.role);


        if (aRoleIndex === -1) return 1;
        if (bRoleIndex === -1) return -1;
        return aRoleIndex - bRoleIndex;

      } else if (sortBy === "department") {
        const aDepartmentIndex = departments.indexOf(a.department);
        const bDepartmentIndex = departments.indexOf(b.department);

        if (aDepartmentIndex === -1) return 1;
        if (bDepartmentIndex === -1) return -1;
        return aDepartmentIndex - bDepartmentIndex;
      }
      return 0; // Default case if sortBy doesn't match any condition
    })

    // Handling the new team member selection
    const [newTeamMember, setNewTeamMember] = useState('');
    const [suggestions, setSuggestions] = useState([]);

    const getSuggestions = (value) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        return inputLength === 0 ? [] : teamMembers.filter(member =>
          `${member.first_name} ${member.last_name}`.toLowerCase().includes(inputValue)
        );
    };

    const getSuggestionValue = (suggestion) => `${suggestion.first_name} ${suggestion.last_name}`;

    const renderSuggestion = (suggestion) => (
        <div>
          {`${suggestion.first_name} ${suggestion.last_name}`}
        </div>
    );

    const onSuggestionsFetchRequested = ({ value }) => {
        setSuggestions(getSuggestions(value));
    };

    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    const onSuggestionSelected = (event, { suggestion }) => {
        handleAddTeamMember(suggestion);
    };

    const handleAddTeamMember = (member) => {
        if (!newTeamMembers.some(m => m.id === member.id)) {
          setNewTeamMembers([...newTeamMembers, member]);
        }
        setNewTeamMember('');
    };

    const handleDeleteTeamMember = (index) => {
        const updatedTeamMembers = newTeamMembers.filter((_, i) => i !== index);
        setNewTeamMembers(updatedTeamMembers);
    };

    const inputProps = {
        className: "interviewers_input_area",
        placeholder: "Add team members!",
        value: newTeamMember,
        onChange: (e, { newValue }) => setNewTeamMember(newValue)
    };


    // handling team openness

    const [openTeams, setOpenTeams] = useState([]);

    const toggleTeamOpen = (teamName) => {
      setOpenTeams((prev) =>
        prev.includes(teamName)
          ? prev.filter((t) => t !== teamName)
          : [...prev, teamName]
      );
    };

    const [teamSection, setTeamSection] = useState("members");



    // handling filtering the groups

    const filteredTeams = teams
    .filter(team => {
      if (searchTerm) {
        const searchTermLower = searchTerm.toLowerCase();
        return (
          team.name.toLowerCase().includes(searchTermLower)
        );
      }

      if (departmentFilter.length > 0 && team.department !== "All" && !departmentFilter.includes(team.department)) {
        return false;
      }

      return true;
    })
    .sort((a, b) => {
      if (sortBy === "name") {
        const aFullName = `${a.name || ''}`.trim().toLowerCase();
        const bFullName = `${b.name || ''}`.trim().toLowerCase();
        return aFullName.localeCompare(bFullName);
      } else if (sortBy === "role") {
        const aRoleIndex = roles.indexOf(a.role);
        const bRoleIndex = roles.indexOf(b.role);


        if (aRoleIndex === -1) return 1;
        if (bRoleIndex === -1) return -1;
        return aRoleIndex - bRoleIndex;

      } else if (sortBy === "department") {
        const aDepartmentIndex = departments.indexOf(a.department);
        const bDepartmentIndex = departments.indexOf(b.department);

        if (aDepartmentIndex === -1) return 1;
        if (bDepartmentIndex === -1) return -1;
        return aDepartmentIndex - bDepartmentIndex;
      }
      return 0; // Default case if sortBy doesn't match any condition
    })

    console.log("FILTERED TEAMS", filteredTeams);
    console.log("MEMBERS", teamMembers);
    console.log("FILTERED MEMBERS", filteredTeamMembers);

    return (

      <>
        <div className="team_holder">

            <div className="team_page_header">
                <h1>
                    Team
                </h1>
            </div>

            <div className='team_actions_holder'>

              <div className="add_member_container" onClick={(e) => {
                  setDropdownVisible(false);
                  if (!permissions.team.add) {
                    sendNotification("You do not have permission to add members to the team");
                    return;
                  }
                  setAddMemberPopupOpen(true);
                }}>
                  <div className={`add_member_btn ${dropdownVisible ? 'open' : ''}`}>
            
                    <div style={{display:"flex", alignItems:"center", gap:"0.5rem"}}>
                      <FaIcons.FaPlus className="plus_icon" />
                      <span>Add Member</span>
                    </div>
                    
                    {dropdownVisible ? (
                      <FaIcons.FaCaretUp className="caret_icon caret_open" onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        toggleDropdown();
                      }} />
                    ) : (
                      <FaIcons.FaCaretDown className="caret_icon" onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        toggleDropdown();
                      }} />
                    )}
                  </div>
            
                  {dropdownVisible && (
                    <div className="add_team_dropdown">
                      <div className='add_team_dropdown_item' style={{zIndex: 1000}} onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        setDropdownVisible(false);
                        if (!permissions.team.add) {
                          sendNotification("You do not have permission to add members to the team");
                          return;
                        }
                        setAddGroupPopupOpen(true);
                      }}> 
                        <FaIcons.FaUsers className="team_icon" />
                        <span>Create New Team</span>
                      </div>
                    </div>
                )}
              </div>


              <div className='member_search'>

                <FaIcons.FaSearch className='team_search_icon' style={{opacity:0.5}} />
                
                <input 
                    className='team_search_input' 
                    type="text" 
                    placeholder="Search! (Eg. John Doe)" 
                    value={searchTerm} 
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              
              
                
                
            </div>

            <div className='team_members_area_holder'>

              <div className='team_members_filter_area'>
                <div className='team_members_sort team_members_filter_item'>
                  <p className='team_members_filter_label' style={{marginBottom: "0.5rem"}}>Sort</p>

                  <select className='team_members_filter_select' value={sortBy} onChange={handleSortChange}>
                    <option value="role">By Role</option>
                    <option value="name">By Name</option>
                    <option value="department">By Department</option>
                  </select>
                </div>

                <div className='team_members_and'>
                  <div className='line'></div>
                  <p className='and'>AND</p>
                  <div className='line'></div>
                </div>

                <div className='team_members_filter team_members_filter_item'>

                  <div className='team_members_filter_header'>
                    <p className='team_members_filter_label'>Filter</p>
                    <FiRefreshCcw className='team_members_filter_icon' onClick={() => {
                      setRoleFilter([]);
                      setDepartmentFilter([]);
                      setDepartmentsSearchTerm("");
                    }} />
                  </div>
                  

                  <div className='filter_by_role'>
                    <p>Filter by Role:</p>
                    <div className="filter_buttons_container">
                      {roles.map((role, idx) => (
                        <button
                          key={idx}
                          onClick={() => toggleRoleFilter(role)}
                          className={roleFilter.includes(role) ? "active" : ""}
                        >
                          {role}
                        </button>
                      ))}
                    </div>
                  </div>

                  <div className='filter_by_department'>
                    <p className='filter_by_department_label'>Filter by Department:</p>

                    <div className='filter_departments_search'>
                      <FaIcons.FaSearch className='filter_departments_search_icon' style={{opacity:0.5}} />
                      <input type="text" className='filter_departments_search_input' placeholder='Search Departments' value={departmentsSearchTerm} onChange={(e) => setDepartmentsSearchTerm(e.target.value)} />
                    </div>

                    <div className="filter_departments_container">
                      {departments.filter(department => department.toLowerCase().includes(departmentsSearchTerm.toLowerCase())).map((department, idx) => (
                        <div
                          key={idx}
                          onClick={() => toggleDepartmentFilter(department)}
                          className={`filter_department ${departmentFilter.includes(department) ? "active" : ""}`}
                        >
                          {department}
                        </div>
                      ))}
                    </div>
                  </div>

                </div>
              </div>

              <div className='team_members_full_area'>

                <div className='team_members_subnav subnav'>
                  <div className={`subnav_item ${teamSection === 'members' ? 'active_subnav_item' : ''}`} onClick={() => setTeamSection('members')}>
                      <p>Members</p>
                  </div>

                  <div className={`subnav_item ${teamSection === 'teams' ? 'active_subnav_item' : ''}`} onClick={() => setTeamSection('teams')}>
                      <p>Teams</p>
                  </div>
                </div>

                {
                  teamSection === 'members' && (
                    <div className='team_members_area'>

                    <div className='team_members_heading'>
                        
                      <div className='team_members_cell expand_cell' />

                      <div className='team_members_cell name_cell'>
                        <p>Name</p>
                      </div>
                      <div className='team_members_cell role_cell'>
                        <p>Role</p>
                      </div>
                      <div className='team_members_cell department_cell'>
                        <p>Department</p>
                      </div>
                      <div className='team_members_cell actions_cell'>
                        <p>Actions</p>
                      </div>
                    </div>

                    <div className='team_members_body'>

                      {filteredTeamMembers.map(member => (
                        <div className='team_member'>
                          <div className='team_members_cell expand_cell' />
                          <div className='team_members_cell name_cell'>
                            <p>{member.first_name} {member.last_name}</p>
                          </div>
                          <div className='team_members_cell role_cell'>
                            <p>{member.role}</p>
                          </div>
                          <div className='team_members_cell department_cell'>
                            <p>{member.department}</p>
                          </div>
                          <div className='team_members_cell actions_cell'>
                            <EditMember member={member} currentMember={currentMember} teamMembers={teamMembers} setTeamMembers={setTeamMembers} teams={teams} setTeams={setTeams} employerID={employerID} />
                            <DeleteMember member={member} currentMember={currentMember} teamMembers={teamMembers} setTeamMembers={setTeamMembers} teams={teams} setTeams={setTeams} employerID={employerID} />
                          </div>
                        </div>
                      ))}

                      {
                        filteredTeamMembers.length === 0 && (
                          <div className='no_members_message'>
                            No members found!
                          </div>
                        )
                      }

                    </div>  
                    
                    </div>
                  )
                }

                {
                  teamSection === 'teams' && (
                    <div className='team_members_area'>

                      <div className='team_members_heading'>
                          
                        <div className='team_members_cell expand_cell' />

                        <div className='team_members_cell name_cell'>
                          <p>Name</p>
                        </div>
                        <div className='team_members_cell role_cell'>
                          <p>Role</p>
                        </div>
                        <div className='team_members_cell department_cell'>
                          <p>Department</p>
                        </div>
                        <div className='team_members_cell actions_cell'>
                          <p>Actions</p>
                        </div>
                      </div>

                      <div className='team_members_body'>

                        {
                          filteredTeams.map((team, index) => (
                            <div key={team.name} className='team_team'>
                              <div className='team_info' onClick={() => toggleTeamOpen(team.name)}>
                                <div className='team_members_cell expand_cell'>
                                  {openTeams.includes(team.name) ? (
                                    <FaIcons.FaChevronDown className='team_member_expand_icon' />
                                  ) : (
                                    <FaIcons.FaChevronRight className='team_member_expand_icon' />
                                  )}
                                </div>
                                <div className='team_members_cell name_cell'>
                                  <p>{team.name}</p>
                                </div>
                                <div className='team_members_cell role_cell' />
                                <div className='team_members_cell department_cell' >
                                  <p>{team.department}</p>
                                </div>
                                <div className='team_members_cell actions_cell' >
                                  <EditTeam team={team} index={index} teams={teams} employerID={employerID} setTeams={setTeams} members={teamMembers} setMembers={setTeamMembers} currentMember={currentMember} />
                                  <DeleteTeam team={team} setTeams={setTeams} teams={teams} employerID={employerID} currentMember={currentMember} index={index} members={teamMembers} setMembers={setTeamMembers} />
                                </div>
                              </div>
                              {openTeams.includes(team.name) && (
                                <ul className='team_submembers'>
                                  {team.members && team.members.length > 0 ? (
                                    team.members.map(member => (
                                      <li key={member.id} className='team_sub_member'>
                                        <p>{member.first_name} {member.last_name}</p>
                                      </li>
                                    ))
                                  ) : (
                                    <div className='team_sub_member'>
                                      <div className='team_members_cell name_cell'>
                                        <p>The team is empty!</p>
                                      </div>
                                    </div>
                                  )}
                                </ul>
                              )}
                            </div>
                          ))
                        }

                        {
                          filteredTeams.length === 0 && (
                            <div className='no_members_message'>
                              No teams found!
                            </div>
                          )
                        }

                      </div>  
                    </div>
                  )
                }
                
              </div>
             
            </div>

            
        </div>







      
        <Popup 
          open={roleInfoPopupOpen}
          onOpen={() => setRoleInfoPopupOpen(true)}
          nested
          position="center center"
        >
          <div className='popup_holder'>
            <div className='popup overlay'>
              <div className='popup_close' onClick={() => setRoleInfoPopupOpen(false)}>
                <X /> 
              </div>

              <h1 className='popup_heading'>{memberRole}</h1>
              
              <div className='role_permissions'>                
                <section>
                  <h3>Candidates</h3>
                  {memberRole === "Super Admin" && <p>Super Admins have full access to all candidate information and actions, including viewing, adding, editing, and deleting candidates, as well as managing their pipeline stages and viewing feedback.</p>}
                  {memberRole === "Admin" && <p>Admins have the same level of access to candidates as Super Admins, allowing them to manage all aspects of the candidate process.</p>}
                  {memberRole === "Recruiting Lead" && <p>Recruiting Leads can view and manage all candidates, including those unrelated to their specific projects. They have full control over candidate information and pipeline management.</p>}
                  {memberRole === "Recruiter" && <p>Recruiters can view and manage candidates related to their projects. They can add, edit, and move candidates through the pipeline, but cannot view unrelated candidates.</p>}
                  {memberRole === "Hiring Manager" && <p>Hiring Managers can view candidates related to their projects and see feedback, but cannot edit candidate information or move them through the pipeline.</p>}
                  {memberRole === "Interviewer" && <p>Interviewers can only view candidates they are scheduled to interview. They cannot edit candidate information or view feedback from other interviewers.</p>}
                </section>
                
                <section>
                  <h3>Team</h3>
                  {memberRole === "Super Admin" && <p>Super Admins have complete control over team management, including adding and removing team members, editing roles, and managing admin privileges.</p>}
                  {memberRole === "Admin" && <p>Admins can view and manage team members, but cannot add or remove Super Admins or delete other Admins.</p>}
                  {memberRole === "Recruiting Lead" && <p>Recruiting Leads can view all team members and their profiles but cannot add, edit, or remove team members.</p>}
                  {memberRole === "Recruiter" && <p>Recruiters can view all team members but cannot access detailed profiles or make any changes to the team structure.</p>}
                  {memberRole === "Hiring Manager" && <p>Hiring Managers can view the team list but cannot access detailed profiles or make any changes.</p>}
                  {memberRole === "Interviewer" && <p>Interviewers do not have access to team management features.</p>}
                </section>
                
                <section>
                  <h3>Interviews</h3>
                  {memberRole === "Super Admin" && <p>Super Admins have full access to view and manage the interview calendar and schedule interviews.</p>}
                  {memberRole === "Admin" && <p>Admins have the same interview management capabilities as Super Admins.</p>}
                  {memberRole === "Recruiting Lead" && <p>Recruiting Leads can view the calendar, see all events, and schedule interviews.</p>}
                  {memberRole === "Recruiter" && <p>Recruiters have full access to the interview calendar and can schedule interviews.</p>}
                  {memberRole === "Hiring Manager" && <p>Hiring Managers can view the calendar and interview events but cannot schedule interviews.</p>}
                  {memberRole === "Interviewer" && <p>Interviewers can view the calendar and their assigned interview events.</p>}
                </section>
                
                <section>
                  <h3>Jobs</h3>
                  {memberRole === "Super Admin" && <p>Super Admins have complete control over job postings, including creating, editing, and deleting jobs.</p>}
                  {memberRole === "Admin" && <p>Admins have the same job management capabilities as Super Admins.</p>}
                  {memberRole === "Recruiting Lead" && <p>Recruiting Leads can create, edit, and delete job postings.</p>}
                  {memberRole === "Recruiter" && <p>Recruiters can create and edit job postings but cannot delete them.</p>}
                  {memberRole === "Hiring Manager" && <p>Hiring Managers can create and edit job postings but cannot delete them.</p>}
                  {memberRole === "Interviewer" && <p>Interviewers do not have access to job management features.</p>}
                </section>
                
                <section>
                  <h3>Settings</h3>
                  {memberRole === "Super Admin" && <p>Super Admins have full access to all settings, including the ability to delete the account.</p>}
                  {memberRole === "Admin" && <p>Admins can view and modify settings but cannot delete the account.</p>}
                  {memberRole === "Recruiting Lead" && <p>Recruiting Leads do not have access to general settings.</p>}
                  {memberRole === "Recruiter" && <p>Recruiters do not have access to general settings.</p>}
                  {memberRole === "Hiring Manager" && <p>Hiring Managers do not have access to general settings.</p>}
                  {memberRole === "Interviewer" && <p>Interviewers do not have access to general settings.</p>}
                </section>
              </div>
            </div>
          </div>
        </Popup>

        <Popup
            open={addMemberPopupOpen}
            onOpen={() => setAddMemberPopupOpen(true)}
            nested
            position="center center"
          >
            <div className='popup_holder'>
              <div className='popup' style={{height: "30rem", width: "50rem"}}>

                <div className='popup_close' onClick={closeAddMemberPopup}>
                  <X />
                </div>

                <h1 className='popup_heading'>Add Member</h1>


                {
                  addedMember ? (
                    <div className='popup_body'>
                     <p>Successfully added new member!</p> 
                    </div>
                  ) : (
                    <div className='popup_body'>
                      <div className='member_information'>
                        <div className='member_name'>
                          <div className='member_item'>
                            <p className='member_item_label'>Name: </p>
                            <input type="text" className='member_input' placeholder='First Name' value={memberName} onChange={(e) => setMemberName(e.target.value)} />
                          </div>

                          <div className='member_item'>
                            <p className='member_item_label'>Surname: </p>
                            <input type="text" className='member_input' placeholder='Surname' value={memberSurname} onChange={(e) => setMemberSurname(e.target.value)} />
                          </div>
                        </div>

                        <div className='member_item member_email'>
                          <p className='member_item_label'>Email: </p>
                          <input type="text" className='member_input' placeholder='Email' value={memberEmail} onChange={(e) => setMemberEmail(e.target.value)} />
                        </div>

                        <div className='member_item'>
                          <p className='member_item_label'>Team: </p>
                          <select className='member_select member_input' style={{width: "40%"}} value={memberTeam} onChange={handleMemberTeamChange}>
                            <option value="">Select Team</option>
                            {
                              teams.map(team => (
                                <option value={team.id}>{team.name}</option>
                              ))
                            }
                          </select>
                        </div>

                      </div>

                      <div className='member_permissions'>
                        <p className='member_permissions_label'>Permissions</p>  

                        <div className='member_permissions_holder'>
                          <div className='member_permissions_item member_item' style={{flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start"}}>

                            <div style={{display: "flex", alignItems: "center", gap: "0.5rem", width: "100%", height: "2rem", marginBottom: "1rem"}}>
                              <p className='member_item_label'>Role: </p>

                              <select className='member_select member_input' value={memberRole} onChange={(e) => setMemberRole(e.target.value)}>
                                <option value="" disabled>Select Role</option>
                                {
                                  roles.map(role => (
                                    <option value={role}>{role}</option>
                                  ))
                                }
                              </select>
                            </div>


                            {
                              memberRole && (
                                <div className="role_explanation" onClick={() => setRoleInfoPopupOpen(true)}>
                                  <p className="role_explanation_exclamation">!</p>
                                  <p className="role_explanation_body">Understand this role!</p>
                                </div>
                              )
                            }
                            
                          </div>

                          <div className='member_permissions_item member_item'>
                            <p className='member_item_label'>Department: </p>

                            <select className='member_select member_input' value={memberDepartment} onChange={handleMemberDepartmentChange}>
                              {
                                departments.map(department => (
                                  <option value={department}>{department}</option>
                                ))
                              }
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }

                

                {
                  !addedMember && (
                    <div className='add_member_actions'>
                      <button className='add_member_button' onClick={addNewMember}>Add Member</button>
                    </div>
                  )
                }
              </div>
            </div>
        </Popup>


        <Popup
          open={addGroupPopupOpen}
          onOpen={() => setAddGroupPopupOpen(true)}
          nested
          position="center center"
        > 
          <div className='popup_holder'>
            <div className='popup' style={{height: "30rem", width: "50rem"}}>
              <div className='popup_close' onClick={closeAddGroupPopup}>
                <X />
              </div>

              <h1 className='popup_heading'>Create New Team</h1>

              

              {
                addedGroup ? (
                  <div className='popup_body'>
                    <p>Successfully added new group!</p>
                  </div>
                ) : (
                    <div className='popup_body' style={{display: "flex"}}>

                      <div className='team_info_holder'>
                        <div className='member_item' style={{marginBottom: "2rem"}}>
                          <p className='member_item_label'>Name: </p>
                          <input type="text" className='member_input' placeholder='Team Name' value={teamName} onChange={(e) => setTeamName(e.target.value)} />
                        </div>

                        <div className='member_item'>
                          <p className='member_item_label'>Department: </p>

                          <select className='member_select member_input' value={teamDepartment} onChange={(e) => setTeamDepartment(e.target.value)}>
                            {
                              departments.map(department => (
                                <option value={department}>{department}</option>
                              ))
                            }
                          </select>
                        </div>
                      </div>

                      <div className='team_info_holder'>
                          <p className='member_item_label' style={{margin:0, marginBottom: "1rem"}}>Members</p>

                          <div className='new_team_members_area'>
                            <Autosuggest
                              suggestions={suggestions}
                              onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                              onSuggestionsClearRequested={onSuggestionsClearRequested}
                              getSuggestionValue={getSuggestionValue}
                              renderSuggestion={renderSuggestion}
                              inputProps={inputProps}
                              onSuggestionSelected={onSuggestionSelected}
                              theme={{
                                container: 'autosuggest-container',
                                suggestionsContainer: 'suggestions-container',
                                suggestionsList: 'suggestions-list',
                                suggestion: 'suggestion-item'
                              }}
                            />
                            <div className="new_team_members_list">
                              {newTeamMembers.map((member, index) => (
                                <div key={index} className="new_team_member_item">
                                  <span>{`${member.first_name} ${member.last_name}`}</span>
                                  <FaIcons.FaTrashAlt 
                                    className="delete_team_member" 
                                    onClick={() => handleDeleteTeamMember(index)} 
                                  />
                                </div>
                              ))}

                              {
                                newTeamMembers.length === 0 && (
                                  <div className="no_members_message" style={{padding: "1rem"}}>
                                    No members added yet!
                                  </div>
                                )
                              }
                            </div>
                          </div>
                      </div>

                    
                    </div>
                )
              }

              {
                !addedGroup && (
                  <div className='add_member_actions'>
                    <button className='add_member_button' onClick={addNewGroup}>Add Group</button>
                  </div>
                )
              }
            </div>
          </div>
        </Popup>
     
      </>
    )
}



