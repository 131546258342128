import { useEffect, useState } from "react";
import { sendNotification } from "../../components/notification";
import { auth, db } from "../../firebase";
import { collection, deleteDoc, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import * as FaIcons from "react-icons/fa";
import "./styles/account.css"
import Popup from "reactjs-popup";
import { signOut } from "firebase/auth";

export const SettingsAccount = ({permissions}) => {

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');

    const [employerData, setEmployerData] = useState(null);
    const [employerID, setEmployerID] = useState(null);
    const [canRun, setCanRun] = useState(false);
    const [currentMember, setCurrentMember] = useState(null);

    console.log();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
            setCanRun(true);
            } else {
            // No user is signed in.
            setCanRun(false);
            }
        });

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, []);



    useEffect(() => {
        
        

        const getTeam = async () => {
            try {
                const user = auth.currentUser;
                if (!user) {
                throw new Error('No authenticated user found');
                }
                
                // Step 1: Get the user's member document to find their team_id
                const membersRef = collection(db, "members");
                const qMember = query(membersRef, where("email", "==", user.email));
                const memberSnapshot = await getDocs(qMember);
                if (memberSnapshot.empty) {
                throw new Error('Member not found');
                }
                
                const memberData = memberSnapshot.docs[0].data();
                setCurrentMember(memberData);
                const { team_id } = memberData;
                setEmployerID(team_id);
                // Step 2: Use team_id to find the team and retrieve the members array
                const teamSnapshot = await getDoc(doc(db, "teams", team_id));
                if (!teamSnapshot.exists()) {
                    throw new Error('Team not found');
                }
                
                const teamData = teamSnapshot.data();
                setEmployerData(teamData);
                
            } catch (error) {
                console.error("Failed to fetch user data", error);
            }
        };

        getTeam();
    }, [canRun]);

    useEffect(() => {
        if (employerData) {
            setName(employerData.name);
            setDescription(employerData.description || '');
        }
    }, [employerData]);

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    };

    
    const [deletingAccount, setDeletingAccount] = useState(false);
    const [deletingCandidates, setDeletingCandidates] = useState(false);
    const [deletingJobs, setDeletingJobs] = useState(false);
    const [deletingTeamMembers, setDeletingTeamMembers] = useState(false);
    const [goodbyeMessage, setGoodbyeMessage] = useState(false);

    const handleDeleteAccount = async () => {
        // Handle delete logic here
        if (permissions.settings.delete_account === false) {
            sendNotification("You do not have permission to delete the account!");
            return;
        }


        setDeletingAccount(true);

        // get all jobs for the employer and then for each

            // delete each candidate from the job

            // delete the job itself

        // also all candidates associated with the employer seperately from jobs

        // delete each team member associated with the employer

        // delete the employer team

        setDeletingCandidates(true);

        const jobsQuery = query(collection(db, 'jobs'), where('employer_id', '==', employerID));
        const jobsSnapshot = await getDocs(jobsQuery);
        const jobs = jobsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        // Start fetching candidates linked to each job
        const candidateJobPromises = jobs.map(job => {
            const candidatesRef = collection(db, 'candidates');
            const candidatesQuery = query(candidatesRef, where('job_id', '==', job.id));
            return getDocs(candidatesQuery);
        });

        // Start fetching candidates directly linked to the employer
        const candidatesRef = collection(db, 'candidates');
        const candidatesEmployerQuery = query(candidatesRef, where('employer_id', '==', employerID));
        const candidatesEmployerPromise = getDocs(candidatesEmployerQuery);

        // Wait for all candidate fetches to complete
        const [candidatesJobSnapshots, candidatesEmployerSnapshot] = await Promise.all([
            Promise.all(candidateJobPromises),
            candidatesEmployerPromise
        ]);

        // Combine results from job-related candidate queries
        let allCandidates = candidatesJobSnapshots.flatMap(snapshot => 
            snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id, checked: false }))
        );

        // Combine results from employer-related candidate query
        const candidatesForEmployer = candidatesEmployerSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id, checked: false }));

        allCandidates = allCandidates.concat(candidatesForEmployer);

        
        // Delete all candidates

        const deleteRequests = allCandidates.map(async (candidate) => {
            try {
              const response = fetch('https://api.skillpool.tech/delete_candidate', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({ candidateId: candidate.id }),
              });
      
              if (!response.ok) {
                console.error(`Network Error: ${response.status}`);
                return;
              }      
            } catch (error) {
              console.error("Error:", candidate.id, error);
            }
        });
      
          // Wait for all delete requests to settle
        const results = await Promise.allSettled(deleteRequests); 

        // Delete all jobs

        setDeletingJobs(true);

        const jobDeleteRequests = jobs.map(async (job) => {
            try {
                const jobRef = doc(db, 'jobs', job.id);
                await deleteDoc(jobRef);
            } catch (error) {
                console.error("Error during the job deletion:", error);
            }
        })

        const jobResults = await Promise.allSettled(jobDeleteRequests);

        // Delete all team members

        setDeletingTeamMembers(true);

        const teamMembersRef = collection(db, 'members');

        const teamMembersQuery = query(teamMembersRef, where('team_id', '==', employerID));

        const teamMembersSnapshot = await getDocs(teamMembersQuery);

        const teamMembers = teamMembersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        const teamMemberDeleteRequests = teamMembers.map(async (member) => {
            try {
                const memberRef = doc(db, 'members', member.id);
                await deleteDoc(memberRef);
            } catch (error) {
                console.error("Error during the team member deletion:", error);
            }
        })

        const teamMemberResults = await Promise.allSettled(teamMemberDeleteRequests);

        // Delete the employer team

        const employerRef = doc(db, 'teams', employerID);

        await deleteDoc(employerRef);

        setGoodbyeMessage(true);

        setTimeout(() => {
            setGoodbyeMessage(false);
            signOut(auth);
        }, 2000)



        

    };

    const handleLogOut = () => {
        // Handle logout logic here
        signOut(auth);
    };



    // handle the confirmation popups

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showLogOutConfirmation, setShowLogOutConfirmation] = useState(false);

    const [deleteConfirmation, setDeleteConfirmation] = useState('');

    const cancelDeleteConfirmation = () => {
        setShowDeleteConfirmation(false);
        setDeleteConfirmation('');
    }


    // handle saving the changes

    const [showSavedMessage, setShowSavedMessage] = useState(false);

    const handleSaveChanges = () => {
        
        if (permissions.settings.edit_details === false) {
            sendNotification("You do not have permission to edit the company details!");
            return;
        }

        setShowSavedMessage(true);

        setTimeout(() => {
            setShowSavedMessage(false);
        }, 1000)

        // Save changes to Firestore

        const employerRef = doc(db, "teams", employerID);

        const employerData = {
            name: name,
            description: description
        }

        updateDoc(employerRef, employerData);
    };

    return (
        <div className="settings_account">

            <div className="settings_account_category settings_company_information">
                <p className="settings_account_category_heading">Company Information</p>

                <div className="company_information_item" style={{alignItems:"center", height: "20%"}}>
                    <p className="company_information_item_heading">Name:</p>
                    <input className="company_information_item_input" type="text" value={name} onChange={handleNameChange} placeholder="Please enter the company name here!" />
                </div>

                <div className="company_information_item" style={{height:"80%"}}>
                    <p className="company_information_item_heading">Description:</p>
                    <textarea className="company_information_item_textarea" value={description} onChange={handleDescriptionChange} placeholder="Please enter a company description here!" />
                </div>

            </div>

            <div className="settings_account_category settings_account_actions">
                <p className="settings_account_category_heading">Account Actions</p>

                <div className="settings_account_actions_holder">
                    <Popup
                        trigger={
                            <div className="settings_account_action">
                                Log out
                            </div>
                        }
                        open={showLogOutConfirmation}
                        onOpen={() => setShowLogOutConfirmation(true)}
                        modal
                        nested
                        position="center center"
                    >
                        <div className="popup_holder">
                            <div className="popup" style={{width: "35%", height:"30%"}}>

                                <h1 className="popup_heading">Log Out</h1>

                                <div className="popup_body">
                                    <p>Are you sure you want to log out?</p>
                                </div>
                                
                                <div className="company_action_confirmation_holder">
                                    <button className="confirmation_action" onClick={() => setShowLogOutConfirmation(false)}>Cancel</button>
                                    <button className="confirmation_action" onClick={handleLogOut}>Log Out</button>
                                </div>
                            </div>
                        </div>
                        
                    </Popup>

                    <Popup
                        trigger={
                            <div className="settings_account_action">
                                Delete Account
                            </div>
                        }
                        open={showDeleteConfirmation}
                        onOpen={() => setShowDeleteConfirmation(true)}
                        modal
                        nested
                        position="center center"
                    >
                        <div className="popup_holder">
                            <div className="popup" style={{width: "50%", height:"50%"}}>
                            
                                <h1 className="popup_heading" style={{margin: 0, marginBottom:"1rem"}}>Delete Account</h1>


                                {
                                    !deletingAccount && (
                                        <>
                                            <div className="popup_body">
                                                <p>Are you sure you want to delete this account? </p>
                                                <p>This will delete all your jobs and stored candidates permanently!</p>
                                                <p style={{margin:"2rem 0rem"}}><span style={{fontWeight:"500"}}>NOTE: This action cannot be undone!</span></p>

                                                <p>To confirm, please type DELETE in the box below</p>

                                                <input className="delete_confirmation_box" type="text" placeholder="DELETE" value={deleteConfirmation} onChange={(e) => setDeleteConfirmation(e.target.value)} />
                                            </div>

                            
                                            <div className="company_action_confirmation_holder">
                                                <button className="confirmation_action" onClick={cancelDeleteConfirmation}>Cancel</button>
                                                <button 
                                                    disabled={deleteConfirmation !== "DELETE"}
                                                    className="confirmation_action" 
                                                    style={{
                                                        background: deleteConfirmation === "DELETE" ? "var(--secondary)" : "none",                                        
                                                    }}
                                                    onClick={handleDeleteAccount}
                                                >
                                                    Delete</button>
                                            </div>
                                        </>
                                    )
                                }

                                {
                                    deletingAccount && (
                                        <div className="popup_body deletion_acknowledgment">
                                            <p>Deleting account...</p>

                                            {
                                                deletingCandidates && <p>Deleting candidates...</p>
                                            }

                                            {
                                                deletingJobs && <p>Deleting jobs...</p>
                                            }

                                            {
                                                deletingTeamMembers && <p>Deleting team members...</p>
                                            }

                                            {
                                                goodbyeMessage && <p>We're sorry to see you go :(</p>
                                            }
                                        </div>
                                    )
                                }
                                
                            </div>
                        </div>
                        
                    </Popup>
                    

                    
                </div>
                
            </div>
            
            <div className="save_changes_holder">

                {
                    showSavedMessage && <div className="saved_message">
                        <FaIcons.FaCheck />
                        <p>Saved!</p>
                    </div>
                }

                {
                    !showSavedMessage && <div className="save_changes_button" onClick={handleSaveChanges}>
                        <FaIcons.FaSave />
                        <p>Save</p>
                    </div>
                }
               
            </div>
        </div>
    );
}