import ReactQuill from "react-quill";
import { Placeholder, placeholders } from "../../../components/candidateEmailHistory";
import { useState } from "react";
import { diffChars } from "diff";
import { globalPermissions } from "../../../routing";
import { sendNotification } from "../../../components/notification";


export const EmailTemplatePopup = ({subject, setSubject, body, setBody, name, setPopupOpen}) => {


    const [currentSubject, setCurrentSubject] = useState(subject);
    const [currentBody, setCurrentBody] = useState(body);

    

    const handleEmailBodyChange = (content, delta, source, editor) => {

        // get the difference in content by comparing it with the current body''

        const findHTML = (text) => {
            // find a string that starts with <p>&lt;a and ends with /a&gt;</p>.

            // get the index of that string and then get the string itself

            const link = text.match(/&lt;a.*?\/a&gt;/g);

            if (link) {
                const index = text.indexOf(link[0]);
                const linkText = text.substring(index, index + link[0].length);
                return {enteredHTMLLink: linkText, indexOfHTML: index};
            }
        }

        try {
            const {enteredHTMLLink, indexOfHTML} = findHTML(content);
            const length = enteredHTMLLink.length;
            const whichLink = enteredHTMLLink.match(/&gt;.*?&lt;/g);
            const linkText = whichLink[0].substring(4, whichLink[0].length - 4);

            const newContent = content.substring(0, indexOfHTML) + `<a href={{link}}>${linkText}</a>` + content.substring(indexOfHTML + length, content.length);
            setCurrentBody(newContent);

            // if ((linkText === "Schedule Interview") || (linkText === "Availability Link") || (linkText === "Booking Link") || (linkText === "Meeting Link")) {
            //     // replace the link with the schedule interview link
            //     const newContent = content.substring(0, indexOfHTML) + `<a href={{link}}>${linkText}</a>` + content.substring(indexOfHTML + length, content.length);
            //     setCurrentBody(newContent);
            // }

            // if (linkText === "Take Interview") {
            //     // replace with the take interview link
            //     const newContent = content.substring(0, indexOfHTML) + `<a href={{link}}>${linkText}</a>` + content.substring(indexOfHTML + length, content.length);
            //     setCurrentBody(newContent);
            // }
            
        } catch {
            // no html linl
            setCurrentBody(content); // or content for just the HTML content
        }
        
    };

    console.log(currentSubject);
    console.log(currentBody)

    const cancelChanges = () => {
        setCurrentBody(body);
        setCurrentSubject(subject);
        setPopupOpen(false);
    }


    const saveChanges = () => {

        if (globalPermissions.settings.change_templates === false) {
            sendNotification("You do not have permission to edit email templates");
            return;
        }

        setSubject(currentSubject);
        setBody(currentBody);
        setPopupOpen(false);
    }

    const PlaceholderLink = ({ text }) => {
        const [copied, setCopied] = useState(false);
    
        const copyText = async () => {
            try {
                
                await navigator.clipboard.writeText(`<a href={{link}}>${text}</a>`);
                setCopied(true);
                setTimeout(() => setCopied(false), 1000);
            } catch (err) {
                console.error('Failed to copy: ', err);
            }
        };
    
        return (
            <div className="placeholder" onClick={copyText}>
                {copied ? 'Copied!' : <span style={{color: "blue", textDecoration:"underline"}}>{text}</span>}
            </div>
        );
    }

    const handlePaste = (event) => {

        const clipboardData = event.clipboardData || window.clipboardData;
        const pastedData = clipboardData.getData('Text');

        // Try to detect if pasted data is HTML
        if (pastedData.startsWith('<a') && pastedData.endsWith('</a>')) {
            event.preventDefault();
            const quill = this.quillRef.current.getEditor();
            const range = quill.getSelection();
            if (range) {
                quill.clipboard.dangerouslyPasteHTML(range.index, pastedData);
            }
        }
    };
    

    return (
        <div className="popup_holder">
            <div className="popup">
                <h1 className="popup_heading">
                    {name}
                </h1>

                <div className="candidate_send_email_holder">
                    <div className="candidate_send_email_details">

                        <h3>Subject: </h3>
                        <input type="text" placeholder="Subject" value={currentSubject} onChange={(e) => setCurrentSubject(e.target.value)} className="send_email_subject" />

                        <h3>Body: </h3>
                        <ReactQuill theme="snow" value={currentBody} onChange={handleEmailBodyChange} onPaste={handlePaste} placeholder="Please enter the body here..." className="send_email_body"/>
                    </div>

                    <div className="candidate_send_email_placeholders">
                        <h3>Usable Placeholders</h3>
                        <div className="placeholders_list">
                            {placeholders.map((text, index) => (
                                <Placeholder key={index} text={text} />
                            ))}

                            

                            {( name === "Application Thanks + Screening Interview") &&
                              <PlaceholderLink text={"Take Interview"} />
                            }

                            {(name === "Manual Interview Invite") &&
                              <PlaceholderLink text={"Schedule Interview"} />
                            }

                            {(name === "Reminder Email") &&
                                <>
                                    <Placeholder text={"{{time}}"} />
                                    <PlaceholderLink text={"Meeting Link"} />
                                </>
                            }

                            {(name === "Candidate Availability Email") &&
                              <PlaceholderLink text={"Give Availability"} />
                            }

                            {(name === "Candidate Booking Email") &&
                              <PlaceholderLink text={"Schedule Interview"} />
                            }

                        </div>
                    </div>
                </div>

                <div className="popup_bottom_actions">
                    <div className="popup_cancel" onClick={cancelChanges}>
                        Cancel
                    </div>

                    <div className="popup_action" onClick={saveChanges}>
                        Save
                    </div>
                </div>

            </div>
        </div>
    )
}