import "./styles/job.css"
import "./styles/candidates.css"
import "./styles/pipeline.css"
import * as FaIcons from "react-icons/fa"
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Link, Routes, Outlet, useNavigate } from 'react-router-dom';
import { collection, deleteDoc, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { db } from "../firebase";
import Popup from "reactjs-popup";
import { X } from "lucide-react";
import { CandidateEligibility } from "./job_subPages/candidateUploadingComponents/candidateEligibility";
import { CandidateSourcing } from "./job_subPages/candidateUploadingComponents/candidateSourcing";
import { EditJob } from "./job_subPages/editJob";
import { sendNotification } from "../components/notification";

export const stagesNames = {
  applied: 'Applied', //the reason this is rejected is cus the candidate was unable to pass the pre-screening requirements.
  prescreening: 'Prescreening',
  skillscore: 'SkillScore',
  interview: 'Interview',
  made_offer: 'Made Offer',
  hired: 'Hired',
}


export var lastPage = "";

export var setLastPage = (page) => {
  if (page != "dashboard") {
    lastPage = page;
  } else{
    lastPage = "";
  }
}


export var globalCandidates = [];

export const setGlobalCandidates = (candidates) => {
  globalCandidates = candidates;
}



const SubNavigation = () => {


    const parts = window.location.href.split("/");

    var curr_active = parts[parts.length - 1];

  if ((curr_active !== "pipeline") && (curr_active !== "candidates") && (curr_active !== "settings") && (curr_active !== "sourcing")) {
      curr_active = "dashboard";
  }

    const [activeItem, setActiveItem] = useState(curr_active);

    const changeActive = (itemName) => {
    setActiveItem(itemName);
    lastPage = itemName;
  };

  return (
    <div className="job_subnav">

    <div className={`job_subnav_item ${activeItem === 'dashboard' ? 'active_job_subnav_item' : ''}`} onClick={() => changeActive('dashboard')}>
      <Link className="subnav_link" to="">Dashboard</Link>
    </div>


    <div className={`job_subnav_item ${activeItem === 'pipeline' ? 'active_job_subnav_item' : ''}`} onClick={() => changeActive('pipeline')}>
      <Link className="subnav_link" to="pipeline">Pipeline</Link>
    </div>

    <div className={`job_subnav_item ${activeItem === 'candidates' ? 'active_job_subnav_item' : ''}`} onClick={() => changeActive('candidates')}>
      <Link className="subnav_link" to="candidates">Candidates</Link>
    </div>

    <div className={`job_subnav_item ${activeItem === 'sourcing' ? 'active_job_subnav_item' : ''}`} onClick={() => changeActive('sourcing')}>
      <Link className="subnav_link" to="sourcing">Sourcing</Link>
    </div>

  </div>
  );
};

export default SubNavigation;





export const Job = ({permissions}) => {

    //Create a check here to return NotFound if the job id doesn't exist.
    const [job, setJob] = useState(null);
    const [employer, setEmployer] = useState(null);

    var jobId = window.location.href.split('/')[4]

    const [jobID, setJobId] = useState(jobId);

    let navigate = useNavigate();

    useEffect(() => {

        const getJob = async () => {
            try {

                const docRef = doc(db, 'jobs', jobId); // Create a reference to the candidate document
                const docSnap = await getDoc(docRef); // Get the document snapshot
                if (docSnap.exists()) {
                    setJob({
                      ...docSnap.data(),
                      id: docSnap.id
                    }); // Set the candidate state with the data from Firestore
                }

                const employerId = docSnap.data().employer_id;
                const employerRef = doc(db, 'teams', employerId); // Create a reference to the employer document
                const employerSnap = await getDoc(employerRef); // Get the document snapshot
                if (employerSnap.exists()) {
                    setEmployer(employerSnap.data()); // Set the employer state with the data from Firestore
                }

            } catch (error) {
                console.error("Error fetching job:", error);
            }
        };



        if (jobId) {
            getJob();
        }

    }, []);

  const [jobActionsPopupOpen, setJobActionsPopupOpen] = useState(false);

  const [deleteJobPopupOpen, setDeleteJobPopupOpen] = useState(false);

  const setAllPopupsClosed = () => {
    setJobActionsPopupOpen(false);
    setDeleteJobPopupOpen(false);
  }


  const delJobCandidates = async (jobId) => {
      // Delete the job first
      const jobRef = doc(db, 'jobs', jobID);
      await deleteDoc(jobRef);

      // Query to find all candidates with the specified jobId
      const candidatesQuery = query(collection(db, 'candidates'), where('job_id', '==', jobID));
      const snapshot = await getDocs(candidatesQuery);

      const candidatesToDelete = snapshot.docs.map(doc => ({
        id: doc.id, // Document ID
        ...doc.data() // Document data
      }));

      // Delete each candidate
      candidatesToDelete.map((candidate) => {
        try {
          const response = fetch('https://api.skillpool.tech/delete_candidate', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ candidateId: candidate.id }),
          });

          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

        } catch (error) {
          console.error("Error deleting candidate ID:", candidate.id, error);
        }
      });


    navigate('../jobs');
  };

  const delJob = async () => {
    try {
      // Delete the job first
      const jobRef = doc(db, 'jobs', jobID);
      await updateDoc(jobRef, {deleted: true});

    } catch (error) {
      console.error("Error during the job deletion:", error);
    }


    navigate('../jobs');
  }

  // handlign the logic to upload candidates

  const [addCandidatePopupIsOpen, setAddCandidatePopupOpen] = useState(false);

    const [uploadingFiles, setUploadingFiles] = useState(false);

    const [files, setFiles] = useState([]); // This will store file objects with { name, id }

    const handleFileChange = (event) => {
        const newFiles = Array.from(event.target.files);
        newFiles.forEach(file => {
            uploadFile(file);
        });
    };

    const uploadFile = (file) => {

        setUploadingFiles(true);
        console.log("reached here");
        const formData = new FormData();
        formData.append('file', file);
        fetch('https://api.skillpool.tech/apply/upload', {
            method: 'POST',
            body: formData,
        })
        .then(response => response.json())
        .then(data => {
            setUploadingFiles(false);
            if (data && data.id) {
                
                setFiles(prevFiles => [...prevFiles, { name: file.name, id: data.id }]);
            }
        })
        .catch(error => console.error('Error uploading file', error));
    };

    const handleRemoveFile = (fileId, fileName) => {
        fetch('https://api.skillpool.tech/apply/delete', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ fileName: fileId })
        })
        .then(response => response.json())
        .then(data => {
            setFiles(prevFiles => prevFiles.filter(file => file.id !== fileId));
        })
        .catch(error => console.error('Error removing file', error));
    };

    const handleUploadAll = () => {

        fetch('https://api.skillpool.tech/upload_candidates', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                files: files.map(file => file.id),
                job_id: jobId,
                locationResponse: locationTrue,
                workAuthorization: workAuthorizationTrue,
                sponsorshipNeeded: sponsorshipNeeded,
                source: candidateSource,
            })
        })
        .catch(error => console.error('Error uploading file', error));

        setLocationTrue(true);
        setWorkAuthorizationTrue(true);
        setSponsorshipNeeded(false);
        setFiles([]);

        setSubmitted(true);


        setTimeout(() => {
            setAddCandidatePopupOpen(false);
            setSubmitted(false);
            setReloadPage(true);
        }, 1000);

    };


    const [reloadPage, setReloadPage] = useState(false);

    // Logic to display confirmation screen

    const [submitted, setSubmitted] = useState(false);


    const cancelAddCandidates = () => {
        setLocationTrue(true);
        setWorkAuthorizationTrue(true);
        setSponsorshipNeeded(false);
        setFiles([]);
        setAddCandidatePopupOpen(false)
    }



    // Handling settings for adding new candidates

    const [locationTrue, setLocationTrue] = useState(true);
    const [workAuthorizationTrue, setWorkAuthorizationTrue] = useState(true);
    const [sponsorshipNeeded, setSponsorshipNeeded] = useState(false);

    // handling the logic for candidate sourcing
    const [candidateSource, setCandidateSource] = useState({
        name: "Uploaded",
        category:"Other",
    });

  return (
      <div className = "job_holder">
          <div className = "job_info">
              <h1>
                  {job?.jobTitle}
              </h1>

              <div className="job_all_actions">

                <div className="job_add_candidates_button" onClick={() => {
                  if (!permissions.candidates.add) {
                    sendNotification("You do not have permission to add candidates!");
                    return;
                  }
                  setAddCandidatePopupOpen(true);
                }}>
                  <FaIcons.FaPlus style={{marginRight:"0.5rem"}} />
                  <p>Add Candidates</p>
                </div>

                <Popup
                    open={addCandidatePopupIsOpen}
                    onOpen={() => setAddCandidatePopupOpen(true)}
                    modal
                    nested
                    position="center center"
                >
                    <div className="popup_holder">
                        <div className="popup">
                            <h1 className="popup_heading">Add Candidates</h1>

                            { !submitted && (
                            <>
                                <div className="add_candidates_body">

                                <div className="add_candidates_file_input">
                                    <div className="file-input-container">
                                        <label className="label-file-input">
                                            Add Resumes
                                            <input type="file" className="file-input" multiple onChange={handleFileChange}
                                                // style={{
                                                //     display:"none"
                                                // }}
                                            />
                                        </label>
                                    </div>


                                    <div className="file_list_area">

                                        {
                                            uploadingFiles && (
                                                <p>Uploading files...</p>
                                            )
                                        }
                                        <ul className="file-list">
                                            {files.map((file, index) => (
                                                <li key={index}>
                                                    {file.name}
                                                    <button className="button button-remove" onClick={() => handleRemoveFile(file.id, file.name)}>
                                                        <FaIcons.FaTrash
                                                            style={{
                                                                fontSize: ".8rem"
                                                            }}
                                                        />
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>

                                </div>

                                <div className="add_candidates_details">

                                    <h2 className="add_candidate_details_heading">Details</h2>

                                    <CandidateEligibility 
                                        job={job}
                                        locationTrue={locationTrue}
                                        setLocationTrue={setLocationTrue}
                                        workAuthorizationTrue={workAuthorizationTrue}
                                        setWorkAuthorizationTrue={setWorkAuthorizationTrue}
                                        sponsorshipNeeded={sponsorshipNeeded}
                                        setSponsorshipNeeded={setSponsorshipNeeded}
                                    />

                                    {
                                        job && (
                                            <CandidateSourcing 
                                                employer={employer}
                                                employerID={job.employer_id}
                                                candidateSource={candidateSource}
                                                setCandidateSource={setCandidateSource}
                                            />
                                        )
                                    }

                                    

                                </div>

                                </div>

                                <div className="popup_bottom_actions">
                                    <div className="popup_cancel" onClick={cancelAddCandidates}>
                                        Cancel
                                    </div>

                                    <div className="popup_action" onClick={handleUploadAll}>
                                        Add
                                    </div>
                                </div>
                            </>
                            )}


                            {submitted && (
                                <>
                                <p>The candidates have been submitted, they will be processed now!</p>
                                <p>Please refresh the page to see the uploaded candidates in the candidates list!</p>
                                </>
                            )}


                        </div>
                    </div>

                </Popup>

                <Popup trigger={
                  <div className = "job_end_actions">
                    <a className="sidebarIcon" > <FaIcons.FaBars /> </a>
                  </div>
                }

                open={jobActionsPopupOpen}
                onOpen={() => setJobActionsPopupOpen(true)}
                // modal
                contentStyle={{ padding: '0px', border: 'none' }}
                nested
                position="left top"
                >
                  <div className="job_actions_holder">

                    <a className="job_action" target="_blank" href={"https://jobs.skillpool.tech/" + job?.employer_id + "/job/" + jobID}>
                      View Job
                    </a>

                    <EditJob 
                      job={job}
                      setJob={setJob}
                      jobID={jobID}
                      employer={employer}
                      setAllPopupsClosed={setAllPopupsClosed}
                    />

                    <div className="job_action" onClick={() => {
                      if (!permissions.jobs.delete) {
                        sendNotification("You do not have permission to delete jobs!");
                        setJobActionsPopupOpen(false);
                        return;
                      }
                      setDeleteJobPopupOpen(true);
                    }}>
                      Delete Job
                    </div>
                    <Popup
                    open={deleteJobPopupOpen}
                    onOpen={() => setDeleteJobPopupOpen(true)}
                    modal
                    nested
                    position="center center"
                    >
                      <div className="popup_holder">
                        <div className="popup" style={{width: "30rem", height:"20rem"}}>
                          <h1 className="popup_heading" style={{margin: 0, marginBottom:"1rem"}}>Delete Job</h1>
                          <div className="popup_close" onClick={setAllPopupsClosed}>
                              <X />
                          </div>
                          <p style={{marginBottom:"2rem"}}>Please choose how you want to delete this job!</p>

                          <div className="delete_job_type_holder">
                            <div className="delete_job_type" onClick={delJobCandidates}>
                              Delete job and all associated candidates
                            </div>
                          </div>

                          <div className="delete_job_type_holder">
                            <div className="delete_job_type" onClick={delJob}>
                              Delete job and store the candidates for later
                            </div>
                          </div>

                        </div>
                      </div>
                    </Popup>

                  </div>
                </Popup>

              </div>

              


          </div>

          <div className="pageSelector">
              <SubNavigation />
          </div>


          <Outlet />

      </div>
  );
};

/*
<p>
                The aspects within this page should be:
                1. Preview job posting
                2. Edit job posting
                3. Change required skills
                4. View incoming candidates
                <br /><br /><br /><br />

                Note: I do not want to allow them to upload their own candidates (or if they do, then provide the email to send them an AI interview). Should the interview be a choice for the employer?
            </p>
*/
