import logo from './logo.svg';
import './App.css';
import React, { useEffect, useState } from "react";
import { Routing } from './routing';
import Sidebar from './components/Sidebar';
import { Router, useNavigate } from 'react-router-dom';
import { Login } from './pages/auth_pages/LoginPage';
import { RoutingNoAuth } from './routingNoAuth';
import { auth } from './firebase';

export var userLoggedIn = false;

if (userLoggedIn == null) {
  userLoggedIn = false;
  
}

export const userSignedIn = () => {
  userLoggedIn = true;
}

export const userSignedOut = () => {
  userLoggedIn = false;
}


function App() {

  const [loggedIn, setLoggedIn] = useState(window.localStorage.getItem('userLoggedIn'));
  const [user, setUser] = useState(null);

  if (loggedIn == null) {
    setLoggedIn(false);
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        userSignedIn();
        setLoggedIn(true);
        window.localStorage.setItem('userLoggedIn', true)
      } else {
        // No user is signed in.
        userSignedOut();
        setLoggedIn(false);
        window.localStorage.getItem('userLoggedIn', false)
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);
  
  return (

    <>
      {
        loggedIn ? 
          <Routing /> : 
          <RoutingNoAuth />
      }
    </>
  )

}

export default App;
