import { useEffect, useState } from "react";
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase";
import "../styles/job_dashboard.css"
import Chart from 'chart.js/auto';
import { Bar, Line, Pie } from "react-chartjs-2";
import { stagesNames } from "../job";
import ChartDataLabels from 'chartjs-plugin-datalabels';



export const brandColors = {
  primary: "#9755ab",
  primary20: "hsl(286, 34%, 50%, 20%)",
  primary40: "hsl(286, 34%, 50%, 40%)",
  secondary: "#c899d6",
  secondary40: "hsl(286, 43%, 72%, 40%)",
  accent40: "hsl(286, 52%, 63%, 40%)",
}

// some common chart options for each chart
export const chartOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: false, // This hides the legend
    }
  },
  scales: {
    x: {
      grid: {
        display: false, // Hides X-axis gridlines
      },
      ticks: {
        maxTicksLimit: 8
      },
    },
    y: {
      grid: {
        display: false,
      },
      beginAtZero: true,
      ticks: {
        stepSize: 1
      }
    }
  }
};



const CandidateStages = ({ stages }) => {
  // Create labels and data for the chart

  const labels = stages.map(stage => stage.name);
  const dataCounts = stages.map(stage => stage.count);
  const backgroundColors = stages.map(stage => stage.color);
  const borderColors = stages.map(stage => stage.border);

  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Number of Candidates',
        data: dataCounts,
        backgroundColor: backgroundColors,
        borderColor: borderColors,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    ...chartOptions,

  };

  return <Bar data={data} options={options} />;
}

const ApplicationSourcePie = ({ candidates }) => {
  const sourceCounts = candidates.reduce((acc, candidate) => {
    let source;
    if (candidate.source && candidate.source.name) {
      source = candidate.source.name;
    } else if (candidate.uploaded) {
      source = 'Uploaded';
    } else {
      source = 'Applied';
    }
    acc[source] = (acc[source] || 0) + 1;
    return acc;
  }, {});

  const labels = Object.keys(sourceCounts);
  const data = Object.values(sourceCounts);

  const chartData = {
    labels: labels,
    datasets: [
      {
        data: data,
        backgroundColor: labels.map((_, index) => 
          index % 2 === 0 ? brandColors.primary40 : brandColors.secondary
        ),
        borderColor: brandColors.primary,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    ...chartOptions,
    scales: {},
    plugins: {
      legend: {
        position: "bottom",
        display: false,
      }
    },
  };

  return <Pie style={{height: "100%"}} data={chartData} options={options} />;
};

const ScoreHistogram = ({ candidates }) => {
    // Define the score intervals
    const scoreIntervals = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
  
    // Initialize the count array for each interval with zeros
    const intervalCounts = new Array(scoreIntervals.length - 1).fill(0);
  
    // Count the candidates in each interval
    for (let candidate of candidates) {
      for (let i = 0; i < scoreIntervals.length - 1; i++) {
        if (candidate.score >= scoreIntervals[i] && candidate.score < scoreIntervals[i + 1]) {
          intervalCounts[i]++;
          break;
        }
      }
    }
  
    // Create labels for the chart based on the intervals
    const labels = scoreIntervals.slice(0, -1).map((_, index) => `${scoreIntervals[index]} - ${scoreIntervals[index + 1]}`);
  
    const data = {
      labels: labels,
      datasets: [{
        label: 'Number of Candidates',
        data: intervalCounts,
        backgroundColor: brandColors.primary40,
        borderColor: brandColors.primary,
        borderWidth: 1,
      }]
    };
  
    const options = {
      ...chartOptions,
      
    };
  
    return <Bar data={data} options={options} />;
};
  

const ApplicationHistory = ({chartData}) => {


  const options = {
    ...chartOptions,
  }
  return <Line className="job_dashboard_chart" data={chartData} options={options} />
}


export const JobDashboard = () => {

    const [candidates, setCandidates] = useState(null);


    const labels = []; 

    const [chartData, setChartData] = useState({
        labels,
        datasets: [
        {
            label: 'Number of Applications',
            data: [],
            fill: false,
            backgroundColor: brandColors.primary,
            borderColor: brandColors.primary40,
        }
        ]
    });

    const updateChartData = (jobCreatedTime, candidates) => {
        const dateFormat = { month: '2-digit', day: '2-digit' };
        let chartLabels = [];
        let chartDataset = [];

        // Create a map to count applications per day
        let counts = {};

        // Convert job created time to date object and normalize the time
        const startDate = new Date(jobCreatedTime);
        startDate.setHours(0, 0, 0, 0);

        // Initialize counts map with each day from job created to now
        for(let d = new Date(startDate); d <= new Date(); d.setDate(d.getDate() + 1)){
            let dateStr = d.toLocaleDateString('en-CA', dateFormat);
            counts[dateStr] = 0;
            chartLabels.push(dateStr);
        }

        // Increment counts for each candidate application
        candidates.forEach(candidate => {
            let applicationDate = new Date(candidate.application_time.seconds * 1000);
            applicationDate.setHours(0, 0, 0, 0);
            let dateStr = applicationDate.toLocaleDateString('en-CA', dateFormat);
            if(counts[dateStr] !== undefined){
                counts[dateStr]++;
            }
        });

        chartDataset = chartLabels.map(label => counts[label]);
        chartDataset = chartLabels.map(label => Math.max(0, counts[label])); // Ensure no negative values

        setChartData({
            labels: chartLabels,
            datasets: [
                {
                    ...chartData.datasets[0],
                    data: chartDataset
                }
            ]
        });
    }

    const [job, setJob] = useState(null);

    var jobId = window.location.href.split('/')[4]

    useEffect(() => {  
        if (job !== null && candidates !== null) {
            updateChartData(job.createdTime, candidates);
            generateStagesData();
        }
    }, [job, candidates]);

    useEffect(() => {

        const getJob = async () => {
            try {

                const docRef = doc(db, 'jobs', jobId); // Create a reference to the candidate document
                const docSnap = await getDoc(docRef); // Get the document snapshot
                if (docSnap.exists()) {
                    setJob(docSnap.data()); // Set the candidate state with the data from Firestore
                    const candidatesQuery = query(collection(db, 'candidates'), where('job_id', '==', jobId));
                    const querySnapshot = await getDocs(candidatesQuery);
                    const fetchedCandidates = querySnapshot.docs.map(doc => ({
                      id: doc.id,
                      ...doc.data(),
                    }));
                    setCandidates(fetchedCandidates);
                }
                
            } catch (error) {
                console.error("Error fetching job:", error);
            }
        };

        

        if (jobId) {
            getJob();
        }

    }, []);

    const [stagesData, setStagesData] = useState([]);

    const generateStagesData = () => {
        if (!job || !candidates) return [];
        // Extract stages from the job's pipeline and initialize counts
        const hasPrescreening = job.pipeline.some(stage => stage.label === "prescreening");
        const stagesData = job.pipeline
            .filter(stage => stage.label !== "prescreening")
            .map((stage, index) => ({
                name: stage?.details?.name || stagesNames[stage.label],
                count: 0,
                color: stage.color || brandColors.primary40, // Provide a default color if not specified
                border: brandColors.primary,
            }));
        // Count the number of candidates in each stage
        candidates.forEach(candidate => {
            let stageIndex = candidate.stage;
            if (hasPrescreening && stageIndex > 0) {
                stageIndex -= 1;
            }
            if (stageIndex !== -1 && stageIndex < stagesData.length) {
                stagesData[stageIndex].count += 1;
            }
        });
        setStagesData(stagesData);
    };

    return (
        <div className="job_dashboard_holder">

            <div className="job_dashboard_charts_area">

              <div className="job_dashboard_column">
                  <div className="candidate_information job_dashboard_chart_holder">
                      <p className="job_dashboard_chart_heading">Candidates By Stage</p>
                      {stagesData && ( 
                        <div className="job_dashboard_chart_direct_holder">
                            <CandidateStages className="job_dashboard_chart" stages={stagesData} />
                        </div>
                     )}
                        
                  </div>


                  <div className="candidate_scores job_dashboard_chart_holder">
                    <p className="job_dashboard_chart_heading">Candidates By Score</p>
                    {candidates && (
                        <div className="job_dashboard_chart_direct_holder">
                          <ScoreHistogram candidates={candidates} />                   
                        </div>

                    )}
                  </div>
              </div>

              <div className="job_dashboard_column">
                <div className="applicant_history job_dashboard_chart_holder">
                  <p className="job_dashboard_chart_heading">Application History</p>
                    {job && candidates && ( 
                      <div className="job_dashboard_chart_direct_holder">
                        <ApplicationHistory chartData={chartData} />
                      </div>
                    )}
                </div>


                <div className="application_source job_dashboard_chart_holder">
                  <p className="job_dashboard_chart_heading">Application Source</p>
                  {candidates && (
                    <div className="job_dashboard_chart_direct_holder" style={{height: "80%"}}>
                      <ApplicationSourcePie candidates={candidates} />
                    </div>
                  )}
                </div>

              </div>

            </div>


        </div>
    )
}