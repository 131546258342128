import { X } from "lucide-react";
import { useState } from "react";
import Popup from "reactjs-popup";
import { sendNotification } from "./notification";
import "./styles/allCandidatesActions.css";
import ReactQuill from "react-quill";
import { Placeholder, placeholders } from "./candidateEmailHistory";

export const AllCandidatesActions = ({
    selectedCandidates, 
    candidates, 
    setCandidates, 
    setFilteredCandidates,
    setIsAllChecked,
    jobs,
    employer,
}) => {

    const [selectedAction, setSelectedAction] = useState("Actions");

    const [moveJobPopup, setMoveJobPopup] = useState(false);

    

    const handleChangedOption = (e) => {

        if (e.target.value === "Actions") {
            setSelectedAction("Actions");
            return;
        }


        if (selectedCandidates.length === 0) {
            sendNotification("Please select candidates first!");
            return;
        }

        setSelectedAction(e.target.value);

        if (e.target.value === "MoveJob") {
            setMoveJobPopup(true);
        } else if (e.target.value === "Email") {
            setSendEmailPopupOpen(true);
        } else if (e.target.value === "Delete") {
            setDeleteCandidatePopupOpen(true);
        }
    }

    const handleClosingPopup = () => {
        setSelectedAction("Actions");
        setMoveJobPopup(false);
        setDeleteCandidatePopupOpen(false);
        setSendEmailPopupOpen(false);
        setEmailSubject("");
        setEmailBody("");
        setMoved(false);
        setSentCandidateEmails(false);
        setSelectedJob("");
    }


    // handling the move candidate to job

    const [selectedJob, setSelectedJob] = useState("");

    const [moved, setMoved] = useState(false);

    const moveCandidatesToJob = async () => {

        if (selectedJob === "") {
            sendNotification("Please select a job!");
            return;
        }

        await fetch('https://api.skillpool.tech/move_candidates_job', {
            method: 'POST',
            headers: {  
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                candidateIDs: selectedCandidates.map(candidate => candidate.id),
                jobID: selectedJob,
                jobs: jobs.map(job => ({id: job.id, jobTitle: job.jobTitle})),
            }),
            // Include headers if your server requires them
        });

        const updatedCandidates = candidates.map(candidate => {
            if (selectedCandidates.some(selectedCandidate => selectedCandidate.id === candidate.id)) {
                return {...candidate, job_id: selectedJob, checked: false};
            }
            return {...candidate, checked: false};
        });


        setCandidates(updatedCandidates);
        setFilteredCandidates(updatedCandidates);

        setMoved(true);

        setTimeout(() => {
            handleClosingPopup();
            setMoved(false);
        }, 1250);
        

    }
    

    // handling the delete candidates section

    const [deleteCandidatePopupOpen, setDeleteCandidatePopupOpen] = useState(false);

    const deleteCandidates = async () => {

        const candidatesToDelete = selectedCandidates;

        if (candidatesToDelete.length === 0) {
            setDeleteCandidatePopupOpen(false);
            sendNotification("No candidates selected to delete!");
        }

        const remainingCandidates = candidates.filter(candidate =>
            !candidatesToDelete.some(toDelete => toDelete.id === candidate.id)
        );
        remainingCandidates.forEach(candidate => candidate.checked = false);

        setCandidates(remainingCandidates);
        // Reset the checkboxes for the remaining candidates
        setIsAllChecked(false);

        candidatesToDelete.map((candidate) => {
            try {
                fetch('https://api.skillpool.tech/delete_candidate', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ candidateId: candidate.id }),
                });
            } catch (error) {
                console.error("Error deleting candidates", error);
            }
        });

        handleClosingPopup();

    }



    // handling the send email section

    const [sendEmailPopupOpen, setSendEmailPopupOpen] = useState(false);

    const [emailSubject, setEmailSubject] = useState("");
    const [emailBody, setEmailBody] = useState("");


    const handleEmailBodyChange = (content, delta, source, editor) => {
        setEmailBody(editor.getHTML()); // or content for just the HTML content
    }

    const [sentCandidateEmails, setSentCandidateEmails] = useState(false);

    const [emailTemplates, setEmailTemplates] = useState([]);

    const loadTemplate = (templateKey) => {
        let subject, body;
    
        switch (templateKey) {
            case 'no_update':
                subject = employer.settings.email_templates.no_update.subject;
                body = employer.settings.email_templates.no_update.body;
                break;
            case 'default':
                subject = "";
                body = "";
                break;
            default:
                subject = employer.settings.email_templates.custom[parseInt(templateKey)].subject;
                body = employer.settings.email_templates.custom[parseInt(templateKey)].body;
                // this means that it's an index of the custom emails
                break;
        }
    
        // subject = subject.replace(/{{candidate_name}}/g, candidate?.name.split(" ")[0]);
        subject = subject.replace(/{{employer_name}}/g, employer?.name);
    
        // body = body.replace(/{{candidate_name}}/g, candidate?.name.split(" ")[0]);
        body = body.replace(/{{employer_name}}/g, employer?.name);
    
        setEmailSubject(subject);
        setEmailBody(body);
    };

    const sendCandidatesEmail = () => {


        if (selectedCandidates.some(candidate => !candidate.job_id)) {
            if (emailBody.includes("{{job_title}}")) {
                sendNotification("Some candidates don't have jobs, please remove those candidates or the job placeholder!");
                return;
            }
        }

        if (emailBody === '') {
          sendNotification("Please enter an email body")
          return;
        }
    
        if (emailSubject === '') {
            sendNotification("Please enter an email subject")
            return;
        }
    
        selectedCandidates.forEach(candidate => {
          fetch('https://api.skillpool.tech/send_email', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                senderEmail: candidate?.employerEmail,
                receiverEmail: candidate?.email,
                subject: emailSubject,
                body: emailBody,
                candidateID: candidate.id,
            }),
            // Include headers if your server requires them
          });
        });

        const updatedCandidates = candidates.map(candidate => {
            return {...candidate, checked: false};
        });


        setCandidates(updatedCandidates);
        // Reset the checkboxes for the remaining candidates
        setIsAllChecked(false);

        setSentCandidateEmails(true);

        setTimeout(() => {
            handleClosingPopup();
            setSentCandidateEmails(false);
        }, 1250);
    }
    


    return (
        <>
        <select className="job_candidates_actions" value={selectedAction} onChange={handleChangedOption}>
            <option value="Actions" className="candidates_action">
                Actions
            </option>

            <option value="Email" className="candidates_action">
                Send Email
            </option>

            <option value="MoveJob" className="candidates_action">
                Move to Job
            </option>

            <option value="Delete" className="candidates_action">
                Delete
            </option>
        </select>


        <Popup
          open={sendEmailPopupOpen}
          onOpen={() => setSendEmailPopupOpen(true)}
          modal
          nested
          position="center center"
      >

      <div className="popup_holder">
        <div className="popup">

            <div className="popup_close" onClick={handleClosingPopup}>
              <X />
            </div>

            <h1 className="popup_heading"> Send Emails </h1>

            

            {
              sentCandidateEmails ? (
                <div className="popup_body">
                  <p>The emails have been sent to the selected candidates!</p>
                </div>
              ) : (
                <>
                  <div className="candidate_send_email_description" style={{display: "flex", alignItems:"center", justifyContent:"space-between", width:"100%"}}>
                    <p style={{width:"70%"}}>Go ahead and enter the details for the email, or choose from one of your templates to the right. </p>
                    <div className="send_email_template">
                        <select className="send_email_select" onChange={(e) => loadTemplate(e.target.value)}>
                            <option value="default" selected >Load Template</option>
                            <option value="no_update">No Update</option>
                            {employer && employer.settings.email_templates.custom.map((template, index) => (
                                <option value={index} key={index}>{template.name}</option>
                            ))}
                            {/* now run a map through all of the custom emails */}
                        </select>
                    </div>
                  </div>

                  <div className="candidate_send_email_holder">
                    <div className="candidate_send_email_details">

                        <h3>Subject: </h3>
                        <input type="text" placeholder="Subject" value={emailSubject} onChange={(e) => setEmailSubject(e.target.value)} className="send_email_subject" />

                        <h3>Body: </h3>
                        <ReactQuill theme="snow" value={emailBody} onChange={handleEmailBodyChange} placeholder="Please enter the body here..." className="send_email_body"/>
                    </div>

                    <div className="candidate_send_email_placeholders">
                        <h3>Usable Placeholders</h3>
                        <div className="placeholders_list">
                            {placeholders.map((text, index) => (
                                <Placeholder key={index} text={text} />
                            ))}
                        </div>
                    </div>
                </div>
                

                <div className="popup_bottom_actions">
                    <div className="popup_cancel" onClick={handleClosingPopup}>
                        Cancel
                    </div>

                    <div className="popup_action" onClick={sendCandidatesEmail}>
                        Send
                    </div>
                </div>
              </>
              )
            }

        </div>
      </div>

      </Popup>


        <Popup
            open={moveJobPopup}
            onOpen={() => setMoveJobPopup(true)}
            modal
            nested
            position="center center"
        >
            <div className="popup_holder">
                <div className="popup" style={{width: "55%", height: "50%"}}>

                    <div className="popup_close" onClick={handleClosingPopup}>
                        <X />
                    </div>

                    <h1 className="popup_heading"> Move To Job </h1>
                    

                    <div className="popup_body">

                        {
                            moved ? (
                                <div className="popup_body_loading">
                                    <p>Successfully moved candidate{selectedCandidates.length > 1 ? "s" : ""} to job!</p>
                                </div>
                            ) : (
                                <>
                                    <div className="add_candidates_note">
                                        <p className="add_candidates_note_head" style={{fontWeight:600}}>Note: </p>

                                        <div>
                                            <p className="add_candidates_note_body" style={{marginBottom:"0.5rem"}}>This will move the selected candidates to the job you choose!</p>
                                            <p className="add_candidates_note_body">This action will also remove the candidates from their current job, and cannot be undone!</p>
                                        </div>
                                            
                                    </div>


                                    <div className="move_job_select_holder">
                                        <p className="move_job_select_label">Job: </p>

                                        <select className="move_job_select" onChange={(e) => setSelectedJob(e.target.value)} value={selectedJob}> 
                                            <option value=""> Select Job </option>
                                            {jobs.filter(job => !job.deleted && !job.filled).map((job) => (
                                                <option value={job.id}> {job.jobTitle} </option>
                                            ))}
                                        </select>
                                    </div>
                                </>
                            )
                        }

                        

                    </div>
                
                    <div className="popup_bottom_actions delete_bottom_actions">
                        <div className="cancel_delete" onClick={handleClosingPopup}>
                            Cancel
                        </div>

                        <div className="delete_candidate" onClick={moveCandidatesToJob}>
                            Move
                        </div>
                    </div>
                
                </div>
            </div>
        </Popup>


        <Popup
            open={deleteCandidatePopupOpen}
            onOpen={() => setDeleteCandidatePopupOpen(true)}
            modal
            nested
            position="center center"
        >

        <div className="popup_holder">
            <div className="popup" style={{width: "40%", height:"40%"}}>
                <div className="popup_close" onClick={() => setDeleteCandidatePopupOpen(false)}>
                <X />
                </div>

                <h1 className="popup_heading">Delete Candidates</h1>

                <div className="popup_body">
                <p>Are you sure you want to delete these candidates?</p>
                <p>This decision cannot be undone!</p>
                </div>

                <div className="popup_bottom_actions delete_bottom_actions">
                    <div className="cancel_delete" onClick={() => setDeleteCandidatePopupOpen(false)}>
                        Cancel
                    </div>

                    <div className="delete_candidate" onClick={deleteCandidates}>
                        Delete
                    </div>
                </div>

            </div>
        </div>

        </Popup>


        </>
    )
}

