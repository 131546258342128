import React, { useEffect, useState } from 'react';
import { doc, updateDoc } from "firebase/firestore";
import Popup from "reactjs-popup";
import { db } from "../../firebase";
import { sendNotification } from "../notification";
import * as FaIcons from "react-icons/fa";
import { X } from 'lucide-react';
import { globalPermissions } from '../../routing';

export const EditMember = ({member, currentMember, employerID, teamMembers, setTeamMembers, teams, setTeams}) => {
    const [popupIsOpen, setPopupOpen] = useState(false);
    const [memberFirstName, setMemberFirstName] = useState(member.first_name);
    const [memberLastName, setMemberLastName] = useState(member.last_name);
    const [memberEmail, setMemberEmail] = useState(member.email);
    const [memberRole, setMemberRole] = useState(member.role);
    const [memberDepartment, setMemberDepartment] = useState(member.department);
    const [memberTeam, setMemberTeam] = useState(member.team || "");
    const [editedMember, setEditedMember] = useState(false);

    console.log("MEMBER", member);

    useEffect(() => {
        console.log("MEMBER GOT UPDATED", member.role, member.team);
        updateMemberState();
    }, [member]);

    const updateMemberState = () => {
        setMemberFirstName(member.first_name);
        setMemberLastName(member.last_name);
        setMemberEmail(member.email);
        setMemberTeam(member.team || "");
        setMemberDepartment(member.department || "All");
        setMemberRole(member.role || "");
    }

    const roles = ["Super Admin", "Admin", "Recruiting Lead", "Recruiter", "Hiring Manager", "Interviewer"];
    const departments = ["All", "Software Development", "Data Science", "Product Management", "Marketing", "Finance", "Sales", "Engineering", "Design", "Business", "Consulting", "Other"];

    const openEditPopup = () => {
        if (!globalPermissions.team.edit) {
            sendNotification("You do not have permission to edit member details");
            return;
        }
        updateMemberState();
        setPopupOpen(true);
    }

    const closePopup = () => {
        setPopupOpen(false);
        setEditedMember(false);
    }

    const handleMemberTeamChange = (e) => {
        const selectedTeam = teams.find(team => team.name === e.target.value);
        if (selectedTeam && memberDepartment !== selectedTeam?.department) {
            setMemberDepartment(selectedTeam.department || "All");
        }
        setMemberTeam(e.target.value);
    }

    const handleMemberDepartmentChange = (e) => {
        const selectedTeam = teams.find(team => team.name === memberTeam);
        if (selectedTeam && e.target.value !== selectedTeam?.department) {
            setMemberTeam("");
        }
        setMemberDepartment(e.target.value);
    }

    const editMember = async () => {
        console.log(member);

        if (memberFirstName == "") {
            sendNotification("Please enter a first name!");
            return;
        }

        if (memberLastName == "") {
            sendNotification("Please enter a last name!");
            return;
        }

        if (memberEmail == "") {
            sendNotification("Please enter an email!");
            return;
        }

        if (memberRole == "") {
            sendNotification("Please select a role!");
            return;
        }

        try {
            if (memberTeam !== member.team) {
                let updatedTeams = teams;
                if (member.team && member.team !== "") {
                    updatedTeams = teams.map(team => {
                        if (team.name === member.team) {
                            return {
                                ...team,
                                members: team.members.filter(m => m.id !== member.id)
                            }
                        }
                        return team;
                    })
                }

                if (memberTeam !== "") {
                    updatedTeams = teams.map(team => {
                        if (team.name === memberTeam) {
                            return {
                                ...team,
                                members: [...team.members, {
                                    id: member.id,
                                    first_name: memberFirstName,
                                    last_name: memberLastName,
                                }]
                            }
                        }
                        return team;
                    })
                }

                console.log(employerID);
                const employerDocRef = doc(db, "teams", employerID);
                await updateDoc(employerDocRef, {
                    teams: updatedTeams
                });

                setTeams(updatedTeams);
            }

            const memberRef = doc(db, "members", member.id);
            const memberData = {
                first_name: memberFirstName,
                last_name: memberLastName,
                email: memberEmail,
                role: memberRole,
                department: memberDepartment,
            };

            if (memberTeam !== "") {
                memberData.team = memberTeam;
            }

            console.log(memberData);

            await updateDoc(memberRef, memberData);

            const updatedTeamMembers = teamMembers.map(teamMember => 
                teamMember.id === member.id ? {...teamMember, ...memberData} : teamMember
            );

            setTeamMembers(updatedTeamMembers);
            setEditedMember(true);

            setTimeout(() => {
                setEditedMember(false);
                closePopup();
            }, 1500);
        } catch (error) {
            console.error("Error updating member:", error);
            sendNotification("Failed to update member. Please try again.");
        }
    }

    return (
        <>
        <div className='member_action' onClick={openEditPopup}>
            <FaIcons.FaPen />
        </div>

        <Popup 
          open={popupIsOpen}
          onOpen={() => setPopupOpen(true)}
          nested
          position="center center"
        >   
        <div className="popup_holder">
            <div className='popup' style={{height: "30rem", width: "50rem"}}>
                <div className='popup_close' onClick={closePopup}>
                    <X />
                </div>

                <h1 className='popup_heading'>Edit Member</h1>

                {editedMember ? (
                    <div className='popup_body'>
                        <p>Successfully updated member!</p>
                    </div>
                ) : (
                    <div className='popup_body'>
                        <div className='member_information'>
                            <div className='member_name'>
                                <div className='member_item'>
                                    <p className='member_item_label'>Name: </p>
                                    <input type="text" className='member_input' placeholder='First Name' value={memberFirstName} onChange={(e) => setMemberFirstName(e.target.value)} />
                                </div>

                                <div className='member_item'>
                                    <p className='member_item_label'>Surname: </p>
                                    <input type="text" className='member_input' placeholder='Surname' value={memberLastName} onChange={(e) => setMemberLastName(e.target.value)} />
                                </div>
                            </div>

                            <div className='member_item member_email'>
                                <p className='member_item_label'>Email: </p>
                                <input type="text" className='member_input' placeholder='Email' value={memberEmail} onChange={(e) => setMemberEmail(e.target.value)} />
                            </div>

                            <div className='member_permissions_item member_item'>
                                <p className='member_item_label'>Team: </p>

                                <select className='member_select member_input' value={memberTeam} onChange={handleMemberTeamChange}>
                                    <option value="">Select Team</option>
                                    {teams.map(team => (
                                        <option key={team.name} value={team.name}>{team.name}</option>
                                    ))}
                                </select>
                            </div>
                            
                        </div>

                        <div className='member_permissions'>
                            <p className='member_permissions_label'>Permissions</p>  

                            <div className='member_permissions_holder'>
                                <div className='member_permissions_item member_item'>
                                    <p className='member_item_label'>Role: </p>

                                    <select className='member_select member_input' value={memberRole} onChange={(e) => setMemberRole(e.target.value)}>
                                        <option value="" disabled>Select Role</option>
                                        {roles.map(role => (
                                            <option key={role} value={role}>{role}</option>
                                        ))}
                                    </select>
                                </div>

                                <div className='member_permissions_item member_item'>
                                    <p className='member_item_label'>Department: </p>

                                    <select className='member_select member_input' value={memberDepartment} onChange={handleMemberDepartmentChange}>
                                        {departments.map(department => (
                                            <option key={department} value={department}>{department}</option>
                                        ))}
                                    </select>
                                </div>

                                
                            </div>
                        </div>
                    </div>
                )}

                {!editedMember && (
                    <div className='add_member_actions'>
                        <button className='add_member_button' onClick={editMember}>Update Member</button>
                    </div>
                )}
            </div>
        </div>
        </Popup>
        </>
    )
}