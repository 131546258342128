import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as FaIcons from "react-icons/fa"
import { DonutChart } from '../../components/donutChart';
import { changeJob } from './job_settings';
import { globalCandidates, setGlobalCandidates, stagesNames } from '../job';
import { useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { sendNotification } from '../../components/notification';

//The next few variables function as session variables enabling shuffling through the different candidates

//We're gonna have to change this to using localStorage lmao. Otherwise it fucks up on refresh
export var candidatesList = [];
export var curr_index = 0;

export const setCandidateListIndex = (index) => {
    curr_index = index;
}
export const setCandidateList = (newCandidates) => {
    candidatesList = newCandidates
}


// Constants for the draggable item type
const ItemType = {
    CANDIDATE: 'candidate'
};

// Drag component for candidates
const Candidate = ({ candidate, index, stage, moveCandidate, candidates }) => {
    // const [{ isDragging }, drag] = useDrag(() => ({
    //     type: ItemType.CANDIDATE,
    //     item: { id: candidate.id, index, stage },
    //     collect: (monitor) => ({
    //         isDragging: !!monitor.isDragging(),
    //     }),
    // }));
    const setCandidateArrayByStage = (stage) => {
        const candidatesToShow = candidates
        .filter(candidate => candidate.stage === stage) 
        .sort((a, b) => {
            if (a.score === undefined && b.score === undefined) return 0;
            if (a.score === undefined) return 1;
            if (b.score === undefined) return -1;
            return b.score - a.score;
        })
        .map(candidate => candidate.id); 
        
        setCandidateList(candidatesToShow);
    }

    let navigate = useNavigate();

    const openCandidate = () => {
        setCandidateArrayByStage(stage);
        setCandidateListIndex(index);
        navigate('../candidate/' + candidatesList[index]);
    }

    return (
        // <div ref={drag} style={{ opacity: isDragging ? 0.5 : 1 }} className='candidate_link'>
        <div className='candidate_link' onClick={openCandidate}>
            <div className="candidateLink">
                <div className="candidate_pipeline">
                    <div className='candidate_left_side'>
                        <div className='candidate_opened'>
                            {(candidate.viewed === false) && <div className="glowing-indicator"></div>}
                        </div>
                        <h3>{candidate.name}</h3>
                    </div>
                    {candidate.score !== undefined &&
                        <div className='candidate_right_side'>
                            <DonutChart percentage={candidate.score} color="darkgreen" multiplier={0.04} fontSize={12} />
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

// Drop component for stages
const Stage = ({ stage, children, moveCandidate }) => {
    // const [{ isOver }, drop] = useDrop(() => ({
    //     accept: ItemType.CANDIDATE,
    //     drop: (item) => moveCandidate(item.id, item.stage, stage),
    //     collect: (monitor) => ({
    //         isOver: !!monitor.isOver(),
    //     }),
    // }));

    return (
        // <div ref={drop} className='dropArea' style={{ backgroundColor: isOver ? '#e0e0e0' : 'inherit' }}>
        <div className='dropArea'>
            {children}
        </div>
    );
};
  
    
export const Pipeline = () => {

    const [candidates, setCandidates] = useState([]);
    const [stages, setStages] = useState([])
    const [job, setJob] = useState(null);
    const jobId = window.location.href.split('/')[4];

    useEffect(() => {

        const fetchCandidates = async () => {
            try {
            const response = await fetch('https://api.skillpool.tech/getCandidates', {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                },
                body: JSON.stringify({ job_id: jobId }),
            });
            const data = await response.json();
            setCandidates(data);

            setGlobalCandidates(data
                .sort((a, b) => {
                    if (a.stage !== b.stage) {
                        return a.stage - b.stage;
                    }
                    const scoreA = a.score || 0;
                    const scoreB = b.score || 0;
                    if (scoreA < 0 && scoreB < 0) return scoreA - scoreB;
                    if (scoreA < 0) return -1;
                    if (scoreB < 0) return 1;
                    return scoreA - scoreB;
                })
                .map((candidate) => (
                    candidate.id
                ))
            );

            } catch (error) {
            console.error("Error fetching candidates:", error);
            }
        };

        const fetchJob = async () => {
            try {
            const response = await fetch('https://api.skillpool.tech/getJobById', {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                },
                body: JSON.stringify({ jobId: jobId }),
            });
            const data = await response.json();
            changeJob(data);
            setJob(data);
            setFinishedLoading(true);
            setStages(data.pipeline);
            } catch (error) {
            console.error("Error fetching candidates:", error);
            }
        };

        if (jobId) {
            fetchCandidates();
            fetchJob();
        }

    }, []);

    




    //Handle the mass viewing setup 

    const setCandidateArrayByStage = (stage) => {
        const candidatesToShow = candidates
        .filter(candidate => candidate.stage === stage) 
        .sort((a, b) => {
            // sort by score (higher scores first) TODO: AFTER INTERVIEW
            if (b.score && a.score) {
                return b.score - a.score;
            }
        })
        .map(candidate => candidate.id); 
        
        setCandidateList(candidatesToShow);
    }


    let navigate = useNavigate();

    const reviewCandidates = (stage) => {
        setCandidateArrayByStage(stage);        
        setCandidateListIndex(0);
        navigate('../candidate/' + candidatesList[0]);

    }

    const openCandidate = (stage, index) => {
        setCandidateArrayByStage(stage);
        setCandidateListIndex(index);
        navigate('../candidate/' + candidatesList[index]);
    }

    // Function to move candidates between stages
    const moveCandidate = (candidateId, fromStage, toStage) => {

        console.log("Moving candidate from stage " + fromStage + " to stage " + toStage);

        if (toStage < fromStage) {
            if (toStage !== -1) {
                sendNotification("Can't move candidate to a previous stage!");
                return;
            }
        }

        if (Math.abs(toStage - fromStage) > 1) {
            if (!((toStage === -1) || (fromStage === -1))) {
                sendNotification("Can't move candidate two stages immediately!");
                return;
            }
        }

        setCandidates((prevCandidates) =>
            prevCandidates.map((candidate) =>
                candidate.id === candidateId ? { ...candidate, stage: toStage } : candidate
            )
        );
    };

    
    const renderCandidates = (stage) => {
        const hasPreScreening = stages.some(s => s.label === "prescreening");
        const adjustedStage = hasPreScreening && stage >= 0 ? stage + 1 : stage;

        return candidates
            .filter(candidate => candidate.stage === adjustedStage)
            .sort((a, b) => {
                if (a.score === undefined && b.score === undefined) return 0;
                if (a.score === undefined) return 1;
                if (b.score === undefined) return -1;
                return b.score - a.score;
            })
            .map((candidate, index) => (
                <Candidate key={candidate.id} candidate={candidate} index={index} stage={adjustedStage} moveCandidate={moveCandidate} candidates={candidates} />
            ));
    };


    const [finishedLoading, setFinishedLoading] = useState(false);


    return (
        // <DndProvider backend={HTML5Backend}>
            <div className='all_pipeline_holder'>
                <div className="pipeline_container">
                    {finishedLoading && stages?.filter(stage => stage.label !== "prescreening").map((stage, index) => (
                        <div key={index} className="stage">
                            <div className='stage_top_section'>
                                <div className='stage_title_center'>
                                    <h3>{stage?.details?.name || stagesNames[stage.label]}</h3>
                                </div>
                                <div className='stage_candidates_number'>
                                    {candidates.filter(candidate => {
                                        const hasPreScreening = stages.some(s => s.label === "prescreening");
                                        const adjustedStage = hasPreScreening && index > 0 ? index + 1 : index;
                                        return candidate.stage === adjustedStage;
                                    }).length}
                                </div>
                            </div>
                            <Stage stage={index} moveCandidate={moveCandidate}>
                                {renderCandidates(index)}
                            </Stage>
                        </div>
                    ))}

                    <div className="stage">
                        <div className='stage_top_section'>
                            <div className='stage_title_center'>
                                <h3>Rejected</h3>
                            </div>
                            <div className='stage_candidates_number'>
                                {candidates.filter(candidate => candidate.stage === -1).length}
                            </div>
                        </div>
                        <Stage stage={-1} moveCandidate={moveCandidate}>
                            {renderCandidates(-1)}
                        </Stage>
                    </div>
                </div>
            </div>
        // {/* </DndProvider> */}
    );

    
};