import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Confetti from 'react-confetti';
import { collection, doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';

export const BillingSuccess = ({employerID}) => {
    const navigate = useNavigate();
    const [orderDetails, setOrderDetails] = useState(null);
    const location = useLocation();
    const [showConfetti, setShowConfetti] = useState(false);
    const [companySize, setCompanySize] = useState(null);
    const [subscriptionRenewalDate, setSubscriptionRenewalDate] = useState(null);

    useEffect(() => {
        const fetchOrderDetails = async () => {
            const searchParams = new URLSearchParams(location.search);
            const sessionId = searchParams.get('session_id');

            if (sessionId) {
                try {
                    const response = await fetch(`https://api.skillpool.tech/stripe_order_details?session_id=${sessionId}`);
                    if (response.ok) {
                        const data = await response.json();
                        console.log(data);
                        setOrderDetails(data);
                        setShowConfetti(true);
                        
                    } else {
                        console.error('Failed to fetch order details');
                    }
                } catch (error) {
                    console.error('Error fetching order details:', error);
                }
            }
        };

        fetchOrderDetails();
        
    }, [location]);

    const companySizes = ["1-10", "11-50", "51-100", "101-200", "200+"];

    const parseOrderDetails = (orderDetails) => {
        const data = orderDetails.line_items.data[0];

        const quantity = data.quantity;
        let companySize;

        switch(quantity) {
            case 1:
              companySize = {
                lower: 1,
                upper: 10
              };
              break;
            case 11:
              companySize = {
                lower: 11,
                upper: 50
              };
              break;
            case 51:
              companySize = {
                lower: 51,
                upper: 100
              };
              break;
            case 101:
              companySize = {
                lower: 101,
                upper: 200
              };
              break;
            case 201:
              companySize = {
                lower: 201,
                upper: "NA"
              };
              break;
            default:
              companySize = {
                lower: 1,
                upper: 10
              };
        }
        setCompanySize(companySize);

        const cycle = data.price.recurring.interval;

        let date = new Date();
        if (cycle === "month") { 
            // just use a date a month from now
            date.setMonth(date.getMonth() + 1);
            setSubscriptionRenewalDate(date);
        } else if (cycle === "year") {
            // just use a date a year from now
            date.setFullYear(date.getFullYear() + 1);
            setSubscriptionRenewalDate(date);
        }
        // what data do i need to store
        // type of subscription
        // date started
        // company size
        // date ending

        const billingDetails = {
            subscription_ends: date,
            company_size: companySize,
            cycle: cycle,
            subscription_time: new Date(),
        }

        // console.log(billingDetails);

        const employerRef = doc(db, "teams", employerID);

        updateDoc(employerRef, {billing: billingDetails});
    }

    useEffect(() => {
        if (orderDetails && employerID) {
            parseOrderDetails(orderDetails);
        }
    }, [orderDetails, employerID]);

    return (
        <div className="billing_success_holder">
            {showConfetti && 
                <Confetti 
                    numberOfPieces={200}
                    recycle={false}
                    onConfettiComplete={() => setShowConfetti(false)}
                />
            }
            <h1 className='billing_success_title'>Billing Successful!</h1>
            {(orderDetails && companySize && subscriptionRenewalDate) ? (
                <>
                    <p>Your plan upgrade was successful, and has been processed!</p>
                    <p style={{marginBottom: "2rem"}}>Here's the details regarding your order:</p>
                    {/* <pre>{JSON.stringify(orderDetails, null, 2)}</pre>
                        

                        it should show the company size and the day the subscription will renew.
                    */}

                    <div className='billing_success_details'>
                        <div className='billing_success_details_item'>
                            <p className='billing_success_details_item_label'>Company Size</p>
                            <div className='billing_success_details_item_body'>
                                <p>{companySize.lower} – {companySize.upper}</p>
                            </div>
                        </div>
                        <div className='billing_success_details_item'>
                            <p className='billing_success_details_item_label'>Renews On</p>
                            <div className='billing_success_details_item_body'>
                                <p>{subscriptionRenewalDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })}</p>
                            </div>
                        </div>
                    </div>

                    <p>Please feel free to go back to the <span onClick={() => navigate('/')} style={{color: "var(--primary)", textDecoration: "underline", cursor: "pointer"}}>dashboard</span>, and start recruiting!</p>
                </>
            ) : (
                <p>Loading order details...</p>
            )}
        </div>
    );
};