import React, { useState, useEffect } from "react";
import { sendNotification } from "../notification";
import * as FaIcons from "react-icons/fa";
import Popup from "reactjs-popup";
import { X } from "lucide-react";
import { doc, updateDoc, arrayUnion, arrayRemove } from "firebase/firestore";
import { db } from "../../firebase";
import Autosuggest from "react-autosuggest";
import { globalPermissions } from "../../routing";

export const EditTeam = ({ team, index, teams, setTeams, members, setMembers, currentMember, employerID }) => {
    const [popupIsOpen, setPopupOpen] = useState(false);
    const [teamName, setTeamName] = useState(team.name);
    const [teamDepartment, setTeamDepartment] = useState(team.department || "All");
    const [newTeamMembers, setNewTeamMembers] = useState(team.members || []);
    const [newTeamMember, setNewTeamMember] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [editedTeam, setEditedTeam] = useState(false);

    const departments = ["All", "Software Development", "Data Science", "Product Management", "Marketing", "Finance", "Sales", "Engineering", "Design", "Business", "Consulting", "Other"];

    useEffect(() => {
        if (team) {
            setTeamName(team.name);
            setTeamDepartment(team.department);
            setNewTeamMembers(team.members || []);
        }
    }, [team]);

    const openEditPopup = (e) => {
        e.stopPropagation();
        e.preventDefault();

        if (!globalPermissions.team.edit) {
            sendNotification("You do not have permission to edit team details");
            return;
        }
        
        setPopupOpen(true);
    }

    const closePopup = () => {
        setPopupOpen(false);
        setEditedTeam(false);
    }

    const getSuggestions = (value) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        return inputLength === 0 ? [] : members.filter(member =>
            `${member.first_name} ${member.last_name}`.toLowerCase().includes(inputValue)
        );
    };

    const getSuggestionValue = (suggestion) => `${suggestion.first_name} ${suggestion.last_name}`;

    const renderSuggestion = (suggestion) => (
        <div>
            {`${suggestion.first_name} ${suggestion.last_name}`}
        </div>
    );

    const onSuggestionsFetchRequested = ({ value }) => {
        setSuggestions(getSuggestions(value));
    };

    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    const onSuggestionSelected = (event, { suggestion }) => {
        handleAddTeamMember(suggestion);
    };

    const handleAddTeamMember = (member) => {
        if (!newTeamMembers.some(m => m.id === member.id)) {
            setNewTeamMembers([...newTeamMembers, member]);
        }
        setNewTeamMember('');
    };

    const handleDeleteTeamMember = (index) => {
        const updatedTeamMembers = newTeamMembers.filter((_, i) => i !== index);
        setNewTeamMembers(updatedTeamMembers);
    };

    const inputProps = {
        className: "interviewers_input_area",
        placeholder: "Add team members!",
        value: newTeamMember,
        onChange: (e, { newValue }) => setNewTeamMember(newValue)
    };

    const handleEditTeam = async () => {
        if (teamName.trim() === "") {
            sendNotification("Please enter a team name!");
            return;
        }

        console.log("Editing the team here!");

        const teamData = {
            name: teamName,
            department: teamDepartment,
            members: newTeamMembers.map(member => {
                return {
                    first_name: member.first_name,
                    last_name: member.last_name,
                    id: member.id
                }
            })
        }

        let updatedTeams = teams.map((t, i) => i === index ? teamData : t);
        
        console.log("Updated teams:", updatedTeams);

       
        newTeamMembers.forEach(member => {
            
            if (member.team !== "") {
                // clearly this person was already in a different team.
                updatedTeams = updatedTeams.map(team => {
                  if (team.name === member.team) {
                    return {
                      ...team,
                      members: team.members.filter(m => m.id !== member.id)
                    }
                  }
                  return team;
                });
                
            }

            const memberDocRef = doc(db, "members", member.id);
            updateDoc(memberDocRef, {
                team: teamName,
                department: teamDepartment
            });
        });

        

        const employerDocRef = doc(db, "teams", employerID);
        await updateDoc(employerDocRef, {
            teams: updatedTeams
        });


        const updatedMembers = members.map((member) => {
            if (newTeamMembers.some(newMember => newMember.id === member.id)) {
                return {
                    ...member,
                    team: teamName,
                    department: teamDepartment
                };
            }
            return member;
        });

        console.log("Updated members:", updatedMembers);
        setMembers(updatedMembers);
        setTeams(updatedTeams);
        setEditedTeam(true);

        setTimeout(() => {
            setEditedTeam(false);
            closePopup();
        }, 1500);
    }

    return (
        <>
            <div className='member_action' onClick={openEditPopup}>
                <FaIcons.FaPen />
            </div>

            <Popup 
                open={popupIsOpen}
                nested
                position="center center"
            >
                <div className='popup_holder'>
                    <div className='popup' style={{height: "30rem", width: "50rem"}}>
                        <div className='popup_close' onClick={closePopup}>
                            <X />
                        </div>

                        <h1 className='popup_heading'>Edit Team</h1>

                        {editedTeam ? (
                            <div className='popup_body'>
                                <p>Successfully edited team!</p>
                            </div>
                        ) : (
                            <div className='popup_body' style={{display: "flex"}}>
                                <div className='team_info_holder'>
                                    <div className='member_item' style={{marginBottom: "2rem"}}>
                                        <p className='member_item_label'>Name: </p>
                                        <input type="text" className='member_input' placeholder='Team Name' value={teamName} onChange={(e) => setTeamName(e.target.value)} />
                                    </div>

                                    <div className='member_item'>
                                        <p className='member_item_label'>Department: </p>
                                        <select className='member_select member_input' value={teamDepartment} onChange={(e) => setTeamDepartment(e.target.value)}>
                                            {departments.map(department => (
                                                <option key={department} value={department}>{department}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className='team_info_holder'>
                                    <p className='member_item_label' style={{margin:0, marginBottom: "1rem"}}>Members</p>

                                    <div className='new_team_members_area'>
                                        <Autosuggest
                                            suggestions={suggestions}
                                            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                                            onSuggestionsClearRequested={onSuggestionsClearRequested}
                                            getSuggestionValue={getSuggestionValue}
                                            renderSuggestion={renderSuggestion}
                                            inputProps={inputProps}
                                            onSuggestionSelected={onSuggestionSelected}
                                            theme={{
                                                container: 'autosuggest-container',
                                                suggestionsContainer: 'suggestions-container',
                                                suggestionsList: 'suggestions-list',
                                                suggestion: 'suggestion-item'
                                            }}
                                        />
                                        <div className="new_team_members_list">
                                            {newTeamMembers.map((member, index) => (
                                                <div key={index} className="new_team_member_item">
                                                    <span>{`${member.first_name} ${member.last_name}`}</span>
                                                    <FaIcons.FaTrashAlt 
                                                        className="delete_team_member" 
                                                        onClick={() => handleDeleteTeamMember(index)} 
                                                    />
                                                </div>
                                            ))}

                                            {newTeamMembers.length === 0 && (
                                                <div className="no_members_message" style={{padding: "1rem"}}>
                                                    No members added yet!
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {!editedTeam && (
                            <div className='add_member_actions'>
                                <button className='add_member_button' onClick={handleEditTeam}>Save Changes</button>
                            </div>
                        )}
                    </div>
                </div>
            </Popup>
        </>
    )
}