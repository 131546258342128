import * as FaIcons from "react-icons/fa"
import "./styles/candidateHistory.css"
import { useMemo } from "react";

export const CandidateHistory = ({
    isOpen,
    toggleContainer,
    candidate,
    history,
    emailHistory,
    toggleEmailHistory,
}) => {


    // Combine and sort history
    const combinedHistory = useMemo(() => {
        const combined = [
            ...history.map(item => ({ ...item, type: item.type || "history" })),
            ...emailHistory.map(item => ({ ...item, type: "email" }))
        ];
        return combined.sort((a, b) => {
            const timeA = a.time.seconds ? a.time.seconds * 1000 : a.time._seconds * 1000;
            const timeB = b.time.seconds ? b.time.seconds * 1000 : b.time._seconds * 1000;
            return timeA - timeB; // Sort in ascending order (oldest first)
        });
    }, [history, emailHistory]);

    function getOrdinalSuffix(day) {
        if (day > 3 && day < 21) return 'th'; // covers 4th to 20th
        switch (day % 10) {
            case 1: return 'st';
            case 2: return 'nd';
            case 3: return 'rd';
            default: return 'th';
        }
    }

    function formatDateWithOrdinal(date, timeZone) {
        const options = {
          day: 'numeric',
          month: 'short',
        };

        const formattedDate = date.toLocaleDateString('en-GB', options);

        const day = date.getDate();
        const ordinal = getOrdinalSuffix(day);

        return formattedDate.replace(/(\d+)/, `$1${ordinal}`);
    }


    const openEmailHistory = () => {
        toggleEmailHistory(true);
    }



    return (
        <div className={`tasks_container ${isOpen ? "open" : ""}`}>

            <div className="tasks_heading" onClick={toggleContainer}>
                <p className="tasks_heading_text">Candidate History</p>
                {isOpen && 
                    <FaIcons.FaChevronRight className="tasks_heading_icon" />
                }

                
            </div>  

            <div className="tasks_body candidate_history_area">
                {combinedHistory.map((item, index) => (
                    <div key={index} className="candidate_history_item">

                        {
                            item.type === "applied" && (
                                (item.job === "NA") ? (
                                    <div className="candidate_history_item_content">
                                        <p>{candidate?.name.split(" ")[0]} was added!</p>
                                    </div>
                                ) : (
                                    <div className="candidate_history_item_content">
                                        <p>{candidate?.name.split(" ")[0]} applied to:</p>
                                        <p><strong>{item.job}</strong></p>
                                    </div>
                                )
                            )
                        }

                        {
                            item.type === "stage" && (
                                <div className="candidate_history_item_content">
                                    <p>{candidate?.name.split(" ")[0]} was moved: </p>
                                    <p style={{display: "flex", alignItems: "center"}}>
                                        <strong style={{maxWidth: "42.5%", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", marginRight: "2.5%"}}>{item.from}</strong>
                                        <FaIcons.FaArrowRight style={{fontWeight: "100", maxWidth: "5%", flexShrink: 0}} />
                                        <strong style={{maxWidth: "42.5%", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", marginLeft: "2.5%"}}>{item.to}</strong>
                                    </p>
                                </div>
                            )
                        }

                        {
                            item.type === "email" && (
                                <div className="candidate_history_item_content" >
                                    <p>{candidate?.name.split(" ")[0]} was <span className="candidate_history_item_email" onClick={openEmailHistory}>sent an email</span>: </p>
                                    <p style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{item.subject}</p>
                                </div>
                            )
                        }

                        {
                            item.type === "job_moved" && (
                                <div className="candidate_history_item_content" >
                                    <p>{candidate?.name.split(" ")[0]} was moved: </p>
                                    <p style={{display: "flex", alignItems: "center", width: "100%"}}>
                                        <strong style={{maxWidth: "42.5%", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", marginRight: "2.5%"}}>{item.from === "NA" ? "General" : item.from}</strong>
                                        <FaIcons.FaArrowRight style={{fontWeight: "100", maxWidth: "5%", flexShrink: 0}} />
                                        <strong style={{maxWidth: "42.5%", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", marginLeft: "2.5%"}}>{item.to}</strong>
                                    </p>
                                </div>
                            )
                        }

                        <div className="candidate_history_item_date">
                            <p>{formatDateWithOrdinal(new Date((item.time.seconds || item.time._seconds) * 1000))}</p>
                        </div>
                       
                    </div>
                ))}

                {
                    combinedHistory.length === 0 && (
                        <div className="no_candidate_history_item">
                            <p>No history yet!</p>
                        </div>
                    )
                }
            </div>

            
        </div>
    )
}