import { Draggable } from "react-beautiful-dnd";
import * as FaIcons from "react-icons/fa";
import { stagesNames } from "../../job";
import { X } from "lucide-react";
import { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import { TechnicalSkillsCategory } from "../../../components/pipeline_nodes/skillscore_categories/technicalSkills";
import { PersonalityTraitsCategory } from "../../../components/pipeline_nodes/skillscore_categories/personalityTraits";
import { SoftSkillsCategory } from "../../../components/pipeline_nodes/skillscore_categories/softSkills";
import { WorkCategory } from "../../../components/pipeline_nodes/skillscore_categories/workHistory";
import { EducationCategory } from "../../../components/pipeline_nodes/skillscore_categories/education";
import ToggleButton from "../../../components/toggleButton";
import { sendNotification } from "../../../components/notification";


export const EditSkillScore = ({index, provided, stage, setPipeline, job}) => {

    const [popupIsOpen, setPopupOpen] = useState(false);

    const openPopup = (event) => {
        setPopupOpen(true);
        event.preventDefault();
    }

    const [educationHistoryRating, setEducationHistoryRating] = useState(0);
    const [educationHistoryComplete, setEducationHistoryComplete] = useState(false);
    const [educationHistoryData, setEducationHistoryData] = useState({});

    const [workHistoryRating, setWorkHistoryRating] = useState(0);
    const [workHistoryComplete, setWorkHistoryComplete] = useState(false);
    const [workHistoryData, setWorkHistoryData] = useState({});
    
    
    const [technicalSkillsRating, setTechnicalSkillsRating] = useState(0);
    const [technicalSkillsComplete, setTechnicalSkillsComplete] = useState(false);
    const [technicalSkillsData, setTechnicalSkillsData] = useState({});

    const [softSkillsRating, setSoftSkillsRating] = useState(0);
    const [softSkillsComplete, setSoftSkillsComplete] = useState(false);
    const [softSkillsData, setSoftSkillsData] = useState({});

    const [personalityFitRating, setPersonalityFitRating] = useState(0);
    const [personalityFitComplete, setPersonalityFitComplete] = useState(false);
    const [personalityFitData, setPersonalityFitData] = useState({});

    const [screeningInterviewEnabled, setScreeningInterviewEnabled] = useState(false);
    

    useEffect(() => {
        const educationHistory = stage.educationHistory;
        const workHistory = stage.workHistory;
        const technicalSkills = stage.technicalSkills;
        const softSkills = stage.softSkills;
        const personalityFit = stage.personalityFit;

        setEducationHistoryRating(educationHistory.rating);
        setWorkHistoryRating(workHistory.rating);
        setTechnicalSkillsRating(technicalSkills.rating);
        setSoftSkillsRating(softSkills.rating);
        setPersonalityFitRating(personalityFit.rating);


        setEducationHistoryData({
            gpaRating: educationHistory.gpaRating,
            collegeLevelRating: educationHistory.collegeLevelRating,
        });

        setWorkHistoryData({
            workExperienceRating: workHistory.workExperienceRating,
            workExperienceData: workHistory.workExperienceData,
        });


        setTechnicalSkillsData(technicalSkills);

        setSoftSkillsData(softSkills);

        setPersonalityFitData(personalityFit);

        if (personalityFit.rating > 0 && softSkills.rating > 0) {
            setScreeningInterviewEnabled(true);
        }
        
        
        
    }, [stage]);

    const handleSaving = () => {
        // setPopupOpen(false);

        const newStage = {
            label: "skillscore",
            complete: educationHistoryComplete && workHistoryComplete && technicalSkillsComplete && softSkillsComplete && personalityFitComplete,
            educationHistory: educationHistoryData,
            workHistory: workHistoryData,
            technicalSkills: technicalSkillsData,
            softSkills: softSkillsData,
            personalityFit: personalityFitData,
        }

        const newPipeline = job.pipeline;

        newPipeline[index] = newStage;



        setPipeline(newPipeline);
        setPopupOpen(false);
    }


    return (
        <>
         <Draggable key={stage.id || index} draggableId={`stage-${index}`} index={index}>
                    {(provided) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="edit_pipeline_stage"
                            onClick={openPopup}
                        >
                            <div className="stage_details" style={{display:"flex", alignItems:"center"}}>
                                <div style={{width: "2rem"}}>
                                    {
                                        stage.label === "interview" && (
                                            <FaIcons.FaGripVertical style={{ cursor: 'grab' }} />
                                        )
                                    }
                                </div>
                                <p style={{margin:0, marginRight:"0.5rem", width:"10rem"}}>{stagesNames[stage.label]}</p>

                                {
                                    (stage.label === "applied" || stage.label === "hired" || stage.label === "made_offer") ? (
                                        <>
                                        </>
                                ) : (
                                    <p style={{margin:0, opacity:0.5, fontWeight:"500"}}>Click to Edit</p>
                                )}
                            </div>
                            
                        </div>
                    )}
                </Draggable>

        
        <Popup 
            open={popupIsOpen}
            onOpen={() => setPopupOpen(true)}
            modal
            nested
            position="center center"
        >
            <div className="popup_holder overlay">
                <div className="popup">
                <div className="top_section" style={{ height: "85%" }}>
                    <h1 className="create_stage_heading"> SkillScore </h1>

                    <div className="popup_close" onClick={handleSaving}>
                        <X />
                    </div>

            

                        <div className="skillscore_heading">
                        <div className="skillscore_info_column">
                            <p className="skillscore_heading_text">Measured Using</p>
                        </div>

                        <div className="skillscore_content_column">
                            <p className="skillscore_heading_text">Measuring</p>
                        </div>
                        </div>

                        <div className="skillscore_row">
                        <div className="skillscore_info_column">
                            <p className="skillscore_row_text">Resume Screening</p>
                        </div>

                        <div className="skillscore_content_column skillscore_row_content">
                            <EducationCategory
                            weightage={educationHistoryRating}
                            setWeightage={setEducationHistoryRating}
                            complete={educationHistoryComplete}
                            setComplete={setEducationHistoryComplete}
                            data={educationHistoryData}
                            setData={setEducationHistoryData}
                            />

                            <WorkCategory
                            weightage={workHistoryRating}
                            setWeightage={setWorkHistoryRating}
                            complete={workHistoryComplete}
                            setComplete={setWorkHistoryComplete}
                            data={workHistoryData}
                            setData={setWorkHistoryData}
                            />

                            <TechnicalSkillsCategory
                            weightage={technicalSkillsRating}
                            setWeightage={setTechnicalSkillsRating}
                            complete={technicalSkillsComplete}
                            setComplete={setTechnicalSkillsComplete}
                            data={technicalSkillsData}
                            setData={setTechnicalSkillsData}
                            editing={true}
                            />
                        </div>
                        </div>

                        <div className="skillscore_row">
                        <div className="skillscore_info_column">
                            <ToggleButton
                            isOn={screeningInterviewEnabled}
                            setIsOn={(value) => {
                                sendNotification("Cannot change interview settings when editing a job!");
                            }}
                            />
                            <p className="skillscore_row_text">Screening Interview</p>
                        </div>

                        <div className="skillscore_content_column skillscore_row_content">
                            <SoftSkillsCategory
                            weightage={softSkillsRating}
                            setWeightage={setSoftSkillsRating}
                            complete={softSkillsComplete}
                            setComplete={setSoftSkillsComplete}
                            data={softSkillsData}
                            setData={setSoftSkillsData}
                            editing={true}
                            />

                            <PersonalityTraitsCategory
                            weightage={personalityFitRating}
                            setWeightage={setPersonalityFitRating}
                            complete={personalityFitComplete}
                            setComplete={setPersonalityFitComplete}
                            data={personalityFitData}
                            setData={setPersonalityFitData}
                            editing={true}
                            />
                        </div>
                        </div>

                    </div>
                </div>
            </div>
        </Popup>
        </>
    )
}