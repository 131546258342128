import { useEffect, useState } from "react";
import "./styles/scheduling.css";
import { auth, db } from "../../firebase";
import { collection, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import * as FaIcons from "react-icons/fa";
import { sendNotification } from "../../components/notification";

export const SettingsScheduling = ({permissions}) => {


    const [employerData, setEmployerData] = useState(null);
    const [employerID, setEmployerID] = useState(null);
    const [canRun, setCanRun] = useState(false);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
            setCanRun(true);
            } else {
            // No user is signed in.
            setCanRun(false);
            }
        });

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, []);



    useEffect(() => {
        
        

        const getTeam = async () => {
            try {
                const user = auth.currentUser;
                if (!user) {
                throw new Error('No authenticated user found');
                }
                
                // Step 1: Get the user's member document to find their team_id
                const membersRef = collection(db, "members");
                const qMember = query(membersRef, where("email", "==", user.email));
                const memberSnapshot = await getDocs(qMember);
                if (memberSnapshot.empty) {
                throw new Error('Member not found');
                }
                
                const memberData = memberSnapshot.docs[0].data();
                const { team_id } = memberData;
                setEmployerID(team_id);
                // Step 2: Use team_id to find the team and retrieve the members array
                const teamSnapshot = await getDoc(doc(db, "teams", team_id));
                if (!teamSnapshot.exists()) {
                    throw new Error('Team not found');
                }
                
                const teamData = teamSnapshot.data();
                setEmployerData(teamData);
                
            } catch (error) {
                console.error("Failed to fetch user data", error);
            }
        };

        getTeam();
    }, [canRun]);


    useEffect(() => {
        if (employerData) {
            if (employerData.scheduling) {
                setMinimumDaysToSelect(employerData.scheduling.daysToSelect);
                setMinimumHoursPerDay(employerData.scheduling.hoursPerDay);
                setMaximumDaysToShow(employerData.scheduling.daysToShow);
            } else {
                setMinimumDaysToSelect(5);
                setMinimumHoursPerDay(5);
                setMaximumDaysToShow(14);
            }
        }
    }, [employerData]);

    const [minimumDaysToSelect, setMinimumDaysToSelect] = useState(null);
    const [minimumHoursPerDay, setMinimumHoursPerDay] = useState(null);
    const [maximumDaysToShow, setMaximumDaysToShow] = useState(null);

    const handleMinimumDaysChange = (value) => {
        setMinimumDaysToSelect(Math.max(1, value));
    };

    const handleMinimumHoursChange = (value) => {
        setMinimumHoursPerDay(Math.max(1, value));
    };

    const handleMaximumDaysChange = (value) => {
        setMaximumDaysToShow(Math.max(1, value));
    };


    const [isSaving, setIsSaving] = useState(false);
    const [saved, setSaved] = useState(false);

    const handleSave = async () => {

        if (!permissions.settings.change_scheduling) {
            sendNotification("You do not have permission to change scheduling settings!");
            return;
        }

        // Ensure constraints are met
        if (minimumHoursPerDay > 12) {
            sendNotification("Please select a maximum ofd 12 hours per day!")
            return;
        }
        if (minimumDaysToSelect > maximumDaysToShow - 2) {
            sendNotification("The days to select must be atleast two less than the days to show!")
            return;
        }

        if ((minimumHoursPerDay < 0) || (minimumDaysToSelect < 0) || (maximumDaysToShow < 0)) {
            sendNotification("Please ensure that all values are positive!")
            return;
        }


        setIsSaving(true);
        const data = {
            daysToSelect: minimumDaysToSelect,
            hoursPerDay: minimumHoursPerDay,
            daysToShow: maximumDaysToShow
        }

        const teamRef = doc(db, "teams", employerID);
        await updateDoc(teamRef, {
            scheduling: data
        });

        console.log(data);
        setIsSaving(false);
        setSaved(true);

        setTimeout(() => {
            setSaved(false);
        }, 1000);
    }

    return (
        <div className="settings_scheduling">
            <div className="settings_scheduling_category">
                <p className="settings_scheduling_category_heading">Candidate Availability</p>

                <div className="candidate_scheduling_section_holder">
                    <div className="candidate_scheduling_section">
                        <p className="candidate_scheduling_section_heading">Date Visibility</p>

                        <div className="candidate_scheduling_section_content">
                            <div className="candidate_scheduling_section_content_item">
                                <p>Number of days visible for interview selection: </p>
                                <div className="number-input-container">
                                    <input
                                        type="number"
                                        className="candidate_scheduling_section_content_item_input"
                                        value={maximumDaysToShow}
                                        onChange={(e) => handleMaximumDaysChange(parseInt(e.target.value))}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="candidate_scheduling_section">
                        <p className="candidate_scheduling_section_heading">Minimum Selections</p>

                        <div className="candidate_scheduling_section_content">
                            <div className="candidate_scheduling_section_content_item">
                                <p>Number of days to select: </p>
                                <div className="number-input-container">
                                    <input
                                        type="number"
                                        className="candidate_scheduling_section_content_item_input"
                                        value={minimumDaysToSelect}
                                        onChange={(e) => handleMinimumDaysChange(parseInt(e.target.value))}
                                    />
                                </div>
                            </div>

                            <div className="candidate_scheduling_section_content_item">
                                <p>Number of hours to select per day: </p>
                                <div className="number-input-container">
                                    <input
                                        type="number"
                                        className="candidate_scheduling_section_content_item_input"
                                        value={minimumHoursPerDay}
                                        onChange={(e) => handleMinimumHoursChange(parseInt(e.target.value))}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="candidate_scheduling_save">
                    {
                        isSaving ? (
                            <button className="candidate_scheduling_save_button candidate_scheduling_saving" >Saving...</button>
                        ) : (
                            saved ? (
                                <button className="candidate_scheduling_save_button candidate_scheduling_saved">
                                    <FaIcons.FaCheck style={{marginRight: "0.5rem"}} />
                                    Saved
                                </button>
                            ) : (
                                <button className="candidate_scheduling_save_button" onClick={handleSave}>
                                    <FaIcons.FaSave style={{marginRight: "0.5rem"}} />
                                    <p>Save</p>
                                </button>
                            )
                        )
                    }
                    
                </div>
            </div>
        </div>
    )
}