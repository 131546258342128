import { useEffect, useState } from 'react';
import './styles/tasks.css'
import * as FaIcons from "react-icons/fa"
import { HiArrowUpRight } from "react-icons/hi2";
import { useNavigate } from 'react-router-dom';


export const Tasks = ({isOpen, toggleContainer, tasks, setTasks, member}) => {

    const navigate = useNavigate();

    useEffect(() => {
        if (member) {
            setTasks(member.tasks);
        }
    }, [member]);

    // there need to be different tabs for different types of tasks
    
    const [activeSection, setActiveSection] = useState('schedule');
    
    const getTimeSince = (timestamp) => {
        const now = new Date();
        const taskTime = new Date(timestamp * 1000);
        const diffInMinutes = Math.floor((now - taskTime) / (1000 * 60));
        
        if (diffInMinutes < 60) {
            return `${diffInMinutes} ${diffInMinutes === 1 ? 'minute' : 'minutes'} ago`;
        } else if (diffInMinutes < 1440) { // Less than 24 hours
            const diffInHours = Math.floor(diffInMinutes / 60);
            return `${diffInHours} ${diffInHours === 1 ? 'hour' : 'hours'} ago`;
        } else {
            const diffInDays = Math.floor(diffInMinutes / 1440);
            return `${diffInDays} ${diffInDays === 1 ? 'day' : 'days'} ago`;
        }
    }

    const handleTaskClick = (task) => {
        // in most cases, this will just be to open the best page

        if (task.type === 'schedule') {
            const link = "/schedule/" + task.id;
            navigate(link);
            toggleContainer();
        }
    }

    return (
        <div className={`tasks_container ${isOpen ? "open" : ""}`}>

            <div className="tasks_heading" onClick={toggleContainer}>
                <p className="tasks_heading_text">Tasks </p>
                {isOpen && 
                    <FaIcons.FaChevronRight className="tasks_heading_icon" />
                }

                
            </div>  

            <div className="tasks_body">
                {tasks?.length > 0 ? (     
                    <>           
                    <div className="tasks_tabs subnav">
                        <div className={`subnav_item ${activeSection === 'schedule' ? 'active_subnav_item' : ''}`} onClick={() => setActiveSection('schedule')}>
                            <p>Schedule</p>

                            {tasks?.filter(task => task.type === 'schedule').length > 0 && (
                                <div className="tasks_tabs_heading_count">
                                    {tasks?.filter(task => task.type === 'schedule').length} 
                                </div>
                            )}

                        </div>

                        <div className={`subnav_item ${activeSection === 'feedback' ? 'active_subnav_item' : ''}`} onClick={() => setActiveSection('feedback')}>
                            <p>Feedback</p>

                            {tasks?.filter(task => task.type === 'feedback').length > 0 && (
                                <div className="tasks_tabs_heading_count">
                                    {tasks?.filter(task => task.type === 'feedback').length} 
                                </div>
                            )}

                        </div>
                        
                    </div>

                    <div className="tasks_tab_content">
                    {tasks?.filter(task => task.type === activeSection).length > 0 ? (
                        tasks?.filter(task => task.type === activeSection).map((task, index) => (
                            activeSection === 'feedback' ? (
                                <a key={index} target='_blank' href={`https://jobs.skillpool.tech/feedback/${task.id}`} style={{textDecoration: 'none', color: 'var(--text)'}}>
                                    <div className="task_item" onClick={() => handleTaskClick(task)}>
                                        <div className="task_item_info">
                                            <p style={{marginBottom: '0.5rem'}}><strong>Feedback</strong>: {task.name} | {task.candidate?.name}</p>
                                            <div className='task_item_info_date'>
                                                <FaIcons.FaRegClock />
                                                <p>{getTimeSince(task?.time?.seconds)}</p>
                                            </div>
                                        </div>
                                        <div className="task_item_actions">
                                            <strong><HiArrowUpRight className='task_item_actions_icon' /></strong>
                                        </div>
                                    </div>
                                </a>
                            ) : (
                                <div key={index} className="task_item" onClick={() => handleTaskClick(task)}>
                                    <div className="task_item_info">
                                        <p style={{marginBottom: '0.5rem'}}><strong>Schedule</strong>: {task.name} | {task.candidate?.name}</p>
                                        <div className='task_item_info_date'>
                                            <FaIcons.FaRegClock />
                                            <p>{getTimeSince(task?.time?.seconds)}</p>
                                        </div>
                                    </div>
                                    <div className="task_item_actions">
                                        <strong><HiArrowUpRight className='task_item_actions_icon' /></strong>
                                    </div>
                                </div>
                            )
                        ))
                    ) : (
                        <p className="no_tasks">No tasks for this type yet!</p>
                    )}
                    </div>
                    </>
                ) : (
                    <p className="no_tasks">No tasks yet!</p>
                )}
            </div>
        </div>

    )
}