import React, { useState, useEffect } from "react";
import Popup from "reactjs-popup";
import { X } from 'lucide-react';
import "./styles/billing.css";
import * as FaIcons from "react-icons/fa";
import { auth } from "../firebase";

export const Billing = () => {
    const [billingPopupOpen, setBillingPopupOpen] = useState(true);
    const [companySize, setCompanySize] = useState("1-10");
    const [billingCycle, setBillingCycle] = useState("monthly");
    const [userEmail, setUserEmail] = useState("");

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                setUserEmail(user.email);
            }
        });

        return () => unsubscribe();
    }, []);

    const prices = {
        "1-10": 100,
        "11-50": 150,
        "51-100": 200,
        "101-200": 300,
        "200+": 500
    };

    const getPrice = () => {
        let price = prices[companySize];
        if (billingCycle === "annual") {
            price = price * 12 * 0.8; // 20% discount for annual
        }
        return price;
    };

    const getDiscountedPrice = () => {
        return getPrice() * 0.5; // 50% early adopter discount
    };

    return (
        <Popup
            open={billingPopupOpen}
            onOpen={() => setBillingPopupOpen(true)}
            closeOnEscape={false}
            modal
            nested
            position="center center"    
        >
            <div className="popup_holder">
            <form className="billing_form" action="https://api.skillpool.tech/create_checkout_session" method="POST">
                <input type="hidden" name="userEmail" value={userEmail} />
                <div className="popup" style={{width: "50%", height: "60%"}}>

                    <h1 className="popup_heading" style={{marginBottom: "1rem"}}>Upgrade Your Plan!</h1>
                    
                    <div className="popup_body">
                        
                        <div className="billing_popup_text_container">
                            <p className="billing_popup_text">Unfortunately, your free trial for SkillPool has ended! Please upgrade to keep using the platform!</p>
                        </div>
                        
                        
                            <div className="billing_selector">
                                <div className="billing_selector_item">

                                    <div className="billing_selector_item_name">
                                        <FaIcons.FaUsers className="billing_selector_item_icon" />
                                        <label htmlFor="companySize">Company Size:</label>
                                    </div>
                                
                                    <select 
                                        id="companySize"
                                        name="companySize"
                                        className="billing_selector_item_select"
                                        value={companySize} 
                                        onChange={(e) => setCompanySize(e.target.value)}
                                    >
                                        <option value="1-10">1-10 employees</option>
                                        <option value="11-50">11-50 employees</option>
                                        <option value="51-100">51-100 employees</option>
                                        <option value="100-200">100-200 employees</option>
                                        <option value="200+">200+ employees</option>
                                    </select>
                                </div>

                                <div className="billing_selector_item">
                                    <div className="billing_selector_item_name">
                                        <FaIcons.FaReceipt className="billing_selector_item_icon" />
                                        <label htmlFor="billingCycle">Billing Cycle:</label>
                                    </div>
                                    <select 
                                        id="billingCycle"
                                        name="billingCycle"
                                        className="billing_selector_item_select"
                                        value={billingCycle} 
                                        onChange={(e) => setBillingCycle(e.target.value)}
                                    >
                                        <option value="monthly">Monthly</option>
                                        <option value="annual">Annual (20% off)</option>
                                    </select>
                                </div>
                            </div>
                            

                            <div className="billing_total">
                                <span className="billing_total_text">Total: </span>
                                <div className="billing_total_old_price">
                                    ${getPrice()}
                                </div>

                                <div className="billing_total_discounted_price">
                                    <span className="billing_final_price">${getDiscountedPrice() + ' '}</span> / {billingCycle === "monthly" ? "month" : "year"}
                                </div>

                                <div className="billing_total_discount">
                                    <p className="billing_total_discount_text">
                                        (50% early adopter discount!)
                                    </p>
                                </div>
                            </div>
                            
                            
                            <div className="billing_button_container">
                                <button 
                                    type="submit"
                                    className="billing_button"
                                >
                                    Upgrade Now
                                </button>
                            </div>
                        
                       
                    </div>
                    
                </div>
                </form>
            </div>

        </Popup>
    )
}